import _ from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from '../Spinner';
import Button from '../Button';
import { showChooseShop, showMessageModal } from '../../redux/actions';
import { onChooseShop } from './ChooseShop.services';
import { PRIMARY, SECONDARY } from '../../constants/configuration';
import { NO_SHOP_SELECTED_ERROR_MESSAGE } from '../../constants';
import { useAppDispatch } from '../../redux/hook';
import Shops from './components/Shops';

export default function ChooseShop() {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedShop, setSelectedShop] = useState({});

  return (
    <>
      <Modal.Body className='py-2 px-0 px-sm-2 dark-modal'>
        <div className='dishop-iso'>
          <div className='container-fluid dark-modal'>
            <div className='row'>
              <div className='col-12'>
                <div className='px-0 px-sm-3'>
                  <div className='col'>
                    {
                      <Shops
                        selectedShop={selectedShop}
                        setSelectedShop={setSelectedShop}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className={`modal-sticky-footer d-flex justify-content-center 
            dark-modal`}
      >
        <>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Button
                type={SECONDARY}
                onClick={() => dispatch(showChooseShop(false))}
              >
                Retour
              </Button>
              <Button
                type={PRIMARY}
                className='btn'
                onClick={() => {
                  if (_.isEmpty(selectedShop)) {
                    dispatch(showMessageModal(NO_SHOP_SELECTED_ERROR_MESSAGE));
                  } else {
                    onChooseShop({ shop: selectedShop, setLoading });
                  }
                }}
              >
                Choisir
              </Button>
            </>
          )}
        </>
      </Modal.Footer>
    </>
  );
}
