/* eslint-disable no-console */
import axios from 'axios';
import { API_STREAM_LOGS, FRONTEND_ALERT_ERROR } from '../constants';
import { isDev, isQrCode } from './config';
import store from '../redux/store';
import pckg from '../../package.json';

import { DescriptiveError } from 'topkat-utils'

export function applicationError(msg, extraInfos = {}, options = {}) {
  const { doThrow = false, doLog = true } = options
  const { shopId } = store.getState().shopReducer;
  const { user } = store.getState().userReducer;
  console.log('descriptiveErrLog')

  const error = new DescriptiveError(msg, {
    doNotThrow: true,
    // stack traces are by default in the browser console
    // and this one is not relevant as it does not take in
    // account source map files
    noStackTrace: true,
    shopId,
    user,
    ...extraInfos
  })

  if (doLog) error.log()
  else error.hasBeenLogged = true

  const cloudWatchParsedLogs = error.logs.join('\n') + '\n'
  sendCloudWatchAlert(cloudWatchParsedLogs, false)
  if (doThrow) throw error
}

export const sendCloudWatchLogs = async (message, enableLogging = true) => logErr(message, 'info', enableLogging)

export const sendCloudWatchAlert = async (message, enableLogging = true) => logErr(message, 'error', enableLogging)

async function logErr(message, level, enableLogging = true) {
  let customMessage = message
  try {
    const stackTrace = (new Error()).stack
    const domain = window.location.hostname;
    const { shopId } = store.getState().shopReducer;
    const { user } = store.getState().userReducer;
    customMessage = `${level === 'error' ? `${FRONTEND_ALERT_ERROR} ` : ''}DISHOP ${isQrCode() ? 'QRCODE' : 'WEBAPP'} ${pckg.version} | ${user && user.customerId ? `CUSTOMER ID: ${user.customerId}` : 'NO ACCOUNT'} SHOP ID: ${shopId || domain} - ${message}`

    if (!isDev()) {
      // stack trace is necessary
      await axios.post(API_STREAM_LOGS, { message: customMessage + '\n' + stackTrace })
    }

  } catch (error) {
    console.error('Error streaming logs to cloudwatch', error);
  }
  if (enableLogging){
    // log the message whatever happens
    if (level === 'error') console.error(customMessage);
    else console.log(customMessage)
  }
}
