import React from 'react';
import {
  handleCheckboxClick,
} from '../../../Category.services';
import OptionLabel from '../../OptionLabel';
import { Checkbox } from '@mui/material';
import { COLOR_LIGHT_GRAY, UNIT } from '../../../../../../../../../../../../../constants';
import { useAppSelector } from '../../../../../../../../../../../../../redux/hook';
import { isCheckedOrSelected } from '../../../../../../../../../../../ListProduct.services';

type OptionalItemProps={
  uniqueId:any;
  productTitle:any;
  categoryId:any;
  items:any;
  itemKey:any;
  productItems:any;
  index:any;
  item:any;
  totalMaxItemsSelected:any;
  notEnoughStock:any;
  optionContainerWebapp:any;
  category:any;
  addItem:any;
  removeItem:any;
  product:any;
  categoryKey:any;
}

export default function OptionalItem({
  uniqueId,
  productTitle,
  categoryId,
  items,
  itemKey,
  productItems,
  index,
  item,
  totalMaxItemsSelected,
  notEnoughStock,
  optionContainerWebapp,
  category,
  addItem,
  removeItem,
  product,
  categoryKey,
}:OptionalItemProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const id = `${uniqueId}-${productTitle}-${categoryId}-${items[itemKey]}`;
  const isItemChecked = isCheckedOrSelected(productItems, index, item);
  const checkBoxDisabled =
    (totalMaxItemsSelected || notEnoughStock) && !isItemChecked;
  return (
    <div
      className={optionContainerWebapp}
      key={itemKey}
      style={{ cursor: !checkBoxDisabled ? 'pointer' : 'auto' }}
    >
      <div
        className={`h-100`}
        onClick={() => {
          if (!checkBoxDisabled) {
            handleCheckboxClick({
              index,
              category,
              addItem,
              removeItem,
              productItems,
              item,
            });
          }
        }}
      >
        <div className={`row d-flex align-items-center`}>
          <OptionLabel
            item={item}
            notAvailable={checkBoxDisabled}
            product={product}
            categoryKey={categoryKey}
          />
          <Checkbox
            checked={isItemChecked}
            value={itemKey}
            style={{
              color: checkBoxDisabled ? COLOR_LIGHT_GRAY : secondaryColor,
              paddingLeft: UNIT * 0.5,
              paddingRight: 0,
            }}
            disabled={checkBoxDisabled}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}
