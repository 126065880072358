import React from 'react';
import { useAppSelector } from '../../../redux/hook';
import { getClosedMessage, isStringNotNull } from '../../../utils';

export default function CloseBar() {
  const { closed, shopUnavailable } = useAppSelector(
    (state) => state.shopReducer
  );

  const isShopClosed = closed || shopUnavailable;

  return isStringNotNull(isShopClosed) ? (
    <div className='row'>
      <div className='col text-center'>
        <div
          className='alert alert-danger mb-0'
          role='alert'
        >
          {getClosedMessage(isShopClosed)}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
