import React, { useState } from 'react';
import PhoneInput from '../PhoneInput';
import { showSignUp, facebookOrGoogleSignUp } from '../../redux/actions';
import {
  renderErrorMessage,
  isStringNotNull,
  isObjectNotNull,
  formatPhone,
} from '../../utils';
import Spinner from '../Spinner';
import Button from '../Button';
import { PRIMARY, UNIT, SECONDARY } from '../../constants';
import { resetEmail } from '../../redux/actions/userAction';
import styles from './SignupForm.styles';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { Field, Form } from 'react-final-form';
import CGU from './components/CGU';
import { validateFormValues } from './SignupForm.services';
import _ from 'lodash';

export default function SignupForm({ onSubmit, errorMessage }) {
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.configurationReducer);
  const { loadingConnexion, email, signUpData } = useAppSelector(
    (state) => state.userReducer
  );

  const [phone, setPhone] = useState('');
  const [isPhoneIncorrect, setIsPhoneIncorrect] = useState(true);
  const [phoneObj, setPhoneObj] = useState({});

  const renderPhoneInput = ({ meta, input }) => {
    const { value, onBlur } = input;
    const className = `form-control ${
      (isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched
        ? 'is-invalid'
        : ''
    }`;
    return (
      <div className='form-group text-left'>
        <PhoneInput
          onChange={(phone, country, e, formattedPhone) => {
            setPhone(phone);
            input.onChange(formattedPhone);
          }}
          onBlur={onBlur}
          value={value}
          className={className}
          isPhoneIncorrect={(isPhoneIncorrect) => {
            setIsPhoneIncorrect(isPhoneIncorrect);
          }}
          getPhoneObj={(obj) => {
            _.isEqual(obj, phoneObj) ? null : setPhoneObj(obj);
          }}
        />
        {(isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched && (
          <div className='invalid-feedback-block text-center'>
            Veuillez fournir un telephone valide
          </div>
        )}
      </div>
    );
  };

  const renderInput = ({ input, type, placeholder, meta, description }) => {
    const className = `form-control ${
      meta.error && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-group'>
        <input
          type={type}
          autoComplete='new-password'
          className={`${className} dark-modal`}
          placeholder={placeholder}
          {...input}
          style={{ ...styles.inputStyle }}
        />
        <small className='form-check-label'>{description}</small>
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  const renderCheckBox = ({ input, meta, description, id }) => {
    const className = `form-check-input ${
      meta.error && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-check mb-2 text-justify '>
        <div className='d-flex align-items-center'>
          <input
            className={`dark-modal ${className}`}
            type='checkbox'
            value=''
            id={id}
            {...input}
          />
          <small className='pl-2'>
            <label
              className='form-check-label text-secondary'
              htmlFor={id}
              style={{ fontSize: UNIT * 0.625 }}
            >
              {description}
            </label>
          </small>
        </div>
        {isStringNotNull(meta.error) && meta.touched && (
          <p className='invalid-feedback-block text-center'>{meta.error}</p>
        )}
      </div>
    );
  };

  const handleSubmitSignup = async ({ password, nom, prenom, phone, newsletter }) => {
    if (isPhoneIncorrect || !isStringNotNull(phone)) {
      return;
    }

    const numero = formatPhone(phone);

    if (isObjectNotNull(signUpData)) {
      const { firstName, lastName, uid, loginMethod } = signUpData;
      const profile = {
        firstName,
        lastName,
        email: signUpData.email,
        firstOrder: true,
        id: uid,
        newsletter: newsletter || false,
        numero,
        phone: phoneObj,
      };
      dispatch(facebookOrGoogleSignUp(profile, loginMethod));
    } else {
      onSubmit(
        email,
        password,
        nom,
        prenom,
        numero,
        phoneObj,
        newsletter || false
      );
    }
  };

  return (
    <Form
      onSubmit={handleSubmitSignup}
      validate={(values)=>validateFormValues({ ...values, email }, signUpData)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {errorMessage !== '' && (
            <p className='text-danger'>{renderErrorMessage(errorMessage)}</p>
          )}
          {!isObjectNotNull(signUpData) && (
            <div>
              <Field
                name='nom'
                type='text'
                placeholder='Nom'
                render={renderInput}
              />
              <Field
                name='prenom'
                type='text'
                placeholder='Prénom'
                render={renderInput}
              />
              <Field
                name='password'
                type='password'
                placeholder='Mot de Passe'
                render={renderInput}
              />
              <Field
                name='confirmPassword'
                type='password'
                placeholder='Confirmation du mot de passe'
                render={renderInput}
              />
            </div>
          )}
          <Field
            name='phone'
            type='text'
            placeholder='Numéro de téléphone'
            render={renderPhoneInput}
          />

          <Field
            name='CGU'
            id='CGUCheckbox'
            type='checkbox'
            render={renderCheckBox}
            formClass='form-check-input'
            description={<CGU />}
          />
          <Field
            name='newsletter'
            id='newsletterCheckbox'
            type='checkbox'
            render={renderCheckBox}
            formClass='form-check-input'
            description={`Je souhaite recevoir les promotions et nouveautés de la part de ${name}`}
          />
          {loadingConnexion && (
            <div className='mt-4'>
              <Spinner />
            </div>
          )}
          <Button
            type={PRIMARY}
            className='btn-block mt-3'
            isSubmit
            style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
          >
            Continuer
          </Button>
          {!loadingConnexion && !isObjectNotNull(signUpData) && (
            <Button
              type={SECONDARY}
              style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
              className='btn-block mt-2'
              onClick={() => {
                dispatch(showSignUp(false));
                dispatch(resetEmail());
              }}
            >
              Retour à la page précédente
            </Button>
          )}
        </form>
      )}
    />
  );
}
