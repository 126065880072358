import React from 'react';
import ProductImage from './components/ProductImage';
import ProductPrice from './components/ProductPrice';
import { useAppSelector } from '../../../../../../redux/hook';
import { PRODUCT_STYLE_LIST } from '../../../../../../constants';

type ProductCardProps = {
  title: string;
  description: string;
  image: any;
  product: any;
  hideProductName: any;
};

export default function ProductCard({
  title,
  description,
  image,
  product,
  hideProductName,
}: ProductCardProps) {
  const { productStyle } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { isHorizontalSection } = useAppSelector(
    (state) => state.componentReducer
  );
  const maxDescription = 60;
  const maxTitle = 30;
  const imageProductGridClass = isHorizontalSection
    ? 'image-product-grid-horizontal'
    : 'image-product-grid-vertical';
  const imageProductListClass = isHorizontalSection
    ? 'image-product-list-horizontal'
    : 'image-product-list-vertical';

  title?.length > 0
    ? `${(title[0].toUpperCase() + title.slice(1).toLowerCase()).substring(
        0,
        maxTitle
      )}${title.length > maxTitle ? '...' : ''}`
    : '';
  description = `${
    description
      ? `${description.substring(0, maxDescription)}${
          description.length > maxDescription ? '...' : ''
        }`
      : ''
  }`;
  if (productStyle === PRODUCT_STYLE_LIST) {
    return (
      <div className={`row ${imageProductListClass}`}>
        <ProductImage
          image={image}
          title={title}
        />
        <div className='col-7 text-left pl-3 my-auto'>
          <h5 className='text-product label'>{title}</h5>
          <p className='text-description mt-2 mb-1 label'>{description}</p>
          <ProductPrice product={product} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={imageProductGridClass}>
        <ProductImage
          image={image}
          title={title}
        />
      </div>
      <div className='py-2'>
        {!hideProductName && <h6 className='text-product px-1'>{title}</h6>}
        <ProductPrice product={product} />
        <p className='text-description mb-0 px-2'>{description}</p>
      </div>
    </>
  );
}
