import _ from 'lodash';
import React from 'react';
import {
  isUniqueOption,
  optionHasNotEnoughStock,
} from '../../Category.services';
import MultipleItem from './components/MultipleItem';
import RequiredItem from './components/RequiredItem';
import OptionalItem from './components/OptionalItem';

type ItemsProps = {
  category: any;
  productItems: any;
  index: any;
  categoryId: any;
  nbProduct: any;
  itemsCounter: any;
  product: any;
  categoryKey: any;
  addItem: any;
  removeItem: any;
  addUniqueItem: any;
  productTitle: any;
};

export default function Items({
  category,
  productItems,
  index,
  categoryId,
  nbProduct,
  itemsCounter,
  product,
  categoryKey,
  addItem,
  removeItem,
  addUniqueItem,
  productTitle,
}: ItemsProps) {
  const { items, multiple } = category;
  const uniqueId = new Date().getUTCMilliseconds();
  const itemsKeys = items ? Object.keys(items) : [];
  const isMultiple = multiple && category?.max !== 1;
  const optionContainerWebapp = 'padding col-12 py-0 py-sm-1';
  const productItem = productItems[index];

  const concernedProductItem =
    productItem && productItem?.categoryKey === categoryId;
  const totalMaxItemsSelected =
    concernedProductItem && _.size(productItem.options) >= category?.max;
  const uniqueOption = isUniqueOption(category);

  return (
    <div className='row col-12'>
      <div className='row col-12 py-3'>
        {itemsKeys
          .sort(
            (a, b) =>
              parseInt(items[a].position, 10) - parseInt(items[b].position, 10)
          )
          .map((itemKey) => {
            const item = items[itemKey];
            item.categoryKey = category.key;
            const notEnoughStock = optionHasNotEnoughStock({
              productItems,
              item,
              nbProduct,
            });
            if (isMultiple) {
              return (
                <MultipleItem
                  key={itemKey}
                  category={category}
                  itemKey={itemKey}
                  itemsCounter={itemsCounter}
                  productItem={productItem}
                  item={item}
                  concernedProductItem={concernedProductItem}
                  totalMaxItemsSelected={totalMaxItemsSelected}
                  notEnoughStock={notEnoughStock}
                  optionContainerWebapp={optionContainerWebapp}
                  index={index}
                  addItem={addItem}
                  removeItem={removeItem}
                  product={product}
                  categoryKey={categoryKey}
                />
              );
            }

            if (uniqueOption) {
              return (
                <RequiredItem
                  key={itemKey}
                  productItems={productItems}
                  index={index}
                  item={item}
                  uniqueId={uniqueId}
                  productTitle={productTitle}
                  categoryId={categoryId}
                  items={items}
                  optionContainerWebapp={optionContainerWebapp}
                  notEnoughStock={notEnoughStock}
                  itemKey={itemKey}
                  category={category}
                  addUniqueItem={addUniqueItem}
                  product={product}
                  categoryKey={categoryKey}
                />
              );
            } else {
              return (
                <OptionalItem
                  key={itemKey}
                  uniqueId={uniqueId}
                  productTitle={productTitle}
                  categoryId={categoryId}
                  items={items}
                  itemKey={itemKey}
                  productItems={productItems}
                  index={index}
                  item={item}
                  totalMaxItemsSelected={totalMaxItemsSelected}
                  notEnoughStock={notEnoughStock}
                  optionContainerWebapp={optionContainerWebapp}
                  category={category}
                  addItem={addItem}
                  removeItem={removeItem}
                  product={product}
                  categoryKey={categoryKey}
                />
              );
            }
          })}
      </div>
    </div>
  );
}
