import React from 'react';
import {
  BLACK,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
  ORDER_TYPE_CLICK_AND_SEAT_LABEL,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_DELIVERY_LABEL,
  UNIT,
  WHITE,
} from '../../../constants';
import styles from '../AddressInput.styles';
import {
  Check,
  DirectionsBike,
  LocalMallOutlined,
  RestaurantOutlined,
} from '@mui/icons-material';
import _ from 'lodash';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

type OrderTypeSwitchProps = {
  orderTypes: any;
  isMobile: boolean;
  orderTypeChoiceIndex: number;
  handleOrderTypeSelection: (number:number) => Promise<void>;
  secondaryColor: string;
};

export default function OrderTypeSwitch({
  orderTypes,
  isMobile,
  orderTypeChoiceIndex,
  handleOrderTypeSelection,
  secondaryColor,
}: OrderTypeSwitchProps) {
  const listOrderTypes = [];
  _.map(orderTypes, (c) => {
    if (c === ORDER_TYPE_DELIVERY) {
      listOrderTypes.push({
        label: (
          <>
            {ORDER_TYPE_DELIVERY_LABEL}{' '}
            {_.size(orderTypes) === 1 && (
              <Check
                style={{ ...styles.space }}
                className='text-end'
              />
            )}{' '}
          </>
        ),
        icon: (
          <>
            <DirectionsBike
              style={{ ...styles.space }}
              className={`${_.size(orderTypes) === 1 && 'float-left'}`}
            />
          </>
        ),
      });
    } else if (c === ORDER_TYPE_CLICK_AND_COLLECT) {
      listOrderTypes.push({
        label: (
          <>
            {ORDER_TYPE_CLICK_AND_COLLECT_LABEL}{' '}
            {_.size(orderTypes) === 1 && (
              <Check
                style={{ ...styles.space }}
                className='text-end'
              />
            )}
          </>
        ),
        icon: (
          <>
            <LocalMallOutlined
              style={{ ...styles.space }}
              className={`${_.size(orderTypes) === 1 && 'float-left'}`}
            />{' '}
          </>
        ),
      });
    } else {
      listOrderTypes.push({
        label: (
          <>
            {ORDER_TYPE_CLICK_AND_SEAT_LABEL}{' '}
            {_.size(orderTypes) === 1 && (
              <Check
                style={{ ...styles.space }}
                className='text-end'
              />
            )}
          </>
        ),
        icon: (
          <>
            <RestaurantOutlined
              style={{ ...styles.space }}
              className={`${_.size(orderTypes) === 1 && 'float-left'}`}
            />{' '}
          </>
        ),
      });
    }
  });

  return (
    <BottomNavigation
      showLabels
      value={orderTypeChoiceIndex}
      onChange={async (event, selectedOrderTypeIndex) =>
        await handleOrderTypeSelection(selectedOrderTypeIndex)
      }
      className='bottom-navigation-style dark-modal dark-color'
      style={{ marginBottom: UNIT, height: 40 }}
    >
      {listOrderTypes &&
        _.map(listOrderTypes, (c, index) => {
          return (
            <BottomNavigationAction
              key={index}
              label={
                (!isMobile || (isMobile && _.size(listOrderTypes)) < 3) &&
                c.label
              }
              icon={c.icon}
              value={index}
              className='label'
              style={{
                flexDirection: 'row',
                maxWidth: '100%',
                borderRadius: UNIT * 2,
                backgroundColor:
                  orderTypeChoiceIndex === index && _.size(orderTypes) > 1
                    ? secondaryColor
                    : 'transparent',
                color:
                  orderTypeChoiceIndex === index && _.size(orderTypes) > 1
                    ? WHITE
                    : BLACK,
              }}
            />
          );
        })}
    </BottomNavigation>
  );
}
