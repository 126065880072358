import {
  getCurrentNbOption,
  getNbInCartOfProduct,
  getOptionAssociatedProduct,
  getStockRemaining,
  isProductDisabled,
  isStringNotNull,
} from '../../../../../../../../../../utils';
import _ from 'lodash';
import { isCheckedOrSelected } from '../../../../../../../../ListProduct.services';

export const getStockOption = (option: any): any => {
  let stockOption = null;
  const product = getOptionAssociatedProduct(option);
  if (product && parseInt(product.stock, 10) >= 0) {
    stockOption = product.stock;
  }
  return stockOption;
};

export const getStockRemainingOption = (
  options: any,
  option: any,
  nbProduct: number
): number => {
  let stockRemainingOption = null;
  const stockOption = getStockOption(option);
  if (parseInt(stockOption, 10) >= 0) {
    const { key, productKey } = option;
    const nbOption = getCurrentNbOption(options, key);
    const nbInCart = getNbInCartOfProduct(key, productKey);
    stockRemainingOption = getStockRemaining(
      stockOption,
      nbInCart,
      nbProduct * nbOption
    );
    if (
      stockRemainingOption > 0 &&
      getStockRemaining(stockOption, nbInCart, nbProduct * (nbOption + 1)) < 0
    ) {
      stockRemainingOption = 0;
    }
  }
  return stockRemainingOption;
};

type OptionHasNotEnoughStockParams = {
  productItems: any;
  item: any;
  nbProduct: number;
};

export const optionHasNotEnoughStock = ({
  productItems,
  item,
  nbProduct,
}: OptionHasNotEnoughStockParams): boolean => {
  let stockRemainingOption = null;
  let notEnoughStock = false;
  const { productKey } = item;
  if (productKey) {
    const product = getOptionAssociatedProduct(item);
    if (product) {
      stockRemainingOption = getStockRemainingOption(
        productItems,
        item,
        nbProduct
      );
      notEnoughStock = isProductDisabled(product);
    }
  }
  return notEnoughStock || parseInt(stockRemainingOption, 10) <= 0;
};

type GetOptionDataResult = {
  image: any;
  description: any;
};

export const getOptionData = (item: any): GetOptionDataResult => {
  const { productKey } = item;
  let result = { image: undefined, description: undefined };
  if (productKey) {
    const product = getOptionAssociatedProduct(item);
    if (product) {
      const { image, description } = product;
      result = { image, description };
    }
  }
  return result;
};

type HandleCounterClickParams = {
  index: any;
  addItem: any;
  removeItem: any;
  item: any;
  category: any;
  action: string;
};

export const handleCounterClick = ({
  index,
  addItem,
  removeItem,
  item,
  category,
  action,
}: HandleCounterClickParams): void => {
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  if (action === 'add') {
    addItem(index, category, item, true);
  } else {
    removeItem(index, category, item, true);
  }
};

type HandleCheckboxClickParams = {
  index: any;
  category: any;
  addItem: any;
  removeItem: any;
  productItems: any;
  item: any;
};

export const handleCheckboxClick = ({
  index,
  category,
  addItem,
  removeItem,
  productItems,
  item,
}: HandleCheckboxClickParams): void => {
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  const checked = isCheckedOrSelected(productItems, index, item);
  if (!checked) {
    addItem(index, category, item);
  } else {
    removeItem(index, category, item);
  }
};

type HandleRadioButtonClickParams = {
  index: any;
  category: any;
  addUniqueItem: any;
  item: any;
};

export const handleRadioButtonClick = ({
  index,
  category,
  addUniqueItem,
  item,
}: HandleRadioButtonClickParams): void => {
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  addUniqueItem(index, category, item);
};

export const generateOptionChoiceMessage = (category: any): string => {
  let choiceMessage = category?.min ? '* Champ Obligatoire' : '';
  if (!isUniqueOption(category)) {
    choiceMessage += category?.min ? ' - ' : '';
    if (category?.min && category?.min < category?.max) {
      choiceMessage += `Entre ${category.min} et ${category.max} choix`;
    } else if (category?.min && category?.min === category?.max) {
      choiceMessage += `Choisissez ${category.max} choix`;
    } else if (category?.min && !category?.max) {
      choiceMessage += `Choisissez au moins ${category.min} choix`;
    } else if (category?.max > 1) {
      choiceMessage += `Choisissez jusqu'à ${category.max}`;
    }
  }
  return choiceMessage;
};

export const getFreeItem = (options: any, item: any, category: any): any => {
  const { optionFree, multiple } = category;
  const freeItems = [];
  const optionsArray = _.values(options);
  _.map(optionsArray, (elm, index) => {
    if (optionFree && index < optionFree) {
      freeItems.push(elm.key);
    }
  });
  const isFreeItem = multiple
    ? freeItems.includes(item?.key) && freeItems.length < optionFree
    : freeItems.includes(item?.key);

  item.isFree = isFreeItem || optionsArray.length < optionFree;
  return item;
};

export const isUniqueOption = (option: any): boolean =>
  option?.min === 1 && option?.max === 1;
