import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.css';
import ModalBorder from '../ModalBorder';
import { showCartModal } from '../../redux/actions';
import { getCreditCard } from '../../utils';
import { COLOR_LIGHT_GRAY } from '../../constants/configuration';
import { getFirstDay, getMaximumPreparationTime } from './CartModal.services';

import { useAppDispatch, useAppSelector } from '../../redux/hook';
import CartModalFooter from './components/CartModalFooter';
import CartModalBody from './components/CartModalBody';

export default function CartModal({ stripe, elements }) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [choosePayment, setChoosePayment] = useState(false);
  const [hourSelectValue, setHourSelectedValue] = useState(0);
  const [day, setDay] = useState(null);
  const [isValidDate, setIsValidDate] = useState(true);
  const [selectDay, setSelectDay] = useState(false);
  const [selectTime, setSelectTime] = useState(false);
  const [isPhoneIncorrect, setIsPhoneIncorrect] = useState(false);

  const { user } = useAppSelector((state) => state.userReducer);

  const { cartModal } = useAppSelector((state) => state.componentReducer);
  const { openHours, sections } = useAppSelector((state) => state.shopReducer);
  const { commande } = useAppSelector((state) => state.pendingOrderReducer);

  const resetState = () => {
    setLoading(false);
    setChoosePayment(false);
    setHourSelectedValue(0);
    setDay(null);
    setIsValidDate(true);
    setSelectDay(false);
    setSelectTime(false);
    setIsPhoneIncorrect(false);
  };

  useEffect(() => {
    if (openHours) {
      const maximumPreparationTime = getMaximumPreparationTime(commande);
      getFirstDay(
        maximumPreparationTime,
        setDay,
        setHourSelectedValue,
        setSelectDay,
        setSelectTime,
        setIsValidDate
      );
    }
  }, [openHours, commande]);

  return (
    <Modal
      onHide={() => {
        dispatch(showCartModal(false));
        resetState();
      }}
      show={cartModal}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable
    >
      <ModalBorder
        className='hide-scroll modal-size-fixed'
        color={COLOR_LIGHT_GRAY}
      >

          <Modal.Body
            className='dark-modal'
          >
            <CartModalBody
              card={getCreditCard(user.cards)}
              choosePayment={choosePayment}
              stripe={stripe}
              elements={elements}
              selectTime={selectTime}
              setIsValidDate={setIsValidDate}
              setLoading={setLoading}
              isPhoneIncorrect={isPhoneIncorrect}
              setChoosePayment={setChoosePayment}
              sections={sections}
              day={day}
              selectDay={selectDay}
              setDay={setDay}
              setHourSelectedValue={setHourSelectedValue}
              setSelectDay={setSelectDay}
              isValidDate={isValidDate}
              setSelectTime={setSelectTime}
              hourSelectValue={hourSelectValue}
              setIsPhoneIncorrect={setIsPhoneIncorrect}
              loading={loading}
            />
          </Modal.Body>
          <Modal.Footer className='modal-sticky-footer d-flex justify-content-center dark-modal'>
            <CartModalFooter
              resetState={resetState}
              choosePayment={choosePayment}
              setChoosePayment={setChoosePayment}
              loading={loading}
              setLoading={setLoading}
              setDay={setDay}
              setHourSelectedValue={setHourSelectedValue}
              setSelectDay={setSelectDay}
              setSelectTime={setSelectTime}
              setIsValidDate={setIsValidDate}
            />
          </Modal.Footer>
      </ModalBorder>
    </Modal>
  );
}
