import _ from 'lodash';
import { isSectionNotInRange, getNextDay } from '../../utils';
import moment from 'moment';
import {
  ITEM_KEY_CATEGORY_SEPARATOR,
  ITEM_KEY_COUNTER_SEPARATOR,
} from '../../constants';

const getTrueEndDate = (
  startDate: any,
  endDate: any,
  daysOfWeek: any
): string => {
  const start = moment(startDate);
  const end = moment(endDate);
  const daysOfWeekArray = Object.keys(daysOfWeek);

  let lastTargetDay = null;

  for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
    if (daysOfWeekArray.includes(m.format('dddd'))) {
      lastTargetDay = moment(m);
    }
  }

  return lastTargetDay ? lastTargetDay.locale('fr').format('DD MMMM') : null;
};

export const updateMsgSectionRange = (
  sections: any,
  messages: any,
  setMessages: React.Dispatch<React.SetStateAction<any>>
): void => {
  if (!sections) return;

  const currentDate = moment();
  const messagesToUpdate = {};

  _.forEach(sections, (section) => {
    const { activationRange, key } = section;
    if (!activationRange || !isSectionNotInRange(section)) {
      if (messages[key]) messagesToUpdate[key] = null;
      return;
    }

    const { startDate, endDate, daysOfWeek } = activationRange;
    const oneWeekBeforeStartDate = moment(startDate).subtract(7, 'days');

    if (currentDate.isSameOrAfter(oneWeekBeforeStartDate)) {
      const dayOfWeek = currentDate.format('dddd');
      const currentDayConfig = daysOfWeek[dayOfWeek];
      const currentSlots = currentDayConfig?.slots || [];

      let activationRangeString = '';

      if (currentDate.isAfter(endDate)) {
        messagesToUpdate[key] = null;
      } else if (currentDate.isBefore(startDate)) {
        const nextDay = getNextDay(daysOfWeek, startDate, endDate);
        const formattedStartDate = daysOfWeek[moment(startDate).format('dddd')]
          ? moment(startDate).locale('fr').format('DD MMMM')
          : nextDay
          ? nextDay.split(' ').slice(1).join(' ')
          : '';
        const formattedEndDate = daysOfWeek[moment(endDate).format('dddd')]
          ? moment(endDate).locale('fr').format('DD MMMM')
          : getTrueEndDate(startDate, endDate, daysOfWeek);
        activationRangeString =
          formattedStartDate === formattedEndDate || !formattedEndDate
            ? `le ${formattedStartDate}`
            : `du ${formattedStartDate} au ${formattedEndDate}`;
      } else {
        const nextDay = getNextDay(daysOfWeek, startDate, endDate, moment());
        const validSlot =
          currentSlots.length && !currentDayConfig?.fullDay
            ? _.find(currentSlots, (slot) =>
                currentDate.isBefore(moment(slot.split('-')[1], 'HH:mm'))
              )
            : null;

        activationRangeString = validSlot
          ? `de ${validSlot.replace('-', ' à ')}`
          : nextDay;
      }

      if (!_.isEqual(messages[key], activationRangeString)) {
        messagesToUpdate[key] = activationRangeString;
      }
    }

  });

  if (!_.isEqual(messages, messagesToUpdate)) {
    setMessages({
      ...messages,
      ...messagesToUpdate,
  });
  }
};

export const isCheckedOrSelected = (
  productItems: any,
  index: number,
  item: any
): boolean => {
  return !!_.find(
    productItems?.[index]?.options,
    (option) =>
      option?.itemRecursiveKey &&
      option.itemRecursiveKey === generateItemRecursiveKey(item, undefined)
  );
};

export const generateItemRecursiveKey = (item: any, counter: any): string => {
  const itemInitialKey = `${item.categoryKey}${ITEM_KEY_CATEGORY_SEPARATOR}${item.key}`;
  return counter !== undefined && parseInt(counter, 10) >= 0
    ? `${itemInitialKey}${ITEM_KEY_COUNTER_SEPARATOR}${counter}`
    : itemInitialKey;
};
