import React from 'react';
import { displayPriceFormat } from '../../../../../../../../utils';
import { WHITE } from '../../../../../../../../constants';
import { useAppSelector } from '../../../../../../../../redux/hook';
import { getMinimumStockRemaining } from '../../ProductModal.services';
import styles from '../../ProductModal.styles';
import Button from '../../../../../../../Button';
import Spinner from '../../../../../../../Spinner';
import { addToCartClick, calculatePrice } from './AddToCartButton.services';

type AddToCartButtonProps = {
  items: any;
  shopCategories: any;
  price: any;
  nb: number;
  unit: any;
  product: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mandatoryCategories: any;
  productCategories: any;
  productKey: any;
  instruction: any;
  sectionName: any;
  sectionKey: any;
  resetComponent: any;
  stepOptionIndex: any;
  loading: boolean;
};

export default function AddToCartButton({
  items,
  shopCategories,
  price,
  nb,
  unit,
  product,
  setLoading,
  mandatoryCategories,
  productCategories,
  productKey,
  instruction,
  sectionName,
  sectionKey,
  resetComponent,
  stepOptionIndex,
  loading,
}: AddToCartButtonProps) {
  const { shopId, closed, shopUnavailable } = useAppSelector(
    (state) => state.shopReducer
  );
  const { isMobile } = useAppSelector((state) => state.componentReducer);
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const renderFinalPrice = () => {
    const productPrice = calculatePrice(items, shopCategories, price);
    let finalPrice = productPrice * nb;
    if (unit) {
      finalPrice /= unit.conversion;
    }
    return finalPrice;
  };

  const categoriesLength = productCategories.length + 1;
  let disabled = false;
  const { stockRemaining } = getMinimumStockRemaining({
    items,
    nb,
    unit,
    product,
  });
  const { addToCartCommandStyle } = styles(secondaryColor);
  const isShopClosed = closed || shopUnavailable;
  if (isShopClosed) {
    disabled = true;
  }
  if (
    nb <= 0 ||
    stockRemaining < 0 ||
    stepOptionIndex < productCategories.length ||
    loading
  ) {
    disabled = true;
  }
  return (
    <div
      className={
        categoriesLength > 1
          ? `col-12 d-flex justify-content-center pt-4`
          : 'col-12 d-flex justify-content-center'
      }
    >
      <Button
        disabled={disabled}
        style={addToCartCommandStyle}
        onClick={() => {
          addToCartClick({
            product,
            setLoading,
            mandatoryCategories,
            items,
            productCategories,
            shopCategories,
            sectionName,
            nb,
            sectionKey,
            productKey,
            instruction,
            unit,
            shopId,
            resetComponent,
          });
        }}
      >
        {loading && (
          <div>
            <Spinner
              color={WHITE}
              size={'sm'}
            />
            <span className='sr-only'>Loading...</span>
          </div>
        )}
        {!loading && (
          <div>
            <span>
              {isShopClosed
                ? 'Indisponible'
                : isMobile
                ? 'Ajouter '
                : 'Ajouter au panier '}
            </span>
            {!isShopClosed && (
              <span> {displayPriceFormat(renderFinalPrice())} </span>
            )}
          </div>
        )}
      </Button>
    </div>
  );
}
