import React from 'react'
import { connect } from 'react-redux'
import styles, { backgroundHeightProperty, mobileDescriptionStyle } from './Cover.styles'
import { showHoursModal } from '../../../redux/actions'
import { BLACK, DESCRIPTION_ADDRESS, PRIMARY } from '../../../constants'
import Button from '../../Button'

class Cover extends React.Component {
  render() {
    const {
      webapp,
      showHoursModal,
      name,
      openHours,
      isMobile,
      sliding,
      descriptionText,
      fullScreenBackgroundImg = false,
    } = this.props;
    const {
      images,
      hideTitle,
      descriptionAddress = DESCRIPTION_ADDRESS
    } = webapp;
    const { imageCoverOrderUrl } = images;
    return (
      <div
        className={`text-white cover`}
        id='cover'
        style={{
              backgroundImage: `url(${
                sliding
                  ? sliding[0]
                  : imageCoverOrderUrl
              })`,
              backgroundSize: 'cover',
              ...backgroundHeightProperty(fullScreenBackgroundImg, isMobile)
            }
        }
      >
        <div
          className={`px-sm-5 py-sm-4 text-white`}
          style={{
            ...styles.overlayCover,
            backgroundColor: `rgba(0,0,0,.4)`
          }}
        >
          <div className='row h-100 mt-sm-0 justify-content-center'>
            <div className='row text-center mt-sm-0'>
              <div
                className={`my-auto col px-3 px-sm-5 `}
              >
                {!hideTitle && (
                  <>

                    <p
                      className={`text-white text-title ${isMobile ? 'shop-title' : 'cover-title'} mb-0`}
                    >
                      {name}
                    </p>
                  </>
                )}

                  <div className='px-lg-5 px-0'>
                    <p
                      className='line-break cover-description mb-3 px-lg-5 px-0'
                      style={{ textShadow: `2px 2px 4px ${BLACK}`, ...mobileDescriptionStyle(isMobile) }}
                    >
                      {descriptionText || descriptionAddress}
                    </p>
                  </div>

                  <Button
                    type={PRIMARY}
                    className={`${isMobile ? '' : 'btn-lg'}`}
                    onClick={() => {
                      const hoursModalData = {
                        show: true,
                        hours: openHours
                      };
                      showHoursModal(hoursModalData);
                    }}
                  >
                    Afficher les horaires
                  </Button>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  configurationReducer,
  shopReducer,
  componentReducer
}) => {
  const { customerAddress } = userReducer;
  const {
    webapp,
    name,
    secondaryColor,
    logoUrl,
    descriptionText
  } = configurationReducer;
  const { openHours, sliding, shopName, description } = shopReducer;
  const { navbarHeight, isMobile } = componentReducer;
  return {
    descriptionText,
    customerAddress,
    webapp,
    openHours,
    navbarHeight,
    secondaryColor,
    name,
    isMobile,
    sliding,
    shopName,
    description,
    logoUrl
  };
};

export default connect(mapStateToProps, { showHoursModal })(Cover);
