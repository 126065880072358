import React from 'react';
import Button from '../../Button';
import { SECONDARY } from '../../../constants';
import { useAppDispatch } from '../../../redux/hook';
import { showOrderModal, showOrderStatus } from '../../../redux/actions';
import _ from 'lodash';

type OrderModalFooterProps = {
  orderStatus: any;
  orders: any;
};

export default function OrderModalFooter({
  orderStatus,
  orders,
}: OrderModalFooterProps) {
  const dispatch = useAppDispatch();
  return (
    <>
      {orderStatus && orders?.[orderStatus] && _.size(orders) > 1 && (
        <Button
          type={SECONDARY}
          onClick={() => {
            dispatch(showOrderStatus(false));
          }}
        >
          Retour
        </Button>
      )}
      {(!orderStatus || _.size(orders) <= 1) && (
        <Button
          type={SECONDARY}
          onClick={() => {
            dispatch(showOrderModal(false));
            dispatch(showOrderStatus(false));
          }}
        >
          Fermer
        </Button>
      )}
    </>
  );
}
