import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showUpdateInfo, showAccountModal } from '../../redux/actions';
import AccountForm from '../AccountForm';
import ModalBorder from '../ModalBorder';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import AccountModalFooter from './components/AccountModalFooter';
import AccountModalBody from './components/AccountModalBody';

export default function AccountModal() {
  const [pastOrder, setPastOrder] = useState(false);

  const { accountModal, updateInfo } = useAppSelector(
    (state) => state.componentReducer
  );

  const dispatch = useAppDispatch();

  return (
    <Modal
      onHide={() => {
        dispatch(showAccountModal(false));
        dispatch(showUpdateInfo(false));
        setPastOrder(false);
      }}
      scrollable
      show={accountModal}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <ModalBorder
        className={`hide-scroll ${
          updateInfo ? '' : 'modal-size-fixed'
        } dark-modal `}
      >
        {updateInfo ? <AccountForm /> : (
          <div>
            <AccountModalBody
              pastOrder={pastOrder}
              setPastOrder={setPastOrder}
            />
            <AccountModalFooter
              pastOrder={pastOrder}
              setPastOrder={setPastOrder}
            />
          </div>
        )}
      </ModalBorder>
    </Modal>
  );
}
