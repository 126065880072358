import React from 'react'
import { COLOR_MID_GRAY, UNIT } from '../../../../../../../constants';
import { useAppSelector } from '../../../../../../../redux/hook';

export default function OptionScrollButton({ setShowOptionScrolButton }) {

    const { secondaryColor } = useAppSelector(
        (state) => state.configurationReducer
      );

  return (
    <i
          className='fas fa-arrow-circle-down shadow-lg'
          onClick={() => {
            document
              .getElementById('optionContainer')
              .scrollIntoView({ block: 'start', behavior: 'smooth' });
            setShowOptionScrolButton(false);
          }}
          style={{
            fontSize: '30px',
            position: 'absolute',
            zIndex: 10,
            top: '-50px',
            color: secondaryColor,
            backgroundColor: COLOR_MID_GRAY,
            borderRadius: UNIT,
            cursor: 'pointer',
          }}
        />
  )
}
