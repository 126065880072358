import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  showAddressModal,
  showChooseShop,
} from '../../redux/actions';
import ChooseShop from '../ChooseShop';
import CloseButton from '../CloseButton';
import ModalBorder from '../ModalBorder';
import { UNIT } from '../../constants/configuration';
import AddressInputModal from '../AddressInput/AddressInputModal.view';
import { useAppDispatch, useAppSelector } from '../../redux/hook';

export default function AddressModal() {
  const dispatch = useAppDispatch();

  const { addressModal, chooseShop } = useAppSelector(
    (state) => state.componentReducer
  );

  const onClose = () => {
    dispatch(showAddressModal(false));
    dispatch(showChooseShop(false));
  };

  return (
    <div>
      <Modal
        show={addressModal}
        onHide={onClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable
      >
        <ModalBorder
          className='hide-scroll modal-size-fixed'
          width='90%'
          margin='0 auto'
        >
          <CloseButton
            onClick={onClose}
            closeStyle={{ right: UNIT * 1.8 }}
          />
          {!chooseShop && (
            <Modal.Body
              className='py-4 px-0 px-sm-2 dishop-iso dark-modal border-color'
              style={{ backgroundColor: 'transparent', borderRadius: UNIT * 2 }}
            >
              <div className='dishop-iso'>
                <div className='container-fluid dark-modal'>
                  <div className='row'>
                    <div className='col-12'>
                      <AddressInputModal />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}
          {chooseShop && <ChooseShop />}
        </ModalBorder>
      </Modal>
    </div>
  );
}
