import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import styles from '../AddressInput.styles';
import { getAddressFormatted } from '../../../utils';
import { ORDER_TYPE_DELIVERY, UNIT } from '../../../constants';

type AddressSelectionRadioButtonsProps = {
  isCustomerAddress: boolean;
  handleAddressTypeSelection: ({ target }: any) => Promise<void>;
  getShopsIsLoading: boolean;
  secondaryColor: any;
  customerAddress: any;
  orderType: any;
};

export default function AddressSelectionRadioButtons({
  isCustomerAddress,
  handleAddressTypeSelection,
  getShopsIsLoading,
  secondaryColor,
  customerAddress,
  orderType,
}: AddressSelectionRadioButtonsProps) {
  return (
    <RadioGroup
      aria-label='isCutomerAddress'
      name='isCutomerAddress'
      id='isCutomerAddress'
      value={isCustomerAddress}
      onChange={(e) => handleAddressTypeSelection(e)}
    >
      <div className='dishop-iso dark-modal'>
        <div className=' align-items-center'>
          <FormControlLabel
            style={{
              ...styles.formControlLabel,
            }}
            control={
              <div>
                <Radio
                  disabled={getShopsIsLoading}
                  value={true}
                  style={{ color: secondaryColor }}
                />
                Adresse enregistrée
              </div>
            }
            label={
              <p style={styles.labelStyle}>
                {getAddressFormatted(customerAddress)}
              </p>
            }
          />
        </div>
        <hr
          className='hr-gray mb-0'
          style={{ marginTop: UNIT * 0.75 }}
        />

        <div className='align-items-center'>
          <FormControlLabel
            style={{
              ...styles.formControlLabel,
            }}
            control={
              <div>
                <Radio
                  disabled={getShopsIsLoading}
                  value={false}
                  style={{ color: secondaryColor }}
                />
                Ajouter une nouvelle adresse
              </div>
            }
            label={
              <p style={styles.labelStyle}>
                {`Saisir ${
                  orderType === ORDER_TYPE_DELIVERY
                    ? 'une nouvelle adresse de livraison'
                    : 'l\'adresse de votre magasin'
                }`}
              </p>
            }
          />
        </div>
      </div>
    </RadioGroup>
  );
}
