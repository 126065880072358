import React from 'react';
import {
  calculateFullPrice,
  displayPriceFormat,
  getManagementFee,
  getPromotion,
  getUserServicePrice,
} from '../../../utils';
import { useAppSelector } from '../../../redux/hook';
import {
  MODIFY_ORDER_TYPE_PROMOTION,
  ORDER_TYPE_DELIVERY,
  PROMOTION_DELIVERY_FREE,
  PROMOTION_PERCENTAGE,
  UNIT,
} from '../../../constants';
import OrderPromotion from './OrderPromotion';

type OrderTotalProps = {
  order: any;
  totalProductPrice: number;
  showFees: boolean;
};

export default function OrderTotal({
  order,
  totalProductPrice,
  showFees,
}: OrderTotalProps) {
  const { orderType: pendingOrderType } = useAppSelector(
    (state) => state.pendingOrderReducer
  );
  const { commande, totalPriceOrder } = order;
  let { promotion, orderType, managementFee, userServicePrice } = order;
  if (!commande) {
    promotion = getPromotion(totalProductPrice);
    const servicePrice = getUserServicePrice();
    managementFee =
      getManagementFee(totalProductPrice) || servicePrice.managementFee;
    userServicePrice = servicePrice.servicePrice;
    orderType = pendingOrderType;
  }

  return (
    <>
      {((showFees && orderType === ORDER_TYPE_DELIVERY) ||
        promotion?.reconversionType ===
          MODIFY_ORDER_TYPE_PROMOTION.CLICK_AND_COLLECT) && (
        <div className='row'>
          <div className={`col-8`}>Livraison</div>
          <div className={`col-4 text-right `}>
            {promotion && promotion.type === PROMOTION_DELIVERY_FREE
              ? displayPriceFormat(0)
              : displayPriceFormat(userServicePrice)}
          </div>
        </div>
      )}
      {showFees && managementFee > 0 && (
        <div className='row'>
          <div className={`col-8`}>Frais et Services</div>
          <div className={`col-4 text-right`}>
            {displayPriceFormat(managementFee)}
          </div>
        </div>
      )}
      {promotion && (
        <>
          {promotion.type === PROMOTION_DELIVERY_FREE && (
            <OrderPromotion
              promotion={promotion}
              commande={commande}
              promotionType={'Livraison gratuite'}
              prefix={''}
            />
          )}
          {typeof promotion.type === 'string' &&
            promotion.type.includes(PROMOTION_PERCENTAGE) && (
              <OrderPromotion
                promotion={promotion}
                commande={commande}
                promotionType={promotion.type}
                prefix={'-'}
              />
            )}
          {typeof promotion.type === 'number' && (
            <OrderPromotion
              promotion={promotion}
              commande={commande}
              promotionType={displayPriceFormat(promotion.type)}
              prefix={'-'}
            />
          )}
        </>
      )}
      <div className='row'>
        <div className='col-6'>
          {' '}
          <h4>Total</h4>
        </div>
        <div
          className='col-6 text-right'
          style={{ fontSize: UNIT * 1.3, fontWeight: 'bold' }}
        >
          {!commande
            ? displayPriceFormat(calculateFullPrice())
            : displayPriceFormat(totalPriceOrder)}
        </div>
      </div>
    </>
  );
}
