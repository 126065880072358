import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import _ from 'lodash';
import { Media } from 'react-bootstrap';
import { Radio } from '@mui/material';
import {
  COLOR_LIGHT_GRAY,
  TEXT_VALIDATION_DELETE_CB,
} from '../../../constants';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { showMessageModal } from '../../../redux/actions';
import { deleteCB } from '../PaymentModal.services';

export default function CreditCards({ setSelectedCard, selectedCard }) {
  const dispatch = useAppDispatch();
  const {
    user: { cards },
  } = useAppSelector((state) => state.userReducer);
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  return _.keys(cards).map((key) => {
    const card = cards[key];
    const { name, id } = card;
    return (
      <Media
        as='li'
        className='py-2 border-bottom label'
        onClick={() => {
          setSelectedCard(card);
        }}
        style={{ cursor: 'pointer' }}
        key={key}
      >
        <Media.Body>
          <div className='row'>
            <div className='form-check pl-0 mr-3 align-self-center'>
              <Radio
                onChange={() => setSelectedCard(card)}
                id={name}
                checked={selectedCard.id === id}
                style={{
                  color:
                    selectedCard.id === id ? secondaryColor : COLOR_LIGHT_GRAY,
                }}
                size='small'
              />
            </div>
            <p className='center align-self-center text-secondary no-localization label'>
              {name}
            </p>
            <DeleteIcon
              fontSize='small'
              className='ml-auto text-muted align-self-center label'
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  showMessageModal(TEXT_VALIDATION_DELETE_CB, {
                    onClick: () => deleteCB(id),
                    showCancel: true,
                  })
                );
              }}
            />
          </div>
        </Media.Body>
      </Media>
    );
  });
}
