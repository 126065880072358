import React from 'react';
import { connect } from 'react-redux';
import ConnexionForm from '../ConnexionForm';
import SignupForm from '../SignupForm';
import ForgetPassword from '../ForgetPassword';
import {
  userConnection,
  userSignUp,
  userForgetPassword,
  facebookOrGoogleSignIn,
  resetLogin
} from '../../redux/actions';
import { UNIT } from '../../constants/configuration';
import styles from './SignInSignUpContainer.styles';
class SignInSignUpContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const { resetLogin } = this.props;
    resetLogin();
  }

  onSubmitConnection = (email, password) => {
    const { userConnection } = this.props;
    userConnection(email, password);
  };

  onSubmitSignUp = (email, password, nom, prenom, telephone, phoneObj, newsletter) => {
    const { userSignUp } = this.props;
    userSignUp(email, password, nom, prenom, telephone, phoneObj, newsletter);
  };

  onSubmitForgetPassword = email => {
    const { userForgetPassword } = this.props;
    userForgetPassword(email);
  };

  render() {
    const {
      userConnectionError,
      userSignupError,
      resetPasswordError,
      facebookOrGoogleSignIn,
      signUp,
      showForgetPassword
    } = this.props;
    return (
      <div className={`container-fluid p-2`}>
        <div className='row'>
          {!signUp && !showForgetPassword && (
            <div className='col-12 text-center' style={{ ...styles.space }}>
              <div style={{ width: `${UNIT * 5}%` }}>
                <p className='text-left title label'>Créer un compte ou se connecter</p>
              </div>
              <ConnexionForm
                onSubmit={this.onSubmitConnection}
                facebookOrGoogleSignIn={facebookOrGoogleSignIn}
                errorMessage={userConnectionError}
              />
            </div>
          )}
          {signUp && !showForgetPassword && (
            <div className='col-12 text-center' style={{ ...styles.space }}>
              <p className='title2 label' style={{ marginBottom: UNIT }}>Terminer l'inscription</p>
              <SignupForm onSubmit={this.onSubmitSignUp} errorMessage={userSignupError} />
            </div>
          )}
          {!signUp && showForgetPassword && (
            <div className='col-12 text-center'>
              <h3>Réinitialiser votre mot de passe</h3>
              <ForgetPassword
                onSubmit={this.onSubmitForgetPassword}
                errorMessage={resetPasswordError}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, componentReducer }) => {
  const { signUp, showForgetPassword } = componentReducer;
  const { userSignupError, userConnectionError, resetPasswordError } = userReducer;
  return {
    userSignupError,
    resetPasswordError,
    userConnectionError,
    signUp,
    showForgetPassword
  };
};

export default connect(mapStateToProps, {
  userConnection,
  userSignUp,
  facebookOrGoogleSignIn,
  resetLogin,
  userForgetPassword
})(SignInSignUpContainer);
