import React from 'react';

type OrderItemOptionProps = {
  className: string;
  fontSize: number;
  itemRenderString: string;
  categoryId: any;
};

export default function OrderItemOption({
  className,
  fontSize,
  itemRenderString,
  categoryId,
}: OrderItemOptionProps) {
  return (
    <div key={categoryId}>
      <span
        className={className}
        style={{ fontSize, display: 'inline-block' }}
      >
        {itemRenderString}
      </span>
      <br />
    </div>
  );
}
