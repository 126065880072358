import React from 'react';
import { LOYALTY } from '../../../constants';
import { useAppSelector } from '../../../redux/hook';
import MinCartPriceInfo from './MinCartPriceInfo';

type OrderPromotionProps = {
  promotion: any;
  commande: any;
  promotionType: string;
  prefix: string;
};

export default function OrderPromotion({
  promotion,
  commande,
  promotionType,
  prefix,
}: OrderPromotionProps) {
  const { secondaryColor, name } = useAppSelector(
    (state) => state.configurationReducer
  );

  const isLoyalty = promotion.promoCode === LOYALTY;

  return (
    <div
      className={`row ${
        !commande && promotion.minBasketPrice && 'strike-through-price'
      }`}
    >
      <div
        className={`col-8 no-localization`}
        style={{
          color: !commande && !promotion.minBasketPrice && secondaryColor,
        }}
      >
        {isLoyalty ? `Fidélité ${name}` : `Promotion ${promotion.promoCode}`}
        {promotion.minBasketPrice > 0 && !commande && (
          <MinCartPriceInfo
            promoType={isLoyalty ? 'loyalty' : 'code-promo'}
            minBasketPrice={promotion.minBasketPrice}
          />
        )}
      </div>
      <div
        className='col-4 text-right'
        style={{
          color: !commande && !promotion.minBasketPrice && secondaryColor,
        }}
      >
        {prefix}
        {promotionType}
      </div>
    </div>
  );
}
