import React, { useState } from 'react';
import { isDarkTheme, isStringNotNull } from '../../../utils';
import { UNIT, WHITE } from '../../../constants';

import { useAppSelector } from '../../../redux/hook';
import SectionImage from './components/SectionImage';

export default function Section({ sectionIndex, title, image, sectionKey }) {
  const [background, setBackground] = useState('transparent');
  const [textColor, setTextColor] = useState(false);

  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  const { isHorizontalSection } = useAppSelector(
    (state) => state.componentReducer
  );
  const { selectedSection } = useAppSelector((state) => state.sectionReducer);

  const toggleHover = () => {
    setBackground(secondaryColor);
    setTextColor(true);
  };

  const toggleLeave = () => {
    setBackground('transparent');
    setTextColor(false);
  };

  let anchor = `${sectionKey}`;
  if (sectionIndex === 0) {
    anchor = 'main-container';
  }
  return (
    <a
      className='custom-link'
      key={sectionKey}
      href={`#${anchor}`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleLeave}
    >
      {isHorizontalSection && (
        <div style={{ backgroundColor: background }}>
          <div
            className={`py-4 py-lg-3 custom-border-bottom${
              String(selectedSection) === String(sectionKey)
                ? textColor
                  ? '-active'
                  : ''
                : '-none'
            } mx-2 mx-md-4`}
          >
            <div className='text-center'>
              <h6
                className={textColor ? 'text-white' : 'text-color'}
                style={{ fontSize: UNIT * 1.125, marginBottom: '0px' }}
              >
                {title?.length &&
                  title[0].toUpperCase() + title.slice(1).toLowerCase()}
              </h6>
            </div>
          </div>
        </div>
      )}
      {!isHorizontalSection && (
        <div
          className='row border-bottom py-3'
          style={{ backgroundColor: background }}
        >
          {isStringNotNull(image) && (
            <SectionImage
              image={image}
              title={title}
            />
          )}
          <h6
            className={`text-left mb-0 ${
              isStringNotNull(image) ? 'col-7' : ' px-1 col-12'
            } ${
              !isDarkTheme()
                ? selectedSection === String(sectionKey)
                  ? textColor
                    ? 'text-white'
                    : 'text-color'
                  : textColor
                  ? 'text-white'
                  : 'text-muted'
                : ''
            } text-break align-self-center`}
            style={{
              fontSize: UNIT * 1.125,
              color: isDarkTheme()
                ? selectedSection === String(sectionKey)
                  ? textColor
                    ? WHITE
                    : secondaryColor
                  : WHITE
                : '',
            }}
          >
            {title[0].toUpperCase() + title.slice(1).toLowerCase()}
          </h6>
        </div>
      )}
    </a>
  );
}
