import React, { useEffect, useRef, useState } from 'react';
import 'moment/min/locales';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Home from './Pages/Home';
import Landing from './Pages/Landing';
import MessageModal from './components/MessageModal';
import CartModal from './components/CartModal';
import PaymentModal from './components/PaymentModal';
import InactivityModal from './components/InactivityModal';
import OrderModal from './components/OrderModal';
import Navbar from './components/Navbar';
import HorairesModal from './components/HorairesModal';
import { updateShop } from './redux/actions/shopActions';
import { fetchConfiguration } from './redux/actions/configurationAction';
import { listenerAuth, userConnectionCheck } from './redux/actions/userAction';
import { initializeMessenger } from './api/messenger';
import { initializeFirebase } from './api/firebase/firebaseInit';
import { getShopsData } from './utils/shop';
import {
  BLACK,
  COLOR_LIGHT_GRAY,
  ORDER_TYPE_CLICK_AND_COLLECT,
  UNIT,
  WHITE,
} from './constants';
import {
  checkAccessContent,
  getMetadata,
  interceptConsole,
  isQrCode,
  isSplash,
  isWebView,
} from './utils';
import {
  isCustomerAddressDefined,
  updateAddressAndShop,
} from './utils/customer-address-and-shops';

import ConnexionModal from './components/ConnexionModal';
import AccountModal from './components/AccountModal';
import AddressModal from './components/AddressModal';
import './css/App.css';
import './css/react-phone-input.css';
import './css/App-dark.css';
import LegalNotices from './Pages/LegalNotices/LegalNotices.view';
import ScrollToTop from './components/ScrollToTop';
import { useAppSelector } from './redux/hook';

export default function App() {
  const [stripe, setStripe] = useState(false);
  const [displayAddressModal, setDisplayAddressModal] = useState(false);

  const { shops } = useAppSelector((state) => state.shopReducer);
  const {
    address: customerAddress,
    orderType,
    shopId,
  } = useAppSelector((state) => state.pendingOrderReducer);
  const {
    webapp,
    name,
    logoUrl,
    theme,
    landing,
    secondaryColor,
    stripeApiKey,
    descriptionText,
  } = useAppSelector((state) => state.configurationReducer);

  useEffect(() => {
    const runInit = async () => {
      interceptConsole();
      await initializeFirebase();
      await fetchConfiguration();
      await getShopsData();
      checkAccessContent();
      await userConnectionCheck();
      if (!shopId) {
        // Lorsqu'on recupere depuis les props il n'a pas encore la valeur definie
        updateShop(null);
      }
      if (isWebView()) {
        initializeMessenger();
      }
    };

    runInit();
    return () => listenerAuth();
  }, []);

  useEffect(() => {
    const handleLoadStripe = async () => {
      const stripeResult = await loadStripe(stripeApiKey);
      setStripe(stripeResult);
    };

    if (stripeApiKey) {
      handleLoadStripe();
    }
  }, [stripeApiKey]);

  const prevCustomerAddress = useRef(null);
  const prevShopId = useRef(null);
  const prevOrderType = useRef(null);
  const prevShops = useRef(null);

  useEffect(() => {
    const customerAddressChanged =
      customerAddress &&
      (!customerAddress ||
        !_.isEqual(
          _.omit(prevCustomerAddress.current ?? {}, ['instruction', 'street2']),
          _.omit(customerAddress, ['instruction', 'street2'])
        ));
    const isSelectedShopChanged =
      (prevShopId.current !== shopId && shopId?.length) ||
      (prevOrderType.current !== orderType && orderType?.length);
    if (
      (customerAddressChanged || isSelectedShopChanged) &&
      isCustomerAddressDefined(prevCustomerAddress.current)
    ) {
      updateShop(customerAddress);
    }

    prevCustomerAddress.current = customerAddress;
    prevOrderType.current = orderType;
    prevShopId.current = shopId;
  }, [customerAddress, shopId, orderType]);

  useEffect(() => {
    if (!_.isEqual(prevShops.current, shops) && !_.isEmpty(shops)) {
      const shopKeys = Object.keys(shops);
      if (shopKeys.length === 1) {
        const shopId = shopKeys[0];
        const shop = shops[shopId];
        const { address, configuration } = shop;
        const { orderTypes } = configuration;
        if (
          orderTypes.includes(ORDER_TYPE_CLICK_AND_COLLECT) &&
          orderTypes.length === 1 &&
          !isQrCode()
        ) {
          updateAddressAndShop(shopId, address, ORDER_TYPE_CLICK_AND_COLLECT);
        }
      }
    }
    prevShops.current = shops;
  }, [shops]);

  const { title, description, keywords } = getMetadata(
    name,
    webapp,
    descriptionText
  );
  const landingEnabled = landing?.enabled;
  const navbarEnabled = webapp?.navbar?.enabled;
  const singlePageEnabled = isQrCode() || isWebView();

  return (
    <div className={`${theme}-theme`}>
      {name && (
        <Helmet>
          <title>{title}</title>
          <meta
            name='description'
            content={description}
          />
          <link
            rel='shortcut icon'
            href={logoUrl}
            type='image/x-icon'
          />
          <meta
            name='keywords'
            content={keywords}
          />
        </Helmet>
      )}
      {(!landingEnabled || singlePageEnabled) && (
        <>
          {navbarEnabled && !singlePageEnabled && <Navbar />}
          <Switch>
            <Route
              path='/mentions-legales'
              exact
              component={LegalNotices}
            />
            <Route
              path='/qrcode/:shopIdFromLink'
              component={Home}
            />
            <Route
              path='/'
              component={Home}
            />
          </Switch>
        </>
      )}
      {!singlePageEnabled && !landingEnabled && (
        <>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route
              path='/:shopId'
              exact
              component={Home}
            />
            <Route
              path='/mentions-legales'
              exact
              component={LegalNotices}
            />
          </Switch>
        </>
      )}
      {!singlePageEnabled && landingEnabled && (
        <>
          <Navbar />
          <Switch>
            <Route
              path='/commander/'
              exact
              component={Home}
            />
            <Route
              path='/mentions-legales'
              exact
              component={LegalNotices}
            />
            <Route
              path='/'
              component={Landing}
            />
          </Switch>
        </>
      )}
      <MessageModal />
      {stripe && (
        <>
          <Elements stripe={stripe}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <CartModal
                  elements={elements}
                  stripe={stripe}
                />
              )}
            </ElementsConsumer>
          </Elements>

          <Elements stripe={stripe}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <PaymentModal
                  elements={elements}
                  stripe={stripe}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </>
      )}
      <HorairesModal />
      <ConnexionModal />
      <AccountModal />
      {getCookieConsentValue() === 'true' || displayAddressModal ? (
        <AddressModal />
      ) : null}
      <InactivityModal />
      <OrderModal />
      {!isSplash() && (
        <CookieConsent
          location='bottom'
          expires={365}
          overlay
          buttonText='Tout Accepter'
          buttonStyle={{
            background: secondaryColor || BLACK,
            color: WHITE,
            borderRadius: UNIT / 2,
          }}
          style={{
            alignItems: 'center',
            backgroundColor: COLOR_LIGHT_GRAY,
            justifyContent: 'center',
          }}
          onAccept={() => {
            setDisplayAddressModal(true);
          }}
          overlayStyle={{
            zIndex: 3000,
          }}
        >
          <p className='text-secondary text-justify'>
            Nous utilisons des cookies pour offrir des fonctionnalités utiles et
            mesurer les performances afin d&apos;améliorer votre expérience.
          </p>
        </CookieConsent>
      )}
    </div>
  );
}
