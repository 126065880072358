import React from 'react';
import { Modal } from 'react-bootstrap';
import { PRIMARY, SECONDARY } from '../../../constants';
import Button from '../../Button';
import { useAppDispatch } from '../../../redux/hook';
import { showAccountModal, userSignOut } from '../../../redux/actions';

export default function AccountModalFooter({ pastOrder, setPastOrder }) {
  const dispatch = useAppDispatch();

  return (
    <Modal.Footer className='modal-sticky-footer justify-content-center dark-modal'>
      {pastOrder && (
        <>
          <Button
            type={SECONDARY}
            onClick={() => {
              setPastOrder(false);
            }}
          >
            Retour
          </Button>
        </>
      )}
      {!pastOrder && (
        <>
          <Button
            type={SECONDARY}
            onClick={() => {
              dispatch(showAccountModal(false));
            }}
          >
            Fermer
          </Button>
          <Button
            type={PRIMARY}
            onClick={() => {
              dispatch(showAccountModal(false));
              dispatch(userSignOut());
            }}
          >
            <i className='fas fa-sign-out-alt pr-2' />
            Se déconnecter
          </Button>
        </>
      )}
    </Modal.Footer>
  );
}
