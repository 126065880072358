import React, { useState } from 'react';
import {
  MODIFY_ORDER_TYPE_PROMOTION,
  ORDER_TYPE_DELIVERY,
  PROMOTION_DELIVERY_FREE,
  SECONDARY,
} from '../../../constants';
import { useAppSelector } from '../../../redux/hook';
import Button from '../../Button';
import { changeOrderType } from '../OrderStatus.services';

type OrderStatusEmergencyDeliveryProps = {
  order: any;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OrderStatusEmergencyDelivery({
  order,
  isLoading,
  setIsLoading,
}: OrderStatusEmergencyDeliveryProps) {
  const [loadingChanginOrderType, setLoadingChanginOrderType] = useState(false);
  const { secondaryColor, delivery } = useAppSelector(
    (state) => state.configurationReducer
  );

  const {
    orderType,
    totalProductPriceOrder,
    userServicePrice,
    managementFee,
    promotion,
  } = order;
  const deliveryFreeReduction =
    promotion?.type === PROMOTION_DELIVERY_FREE ? 0 : userServicePrice;
  const reductionToClickAndCollect =
    Math.round(
      delivery.emergency.clickAndCollectConversionReduction *
        totalProductPriceOrder
    ) /
      100 +
    deliveryFreeReduction +
    managementFee;
  return (
    orderType === ORDER_TYPE_DELIVERY &&
    reductionToClickAndCollect > 0 && (
      <div
        style={{ fontSize: 14 }}
        className='text-center'
      >
        <p className='text-gray mt-1'>
          Nous n'avons pas encore trouvé de livreur pour votre commande.
        </p>
        <p className='text-gray mb-2'>
          Si vous ne souhaitez pas attendre, vous pouvez choisir l'une des deux
          options suivantes :
        </p>
        <h5 className='my-2'>OPTION 1</h5>
        <p className='text-gray my-1'>
          Une{' '}
          <strong>
            réduction immédiate de {reductionToClickAndCollect.toFixed(2)} €
          </strong>{' '}
          si vous choisissez de venir chercher votre commande à{' '}
          <strong>Emporter</strong>
        </p>
        <Button
          type={SECONDARY}
          className='my-2'
          disabled={isLoading}
          isLoading={loadingChanginOrderType}
          style={{
            backgroundColor: secondaryColor,
            borderColor: secondaryColor,
          }}
          onClick={async () =>
            await changeOrderType(
              setIsLoading,
              setLoadingChanginOrderType,
              order,
              MODIFY_ORDER_TYPE_PROMOTION.CLICK_AND_COLLECT
            )
          }
        >
          J'économise {reductionToClickAndCollect.toFixed(2)} € et je me déplace
        </Button>
        <h5 className='my-2'>OPTION 2</h5>
        <p className='text-gray my-1'>
          Un code promo de{' '}
          <strong>{delivery.emergency.refundReduction}%</strong> sur votre
          prochaine commande, si vous choisissez d'annuler votre commande
        </p>
        <Button
          type={SECONDARY}
          className='my-2'
          disabled={loadingChanginOrderType}
          isLoading={isLoading}
          style={{
            backgroundColor: 'gray',
            borderColor: 'gray',
          }}
          onClick={async () =>
            await changeOrderType(
              setIsLoading,
              setLoadingChanginOrderType,
              order,
              MODIFY_ORDER_TYPE_PROMOTION.REFUND
            )
          }
        >
          J'annule ma commande
        </Button>
      </div>
    )
  );
}
