import React, { useEffect } from 'react';
import { calculateProductPrice } from '../../utils';
import apiService from '@dishopsaas/dishop-backend-api-service';
import store from '../../redux/store';
import { UPDATE_LOYALTY } from '../../redux/actions/types';
import Cart from './components/Cart';
import OrderDetail from './components/OrderDetail';

export default function Order({
  showCart = false,
  order,
  showPromo = false,
  deleteOption = false,
  showFees = false,
  fetchLoyalty = false,
}) {
  const totalProductPrice = calculateProductPrice();

  useEffect(() => {
    const handleFetchLoyalty = async () => {
      const loyalty = await apiService.configurationGetOne(['loyalty']);
      store.dispatch({ type: UPDATE_LOYALTY, payload: loyalty });
    };

    if (fetchLoyalty) handleFetchLoyalty();

  }, [fetchLoyalty]);

  return showCart ? (
    <Cart
      order={order}
      showPromo={showPromo}
      deleteOption={deleteOption}
      totalProductPrice={totalProductPrice}
    />
  ) : (
    <OrderDetail
      order={order}
      showFees={showFees}
      deleteOption={deleteOption}
      totalProductPrice={totalProductPrice}
    />
  );
}
