import { ITEM_KEY_CATEGORY_SEPARATOR } from '../../constants';
import _ from 'lodash';
import apiService from '@dishopsaas/dishop-backend-api-service';
import store from '../../redux/store';
import { removeToCommande } from '../../redux/actions';
import { sendGoogleAnalytics } from '../../api';

export const findOptionParent = (option: any, items: any) => {
  const { categoryKey: optionKey } = option;
  const lastOptionKey = _.last(optionKey.split(ITEM_KEY_CATEGORY_SEPARATOR));

  let foundOptionParent = null;
  _.forEach(items, (category) => {
    if (!foundOptionParent && category?.options) {
      foundOptionParent = _.find(category.options, (option) => {
        if (option.subcategories) {
          const lastItemRecursiveKey = _.last(
            option.itemRecursiveKey.split(ITEM_KEY_CATEGORY_SEPARATOR)
          );
          return (
            option.subcategories.includes(lastOptionKey) &&
            optionKey.includes(lastItemRecursiveKey)
          );
        }
        return false;
      });
      foundOptionParent = foundOptionParent && {
        ...foundOptionParent,
        categoryMultiple: category.categoryMultiple,
      };
    }
  });

  return foundOptionParent;
};

type DeleteItemParams = {
  userConnected: boolean;
  isUserAnonymous: boolean;
  customerId: string;
  cartItemKey: string;
  cartItem: any;
};

export const deleteItem = async ({
  userConnected,
  isUserAnonymous,
  customerId,
  cartItemKey,
  cartItem,
}: DeleteItemParams) => {
  const { nb, name, price, position, sectionName, key } = cartItem;
  if (userConnected || isUserAnonymous) {
    await apiService.pendingOrdersDelete([customerId, 'commande', cartItemKey]);
  } else {
    store.dispatch(removeToCommande(cartItemKey));
  }
  sendGoogleAnalytics('remove_from_cart', {
    items: [
      {
        id: key,
        name,
        price,
        position,
        category: sectionName,
        quantity: nb,
      },
    ],
  });
};
