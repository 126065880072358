import React from 'react';
import {
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  PAYMENT_TYPE_STRIPE,
  TEXT_CANCEL_ORDER,
} from '../../../constants';
import { cancelOrder } from '../OrderStatus.services';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import _ from 'lodash';
import { showMessageModal } from '../../../redux/actions';

type OrderStatusFooterProps = {
  order: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OrderStatusFooter({
  order,
  setIsLoading,
}: OrderStatusFooterProps) {
  const dispatch = useAppDispatch();
  const { support } = useAppSelector((state) => state.shopReducer);

  const {
    secondaryColor,
    cancel: { disabled: disabledCancel },
  } = useAppSelector((state) => state.configurationReducer);

  const { status, selfPickupEligible, userCancelServicePrice } = order;
  const phone = order.numeroDep ? order.numeroDep : support?.number;
  if (!_.isEmpty(order)) {
    switch (status) {
      case ORDER_PHASE_CONTACTING: {
        return (
          <>
            {!disabledCancel && !selfPickupEligible && (
              <div
                className='button-bottom-modal'
                onClick={() => {
                  dispatch(
                    showMessageModal(TEXT_CANCEL_ORDER, {
                      onClick: () => cancelOrder(setIsLoading, order),
                      showCancel: true,
                    })
                  );
                }}
              >
                <i
                  className='fas fa-times-circle fa-2x'
                  style={{ color: secondaryColor }}
                />
                <p className='text-muted'>Annuler la commande</p>
              </div>
            )}
          </>
        );
      }
      case ORDER_PHASE_PREPARING: {
        return (
          <>
            {!disabledCancel && order.paymentType === PAYMENT_TYPE_STRIPE && (
              <div
                className='button-bottom-modal'
                onClick={() => {
                  dispatch(
                    showMessageModal(
                      `Êtes-vous sûr(e) de vouloir annuler votre commande ?${
                        userCancelServicePrice > 0
                          ? `\nVous serez facturé d'un montant de ${userCancelServicePrice}€.`
                          : ''
                      }`,
                      {
                        onClick: () => cancelOrder(setIsLoading, order),
                        showCancel: true,
                      }
                    )
                  );
                }}
              >
                <i
                  className='fas fa-times-circle fa-2x'
                  style={{ color: secondaryColor }}
                />
                <p className='text-muted'>Annuler la commande</p>
              </div>
            )}
          </>
        );
      }
      case ORDER_PHASE_DELIVERING: {
        return (
          <>
            <div
              className='button-bottom-modal'
              onClick={() => window.open(`tel:${phone}`)}
            >
              <p className='text-muted mb-0 label'>
                Contactez le livreur en cas de problème
              </p>
              <p className='text-muted'>{phone}</p>
            </div>
          </>
        );
      }
      default:
        break;
    }
  }
}
