import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { isStringNotNull } from '../../../utils';
import PhoneInput from '../../PhoneInput';
import { updateProfile } from '../../../redux/actions';
import _ from 'lodash';

type AskPhoneProps = {
  isPhoneIncorrect: boolean;
  setIsPhoneIncorrect: React.Dispatch<React.SetStateAction<boolean>>;
  phoneObj: any;
  setPhoneObj: React.Dispatch<React.SetStateAction<any>>;
};

export default function AskPhone({
  isPhoneIncorrect,
  setIsPhoneIncorrect,
  phoneObj,
  setPhoneObj,
}: AskPhoneProps) {
  const dispatch = useAppDispatch();

  const {
    user: { numero },
  } = useAppSelector((state) => state.userReducer);

  const className = `form-control ${
    isPhoneIncorrect || !isStringNotNull(numero) ? 'is-invalid' : ''
  }`;

  return (
    <div className='row mb-2'>
      <div className='col'>
        <p className='text-muted mb-2 label'>Votre numéro</p>
        <div className='input-group mb-2'>
          <PhoneInput
            value={numero}
            onChange={(phone, country, e, formattedPhone) => {
              dispatch(updateProfile('numero', formattedPhone));
            }}
            className={`dark-modal ${className}`}
            isPhoneIncorrect={(isPhoneIncorrect) => {
              setIsPhoneIncorrect(isPhoneIncorrect);
            }}
            getPhoneObj={(obj) => {
              _.isEqual(obj, phoneObj) ? null : setPhoneObj(obj);
            }}
          />
          {(isPhoneIncorrect || !isStringNotNull(numero)) && (
            <div className='invalid-feedback-block'>
              Veuillez fournir un telephone valide
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
