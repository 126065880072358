import { InfoOutlined } from '@mui/icons-material';
import React from 'react'
import { useAppDispatch } from '../../../redux/hook';
import { showMessageModal } from '../../../redux/actions';

type MinCartPriceInfoProps={
    promoType:string,
    minBasketPrice:string|number
}

export default function MinCartPriceInfo({ promoType, minBasketPrice }:MinCartPriceInfoProps) {
    const dispatch = useAppDispatch()
  return (
    <InfoOutlined
        className='ml-2 infoIcon'
        onClick={() => {
          dispatch(
            showMessageModal(
              <span>
                Afin de
                <b>
                  {' '}
                  profiter de votre{' '}
                  {promoType === 'code-promo' ? 'code promo' : 'fidélité '}{' '}
                </b>
                , votre panier de commande doit être{' '}
                <b>supérieur à {minBasketPrice}€ </b>
              </span>,
              {
                text: `${
                  promoType === 'code-promo' ? 'Code promo' : 'Fidélité '
                } non applicable`,
                buttonName: 'J’ai compris',
              }
            )
          );
        }}
      />
  )
}
