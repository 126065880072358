import React from 'react';
import { useAppSelector } from '../../../redux/hook';
import Spinner from '../../Spinner';
import { isDarkTheme, isObjectNotNull, isStringNotNull } from '../../../utils';
import {
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_DELIVERY,
  UNIT,
} from '../../../constants';
import Loyalty from '../../Loyalty';
import OrderItem from '../../OrderItem';
import OrderTotal from './OrderTotal';
import _ from 'lodash';
import AddPromoCode from './AddPromoCode';

type CartProps = {
  order: any;
  showPromo: boolean;
  deleteOption: boolean;
  totalProductPrice: number;
};

export default function Cart({
  order,
  showPromo,
  deleteOption,
  totalProductPrice,
}: CartProps) {
  const { userConnected, isUserAnonymous } = useAppSelector(
    (state) => state.userReducer
  );
  const { userServicePriceInfo } = useAppSelector((state) => state.shopReducer);
  const { orderType: pendingOrderType, address: customerAddress } =
    useAppSelector((state) => state.pendingOrderReducer);
  const { orderType, commande, orderNumber } = order;
  const showReduction = (userConnected || isUserAnonymous) && showPromo;
  const displaySpinner = !userServicePriceInfo && customerAddress;

  let orderTypeLabel = '';
  if (customerAddress || orderType) {
    if ((orderType || pendingOrderType) === ORDER_TYPE_DELIVERY) {
      orderTypeLabel = 'En livraison';
    } else if ((orderType || pendingOrderType) === ORDER_TYPE_CLICK_AND_SEAT) {
      orderTypeLabel = 'Sur place';
    } else {
      orderTypeLabel = `À emporter`;
    }
  }

  const renderItems = (commande) => {
    return _.keys(commande).map((key) => {
      const item = commande[key];
      return (
        <OrderItem
          cartItem={item}
          key={key}
          cartItemKey={key}
          deleteOption={deleteOption}
        />
      );
    });
  };

  return displaySpinner ? (
    <div className='text-center'>
      <Spinner />
    </div>
  ) : !isObjectNotNull(order) ? (
    <div className='text-center'>
      <p
        className={`text-secondary mb-0 label ${
          isUserAnonymous ? 'm-0 py-3 px-1' : ''
        }`}
      >
        Votre panier est vide
      </p>
    </div>
  ) : (
    <div>
      <div className={`px-sm-3 mb-4 ${!isDarkTheme() && 'text-color'}`}>
        <h3
          className='text-muted mb-1 d-flex justify-content-center label'
          style={{ fontSize: UNIT * 1.5 }}
        >
          {`Votre commande ${
            isStringNotNull(orderNumber) ? `n°${orderNumber} ` : ''
          }:`}
        </h3>
        <p className='mb-4 text-muted font-italic font-weight-light d-flex justify-content-center label'>{`- ${orderTypeLabel} -`}</p>
        {renderItems(commande || order)}
        {showReduction && <AddPromoCode totalProductPrice={totalProductPrice} />}
        {showReduction && !isUserAnonymous && (
          <div>
            <Loyalty totalProductPrice={totalProductPrice} />
          </div>
        )}
        {showReduction && <hr className='my-4 hr' />}
        <OrderTotal
          order={order}
          totalProductPrice={totalProductPrice}
          showFees
        />
      </div>
    </div>
  );
}
