import React from 'react';
import {
  getFirstProductCategory,
  isStringNotNull,
} from '../../../../../../../../../../../utils';
import { getOptionData } from '../Category.services';
import OptionIcon from './OptionIcon';
import styles from '../Category.styles';
import { useAppSelector } from '../../../../../../../../../../../redux/hook';

type OptionLabel = {
  item: any;
  notAvailable: any;
  product: any;
  categoryKey: any;
};

export default function OptionLabel({
  item,
  notAvailable,
  product,
  categoryKey,
}) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  const { price, isFree } = item;
  let formatPrice = `+${price} €`;
  const firstProductCategory = getFirstProductCategory(product);
  if (
    firstProductCategory &&
    firstProductCategory.path === categoryKey &&
    product.price <= 0
  ) {
    formatPrice = `${price} €`;
  }
  const { image: optionIconUrl, description: optionDescription } =
    getOptionData(item);
  const hasIcon = isStringNotNull(optionIconUrl);

  return (
    <div
      className={`row align-items-center`}
      style={{
        flex: 1,
        opacity: notAvailable && 0.2,
      }}
    >
      {hasIcon && <OptionIcon optionIconUrl={optionIconUrl} />}
      <div
        className={`row align-items-center`}
        style={{ flex: 1, textAlign: 'start' }}
      >
        <div style={styles.divNameAndDescription}>
          <p
            className={`text-break col-8 col-sm-9`}
            style={{ ...styles.productName }}
          >
            {item?.name?.substring(0, 40)}
          </p>
          {isStringNotNull(optionDescription) && (
            <p style={styles.optionDescription}>{optionDescription}</p>
          )}

          {!isFree && price > 0 && (
            <div
              className={`text-break text-muted text-option-price-size ml-auto`}
            >
              <span
                className='no-localization'
                style={{ fontSize: 14, color: secondaryColor }}
              >
                {price !== 0 ? formatPrice : ''}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
