import {
  CUSTOMER_ADDRESS_CHECK,
  IS_GOOGLE_MAPS_ENABLED,
  IS_MOBILE,
  MESSAGE_MODAL,
  SET_NAVBAR_HEIGHT,
  SHOW_ACCOUNT_MODAL,
  SHOW_ADDRESS_MODAL,
  SHOW_CART_MODAL,
  SHOW_CHOOSE_SHOP,
  SHOW_CONNEXION_MODAL,
  SHOW_FORGET_PASSWORD,
  SHOW_HORIZONTAL_SECTION,
  SHOW_HOURS_MODAL,
  SHOW_INACTIVITY_MODAL,
  SHOW_ORDER_MODAL,
  SHOW_ORDER_STATUS,
  SHOW_OVERLAY_COVER,
  SHOW_PAYMENT_MODAL,
  SHOW_PRODUCT_MODAL,
  SHOW_SIGNUP,
  SHOW_SPLASH,
  SHOW_UPDATE_INFO,
  UPDATE_ADDRESS_TEMP
} from '../actions/types'
import { START_CONTENT } from '../../constants'

const INITIAL_STATE = {
  messageModal: null,
  dataModal: {},
  paymentModal: false,
  cartModal: false,
  productModal: false,
  hoursModal: false,
  connexionModal: false,
  chooseShopModal: false,
  addressModal: false,
  splash: true,
  signUp: false,
  showForgetPassword: false,
  accountModal: false,
  addressInputModal: false,
  isMobile: false,
  navbarHeight: '16px',
  overlayCover: START_CONTENT,
  inactivityModal: false,
  isHorizontalSection: false,
  googleMapsEnabled: false,
  chooseShop: false,
  addressTemp: null,
  updateInfo: false,
  orderModal: false,
  orderStatus: false,
  customerAddressCheck: 0
};

export const CUSTOMER_ADDRESS_CHECK_VALUES = {
  INVALID: 0,
  PENDING: 1,
  VALID: 2
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGE_MODAL:
      return {
        ...state,
        messageModal: action.payload.message,
        dataModal: action.payload.data
      };
    case SHOW_PAYMENT_MODAL:
      return { ...state, paymentModal: action.payload };
    case SHOW_CART_MODAL:
      return { ...state, cartModal: action.payload };
    case SHOW_PRODUCT_MODAL:
      return { ...state, productModal: action.payload };
    case SHOW_SPLASH:
      return { ...state, splash: action.payload };
    case SHOW_HOURS_MODAL:
      return {
        ...state,
        hoursModal: action.payload
      };
    case SHOW_CONNEXION_MODAL:
      return { ...state, connexionModal: action.payload };
    case SHOW_SIGNUP:
      return { ...state, signUp: action.payload };
    case SHOW_FORGET_PASSWORD:
      return { ...state, showForgetPassword: action.payload };
    case SHOW_ACCOUNT_MODAL:
      return { ...state, accountModal: action.payload };
    case SHOW_ADDRESS_MODAL:
      if (state?.customerAddressCheck === CUSTOMER_ADDRESS_CHECK_VALUES.PENDING) {
        return state;
      }
      return { ...state, addressModal: action.payload };
    case SHOW_CHOOSE_SHOP:
      return { ...state, chooseShop: action.payload };
    case IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case SET_NAVBAR_HEIGHT:
      return { ...state, navbarHeight: action.payload };
    case SHOW_OVERLAY_COVER:
      return { ...state, overlayCover: action.payload };
    case SHOW_INACTIVITY_MODAL:
      return { ...state, inactivityModal: action.payload };
    case SHOW_HORIZONTAL_SECTION:
      return { ...state, isHorizontalSection: action.payload };
    case IS_GOOGLE_MAPS_ENABLED:
      return { ...state, googleMapsEnabled: action.payload };
    case UPDATE_ADDRESS_TEMP:
      return { ...state, addressTemp: { ...state?.addressTemp, ...action.payload } };
    case SHOW_UPDATE_INFO:
      return { ...state, updateInfo: action.payload };
    case SHOW_ORDER_MODAL:
      return { ...state, orderModal: action.payload };
    case SHOW_ORDER_STATUS:
      return { ...state, orderStatus: action.payload };
    case CUSTOMER_ADDRESS_CHECK:
      return { ...state, customerAddressCheck: action.payload };
    default:
      return state;
  }
};
