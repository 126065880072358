import { UNIT } from '../../../../constants/configuration';

const styles = {
  productCard: { width: '100%', cursor: 'pointer', borderRadius: UNIT },
  productPicture: {
    borderRadius: `${UNIT}px ${UNIT}px 0px 0px`
  },
  productPictureBorder: {
    borderRadius: `${UNIT}px 0px 0px ${UNIT}px`
  },
};

export default styles;
