import React from 'react';
import Product from './Product/Product.view';
import { isProductDisabled } from '../../../utils';
import _ from 'lodash';
import { useAppSelector } from '../../../redux/hook';
import { PRODUCT_STYLE_GRID, PRODUCT_STYLE_LIST } from '../../../constants';

export default function ProductList({ section, messages }) {
  const { productStyle } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { isHorizontalSection } = useAppSelector(
    (state) => state.componentReducer
  );

  const filteredSection = () => {
    return _.keys(section.products)
      .sort((a, b) => {
        const productA = section.products[a];
        const productB = section.products[b];
        if (productA && productB) {
          const positionA = isProductDisabled(productA)
            ? 70 + productA.position
            : productA.position;
          const positionB = isProductDisabled(productB)
            ? 70 + productB.position
            : productB.position;
          return positionA - positionB;
        } else return 0;
      })
      .filter((key) => {
        return !section.products[key].hidden;
      });
  };

  return section ? (
    <>
      {filteredSection().map((key) => {
        const product = section.products[key];
        if (product?.name) {
          let productClass = 'col-6 col-lg-3 col-md-4 d-flex p-0 px-1';
          if (productStyle === PRODUCT_STYLE_GRID) {
            if (isHorizontalSection) {
              productClass += ' px-xl-4';
            } else {
              productClass += ' px-xl-3';
            }
          }
          if (productStyle === PRODUCT_STYLE_LIST) {
            productClass = `col-12 col-lg-4 col-md-6 pr-0 pr-md-3 `;
          }
          return (
            <div
              className={productClass}
              key={product.key || product.name}
            >
              <Product
                product={product}
                title={product.name}
                image={product.image}
                price={product.price}
                description={product.description}
                productKey={key}
                section={section}
                messagesSectionRange={messages}
              />
            </div>
          );
        }
      })}
    </>
  ) : (
    <></>
  );
}
