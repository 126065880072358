import React from 'react';
import {
  ORDER_FLOW_TYPE,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_PREPARING,
  ORDER_TYPE_DELIVERY,
  PICKUP_STATUS,
} from '../../../constants';
import { getAddressFormatted, getShopFromOrder } from '../../../utils';

type OrderStatusAddressProps = {
  order: any;
};

export default function OrderStatusAddress({ order }: OrderStatusAddressProps) {
  const { address, orderType, pickupStatus } = order;
  const shop = getShopFromOrder(order);
  const { address: addressShop } = shop;
  const isContactingEmergency =
    order?.status == ORDER_PHASE_CONTACTING &&
    order?.selfPickupEligible &&
    order?.orderFlowType === ORDER_FLOW_TYPE.REVERSE &&
    pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED;
  const isPreparingEmergency =
    order?.status == ORDER_PHASE_PREPARING &&
    order?.selfPickupEligible &&
    order.pickupStatus < PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT;

  return (
    <div>
      <p className='text-left text-gray mb-2 label'>
        {orderType === ORDER_TYPE_DELIVERY &&
          !isContactingEmergency &&
          !isPreparingEmergency && (
            <p className='text-left  mb-2 label'>
              {' '}
              Adresse de livraison :
              <strong> {getAddressFormatted(address)}</strong>
            </p>
          )}
        {orderType !== ORDER_TYPE_DELIVERY && (
          <p className='text-left mb-2 label'>
            {' '}
            Adresse du commerce :
            <strong> {getAddressFormatted(addressShop)}</strong>
          </p>
        )}
      </p>
    </div>
  );
}
