import { PRIMARY, UNIT, WHITE } from '../../constants';
import Spinner from '../Spinner';
import styles from './AddressInput.styles';
import Button from '../Button';
import React from 'react';
import { useAddressInputModal } from './useAddressInputModal';
import { getOrderTypeLabel, isQrCode } from '../../utils';
import OrderTypeSwitch from './components/OrderTypeSwitch';
import ShopsDropdownList from './components/ShopsDropdownList';
import AddressSearchAutocompleteInput from './components/AddressSearchAutocompleteInput';
import AddressSelectionRadioButtons from './components/AddressSelectionRadioButtons';

export default function AddressInputModal() {
  const {
    showShopsDropdownList,
    handleAddressTypeSelection,
    handleOrderTypeSelection,
    handleAddressSelection,
    setTypedAddress,
    handleAddressInputFormControlError,
    handleShopSelection,
    handleShopsDropdownListClick,
    errorMessage,
    isMobile,
    addressModal,
    closestShops,
    isCustomerAddress,
    customerAddress,
    secondaryColor,
    orderTypes,
    orderTypeChoiceIndex,
    typedAddress,
    handleValidationButtonClick,
    shopDropdowListText,
    isFilledAddressValid,
    openShopsDropdownList,
    shouldRenderAddressSelectionRadioButton,
    orderType,
    hasErrorAddressMessage,
    shouldRenderAddressSearchInput,
    getShopsIsLoading,
    animatePlaceholder,
    inputElementRef,
    chooseShop,
    isFirstTimeFetching,
    displayIsSavingSpinner,
  } = useAddressInputModal();

  const constructValidateButtonText = (isMobile: boolean, orderType: string) =>
    `Valider ${
      isMobile ? `${getOrderTypeLabel(orderType)?.toLowerCase()}` : ''
    } `;

  return (
    <div className={`${!addressModal ? 'px-0 dishop-iso dark-modal' : 'px-0'}`}>
      <div className={'dishop-iso dark-modal'}>
        <p
          style={{ marginTop: 0 }}
          className='text-center  title2 label'
        >
          Passer une commande
        </p>
        <div className='mt-3'>
          <OrderTypeSwitch
            orderTypes={orderTypes}
            isMobile={isMobile}
            orderTypeChoiceIndex={orderTypeChoiceIndex}
            handleOrderTypeSelection={handleOrderTypeSelection}
            secondaryColor={secondaryColor}
          />
          {isFirstTimeFetching && !closestShops?.length ? (
            <div className='row justify-content-center'>
              <Spinner />
            </div>
          ) : (
            <>
              {!isQrCode() && <>{shouldRenderAddressSelectionRadioButton && (
                <AddressSelectionRadioButtons
                  isCustomerAddress={isCustomerAddress}
                  handleAddressTypeSelection={handleAddressTypeSelection}
                  getShopsIsLoading={getShopsIsLoading}
                  secondaryColor={secondaryColor}
                  customerAddress={customerAddress}
                  orderType={orderType}
                />
              )}
              <div className='mt-3'>
                {shouldRenderAddressSearchInput && (
                  <AddressSearchAutocompleteInput
                    typedAddress={typedAddress}
                    setTypedAddress={setTypedAddress}
                    handleAddressSelection={handleAddressSelection}
                    handleAddressInputFormControlError={
                      handleAddressInputFormControlError
                    }
                    hasErrorAddressMessage={hasErrorAddressMessage}
                    getShopsIsLoading={getShopsIsLoading}
                    showShopsDropdownList={showShopsDropdownList}
                    inputElementRef={inputElementRef}
                    animatePlaceholder={animatePlaceholder}
                    isFilledAddressValid={isFilledAddressValid}
                  />
                )}
                <ShopsDropdownList
                  showShopsDropdownList={showShopsDropdownList}
                  openShopsDropdownList={openShopsDropdownList}
                  hasErrorAddressMessage={hasErrorAddressMessage}
                  errorMessage={errorMessage}
                  getShopsIsLoading={getShopsIsLoading}
                  shopDropdowListText={shopDropdowListText}
                  handleShopsDropdownListClick={handleShopsDropdownListClick}
                  closestShops={closestShops}
                  secondaryColor={secondaryColor}
                  handleShopSelection={handleShopSelection}
                />
              </div>
              {errorMessage && !getShopsIsLoading && (
                <p style={styles.errorText}>{errorMessage}</p>
              )}</>}

              <Button
                disabled={
                  errorMessage ||
                  (getShopsIsLoading && chooseShop) ||
                  displayIsSavingSpinner
                }
                type={PRIMARY}
                className='btn position-relative'
                style={styles.btn}
                onClick={() => handleValidationButtonClick()}
              >
                {getShopsIsLoading && !chooseShop ? (
                  <Spinner
                    color={WHITE}
                    size='sm'
                  />
                ) : (
                  constructValidateButtonText(isMobile, orderType)
                )}
                {displayIsSavingSpinner && (
                  <span
                    className='position-absolute'
                    style={{ right: UNIT }}
                  >
                    <Spinner
                      color={WHITE}
                      size='sm'
                    />
                  </span>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
