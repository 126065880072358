import React from 'react';
import Button from '../../Button';
import { UNIT } from '../../../constants';
import { getDayFormat, isStringNotNull } from '../../../utils';

type CustomDayPickerInputProps = {
  value?: string;
  isValidDate: boolean;
  orderTime: any;
  closed: boolean;
  onClick?: () => void;
};

export default function CustomDayPickerInput({
  value,
  isValidDate,
  orderTime,
  closed,
  onClick,
}: CustomDayPickerInputProps) {
  return (
    <Button
      className={`text-left w-100 ${
        isValidDate || orderTime ? 'datepicker-input' : 'border-red'
      }  label`}
      style={{ borderRadius: UNIT / 4 }}
      onClick={onClick}
    >
      {!closed && isStringNotNull(value) ? (
        getDayFormat(value)
      ) : (
        <div style={{ height: UNIT * 1.5 }} />
      )}
    </Button>
  );
}
