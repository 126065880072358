import React, { useState } from 'react';
import _ from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCart';
import UpdateIcon from '@mui/icons-material/Update';
import '../../css/react-tabs.css';
import Spinner from '../Spinner';

import Order from '../Order';
import { useAppSelector } from '../../redux/hook';
import OrderStatusFooter from './component/OrderStatusFooter';
import OrderStatusAddress from './component/OrderStatusAddress';
import OrderStatusStatus from './component/OrderStatusStatus';

type OrderStatusProps = {
  chargeId: string;
};

export default function OrderStatus({ chargeId }: OrderStatusProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { orders } = useAppSelector((state) => state.orderReducer);
  const order = orders[chargeId];

  return _.isEmpty(order) ? (
    <div className='row justify-content-center'>
      <Spinner />
    </div>
  ) : (
    <div className='row'>
      <div className='container-fluid text-center'>
        <Tabs>
          <TabList>
            <Tab style={{ width: '45%' }}>
              <div className='row justify-content-center'>
                <UpdateIcon className='mr-2' />
                <p className='mb-0 d-none d-sm-block'>Statut</p>
              </div>
            </Tab>
            <Tab style={{ width: '45%' }}>
              <div className='row justify-content-center'>
                <ShoppingCartOutlinedIcon className='mr-2' />
                <p className='mb-0 d-none d-sm-block'>Commande</p>
              </div>
            </Tab>
          </TabList>

          <TabPanel>
            <div>
              <OrderStatusStatus
                order={order}
                chargeId={chargeId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              <OrderStatusAddress order={order} />
              <OrderStatusFooter
                order={order}
                setIsLoading={setIsLoading}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              className='text-left'
              style={{ paddingTop: '16px', marginBottom: '16px' }}
            >
              <Order
                order={order}
                showCart
                showFees
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
