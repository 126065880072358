import React from 'react';
import {
  getProductUnits,
  isChatbot,
  isDarkTheme,
  isStringNotNull,
} from '../../../../../../../utils';
import { getMinimumStockRemaining } from '../ProductModal.services';
import { useAppSelector } from '../../../../../../../redux/hook';
import _ from 'lodash';
import { COLOR_LIGHT_GRAY, UNIT } from '../../../../../../../constants';
import Button from '../../../../../../Button';
import AddToCartButton from './AddToCartButton.tsx/AddToCartButton.view';
import SpecialInstrutions from './Categories/components/SpecialInstrutions';

type SelectItemLastStepProps = {
  items: any;
  nb: number;
  setNb: React.Dispatch<React.SetStateAction<number>>;
  unit: any;
  product: any;
  productCategories: any;
  stepOptionIndex: any;
  modalOptionContainer: any;
  shopCategories: any;
  price: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mandatoryCategories: any;
  productKey: any;
  instruction: any;
  sectionName: any;
  sectionKey: any;
  resetComponent: any;
  loading: any;
  setUnit: React.Dispatch<React.SetStateAction<any>>;
  setInstruction: React.Dispatch<React.SetStateAction<any>>;
};

export default function SelectItemLastStep({
  items,
  nb,
  setNb,
  unit,
  product,
  productCategories,
  stepOptionIndex,
  modalOptionContainer,
  shopCategories,
  price,
  setLoading,
  mandatoryCategories,
  productKey,
  instruction,
  sectionName,
  sectionKey,
  resetComponent,
  loading,
  setUnit,
  setInstruction,
}: SelectItemLastStepProps) {
  const { secondaryColor, disableProductInstruction } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { stockRemaining, name: stockName } = getMinimumStockRemaining({
    items,
    nb,
    unit,
    product,
  });
  const units = getProductUnits(product.unit);
  const categoriesLength = productCategories.length + 1;

  const updateWeight = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newNb = parseInt(e.target.value);
    if (newNb >= 0 || !isStringNotNull(newNb)) {
      setNb(newNb);
    }
  };

  const addOne = (): void => {
    const result = getMinimumStockRemaining({ items, nb, unit, product });
    if (_.isEmpty(result) || result.stockRemaining > 0) {
      const nbIncremented = nb + 1;
      setNb(nbIncremented);
    }
  };

  const removeOne = (): void => {
    const nbDecremented = nb === 0 ? 0 : nb - 1;
    setNb(nbDecremented);
  };

  return (
    <div
      className={`row col-12 mt-4 ${categoriesLength > 1 && 'p-4'}`}
      key={stepOptionIndex}
      style={
        productCategories.length === 0 || isDarkTheme()
          ? {}
          : modalOptionContainer
      }
    >
      <div
        className={`row col d-flex justify-content-center`}
        style={{ display: 'flex' }}
      >
        {productCategories.length === 0 ? (
          <div />
        ) : (
          <div className={`col-12 align-self-center`}>
            Combien en voulez-vous ?
          </div>
        )}

        {!unit ? (
          <div
            className={`${categoriesLength > 1 && 'pt-4'}
                    }`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              className={`no-outline ${
                nb > 0 ? 'label' : 'disabled-label'
              } 'p-0'}`}
              onClick={removeOne}
            >
              <i
                className='fas fa-minus-circle mx-2'
                style={{
                  fontSize: 40,
                }}
              />
            </Button>
            <p
              className={`${'mx-2 label'}`}
              style={{ margin: '0px', fontSize: UNIT * 1.5 }}
            >
              {nb}
            </p>
            <Button
              className={`no-outline p-0`}
              onClick={addOne}
              disabled={stockRemaining <= 0}
            >
              <i
                className='fas fa-plus-circle mx-2'
                style={{
                  color:
                    stockRemaining <= 0 ? COLOR_LIGHT_GRAY : secondaryColor,
                  fontSize: 40,
                }}
              />
            </Button>
          </div>
        ) : (
          <form className={`needs-validation pt-4`}>
            <div
              className='form-group'
              style={{
                display: 'flex',
                width: 'fit-content',
                margin: 'auto',
              }}
            >
              <div className='input-group mr-3'>
                <input
                  type='number'
                  className='form-control'
                  style={{ width: '70px' }}
                  value={nb}
                  onChange={updateWeight}
                  min={0}
                />
                <div className='input-group-append'>
                  {units && units.length > 1 && (
                    <div
                      className='btn-group btn-group-toggle'
                      data-toggle='buttons'
                    >
                      {units.map((u, index) => {
                        return (
                          <label
                            key={index}
                            className={`btn btn-light border ${
                              u.name === unit.name ? 'active' : ''
                            }`}
                            style={{
                              borderRadius:
                                index === units.length - 1
                                  ? '0px 4px 4px 0px'
                                  : '0px',
                            }}
                            onClick={() => setUnit(u)}
                          >
                            <input
                              type='radio'
                              name='options'
                              id={index.toString()}
                              autoComplete='off'
                              checked={u.name === unit.name}
                            />
                            {u.name}
                          </label>
                        );
                      })}
                    </div>
                  )}
                  {units && units.length === 1 && (
                    <span
                      className='input-group-text'
                      style={{ fontSize: UNIT, margin: 'auto' }}
                    >
                      {units[0].name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
        <div className='col-12'>
          {stockRemaining > 0 && stockRemaining <= 5 && !loading && (
            <p className='text-warning pt-4 font-italic label'>
              - Plus que {stockRemaining}{' '}
              <span className='no-localization'>{unit ? unit.name : ''}</span>{' '}
              {stockName} en stock -
            </p>
          )}
          {stockRemaining <= 0 && !loading && (
            <p className='text-danger pt-4 font-italic mb-0 label'>
              - Plus de {stockName} en stock -
            </p>
          )}
        </div>
        {categoriesLength > 1 && stepOptionIndex + 1 === categoriesLength && (
          <AddToCartButton
            items={items}
            shopCategories={shopCategories}
            price={price}
            nb={nb}
            unit={unit}
            product={product}
            setLoading={setLoading}
            mandatoryCategories={mandatoryCategories}
            productCategories={productCategories}
            productKey={productKey}
            instruction={instruction}
            sectionName={sectionName}
            sectionKey={sectionKey}
            resetComponent={resetComponent}
            stepOptionIndex={stepOptionIndex}
            loading={loading}
          />
        )}
        {!isChatbot() && (
          <div className='row col'>
            {!disableProductInstruction && (
              <SpecialInstrutions
                productCategories={productCategories}
                instruction={instruction}
                setInstruction={setInstruction}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
