import React from 'react';
import { styled } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { COLOR_GREY, DARK_COLOR } from '../../constants';
import { isDarkTheme } from '../../utils';
import { getOrderSteps } from './OrderSteps.services';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hook';
import OrderStepIcon from './component/OrderStepIcon';

type OrderStepsProps = {
  order: any;
  activeStep: number;
};

export default function OrderSteps({ order, activeStep }: OrderStepsProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { orderType } = order;
  const steps = getOrderSteps(orderType, order?.orderFlowType);

  const ConnectorStyled = styled(StepConnector)(() => ({
    '& .MuiStepper-root': {
      padding: '0px',
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
      padding: '0px',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: secondaryColor,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: secondaryColor,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR_GREY,
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <div>
      <Stepper
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: isDarkTheme() && DARK_COLOR,
        }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ConnectorStyled />}
      >
        {_.map(steps, (label, index) => (
          <Step
            key={label}
            className='px-0'
          >
            <StepLabel StepIconComponent={OrderStepIcon}>
              <span
                style={{
                  color: index <= activeStep ? secondaryColor : COLOR_GREY,
                }}
                className={`${
                  index <= activeStep ? 'label ' : 'disabled-label'
                }`}
              >
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
