import React, { useState } from 'react';
import {
  getAddressFormatted,
  getDateFormat,
  getEstimationTime,
  getShopFromOrder,
  getStatus,
  isDarkTheme,
} from '../../../utils';
import {
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_DELIVERY,
  UNIT,
} from '../../../constants';
import { useAppSelector } from '../../../redux/hook';
import OrderTotal from './OrderTotal';
import _ from 'lodash';
import OrderItem from '../../OrderItem';

type OrderDetailProps = {
  order: any;
  showFees: boolean;
  deleteOption: boolean;
  totalProductPrice: number;
};

export default function OrderDetail({
  order,
  showFees,
  deleteOption,
  totalProductPrice,
}: OrderDetailProps) {
  const [border, setBorder] = useState('transparent');
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { orderNumber, date, commande, status, orderType } = order;
  const shop = getShopFromOrder(order);
  const { name, address } = shop;
  const pastOrder = status === 'completed';
  let orderTypeLabel = '';
  if (orderType) {
    if (orderType === ORDER_TYPE_DELIVERY) {
      orderTypeLabel = 'En livraison';
    } else if (orderType === ORDER_TYPE_CLICK_AND_SEAT) {
      orderTypeLabel = 'Sur place';
    } else {
      orderTypeLabel = `À emporter`;
    }
  }

  const toggleHover = () => {
    setBorder(secondaryColor);
  };

  const toggleLeave = () => {
    setBorder('transparent');
  };

  const renderItems = (commande) => {
    return _.keys(commande).map((key) => {
      const item = commande[key];
      return (
        <OrderItem
          cartItem={item}
          key={key}
          cartItemKey={key}
          deleteOption={deleteOption}
        />
      );
    });
  };

  return (
    <div
      className='card mb-3 shadow'
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleLeave()}
      style={{
        cursor: pastOrder ? 'unset' : 'pointer',
        border: pastOrder && !isDarkTheme() ? 'none' : `2px solid ${border}`,
        borderRadius: UNIT * 2.1875,
      }}
    >
      <div
        className='card-body dark-modal'
        style={{ borderRadius: UNIT * 2 }}
      >
        <h5
          className='card-title label'
          style={{ color: secondaryColor }}
        >
          Commande n°
          <span className='no-localization label'>{orderNumber}</span>
        </h5>
        <h6 className='card-subtitle mb-2 text-muted no-localization label'>
          {getDateFormat(date)} - {orderTypeLabel}
        </h6>
        <h6 className='card-subtitle mb-2 text-muted no-localization label'>
          {orderType === ORDER_TYPE_DELIVERY
            ? `Adresse : ${getAddressFormatted(order.address)}`
            : `${name} : ${getAddressFormatted(address)}`}
        </h6>
        <hr className='my-4 hr' />
        <div className='container-block'>
          {pastOrder && renderItems(commande)}
          <OrderTotal
            order={order}
            totalProductPrice={totalProductPrice}
            showFees={showFees}
          />
          {!pastOrder && (
            <>
              <hr className='my-4 hr' />
              <b className='text-left'>{getStatus(order)}</b>
              {status !== ORDER_PHASE_TAKEAWAY &&
                status !== ORDER_PHASE_DELIVERING && (
                  <div className='text-left'>{`Commande prévue pour le ${getEstimationTime(
                    order
                  )}`}</div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
