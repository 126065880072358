import React from 'react';
import { useAppSelector } from '../../../redux/hook';
import { makeStyles } from '@mui/styles';
import {
  COLOR_GREY,
  ORDER_FLOW_TYPE,
  ORDER_TYPE_DELIVERY,
  UNIT,
} from '../../../constants';
import clsx from 'clsx';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ForumIcon from '@mui/icons-material/Forum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { DirectionsBike, Store } from '@mui/icons-material';

type OrderStepIconProps = {
  order: any;
  active: boolean;
  completed: boolean;
  orderType: any;
  icon: any;
};

export default function OrderStepIcon({
  order,
  active,
  completed,
  orderType,
  icon,
}: OrderStepIconProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const StepIconStyle = makeStyles({
    root: {
      color: COLOR_GREY,
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: secondaryColor,
    },
    completed: {
      color: secondaryColor,
      zIndex: 1,
      fontSize: UNIT * 1.125,
    },
  });

  const classes = StepIconStyle();
  const reverseFlowIcons = {
    1: <SearchIcon />,
    2: <ForumIcon />,
    3: <RestaurantIcon />,
    4: <DirectionsBike />,
  };
  let icons =
    order?.orderFlowType == ORDER_FLOW_TYPE.REVERSE
      ? reverseFlowIcons
      : {
          1: <ForumIcon />,
          2: <RestaurantIcon />,
          3: <DirectionsBike />,
          4: <Check />,
        };

  if (orderType !== ORDER_TYPE_DELIVERY) {
    icons = {
      1: <ForumIcon />,
      2: <RestaurantIcon />,
      3: <Store />,
      4: <Check />,
    };
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}
