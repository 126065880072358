import moment from 'moment';
import _ from 'lodash';
import apiService from '@dishopsaas/dishop-backend-api-service';
import {
  calculateFullPrice,
  calculateProductPrice,
  checkClosedShop,
  checkIfOrderIsInActivationRange,
  checkOrdersLimit,
  displayPriceFormat,
  getClosedMessage,
  getCurrentShopSlot,
  getWebViewCustomerId,
  getWebViewPageId,
  isQrCode,
  isSameDay,
  isStringNotNull,
  sendCloudWatchAlert,
  sendCloudWatchLogs,
  sendOrderClick,
  showMessageModalWithLoading,
  sortSlots,
} from '../../utils';
import {
  API_SEND_ORDER_MESSENGER,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_DELIVERY,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_CB,
  PAYMENT_TYPE_COUNTER,
  PAYMENT_TYPE_STRIPE,
  PAYMENT_TYPE_TICKET_RESTO,
  STRIPE_MINIMUM_AMOUNT,
} from '../../constants';
import {
  showCartModal,
  showHoursModal,
  showMessageModal,
  updateOrderTime,
} from '../../redux/actions';
import store from '../../redux/store';
import { closeWebView, sendPostRequest } from '../../api';
import Horaires from '../Cover/Horaires';

export const getMaximumPreparationTime = (commande) => {
  let maxPreparationTime = 0;
  _.map(commande, (product) => {
    const { preparationTime } = product;
    if (maxPreparationTime < parseInt(preparationTime, 10)) {
      maxPreparationTime = parseInt(preparationTime, 10);
    }
  });
  return maxPreparationTime;
};

export const getLimit = (limits, slots, slot) => {
  if (limits) {
    const indexSlot = _.findIndex(slots, (s) => s === slot);
    return limits[indexSlot];
  }
};

export const calculateStartTime = (now, slotInterval) => {
  let date = moment(now);
  const nowMinutes = parseInt(moment().format('mm'), 10);
  const nbSlot = parseInt((nowMinutes / slotInterval).toString(), 10) + 1;
  const minutesToAdd = nbSlot * slotInterval - nowMinutes;
  date = date.add(minutesToAdd, 'm');
  return date;
};

export const getSlotsAndLimits = (day, orderType) => {
  const { openHours, specialHours } = store.getState().shopReducer;
  const hasSpecialHours = _.find(specialHours, (hours) =>
    isSameDay(hours.day, day)
  );
  const anyDayHasDeliverySlot = _.some(
    openHours,
    (hours) =>
      hours?.slotsDelivery?.length > 0 && orderType === ORDER_TYPE_DELIVERY
  );
  const targetDay = openHours[day.format('dddd')];
  const limits = anyDayHasDeliverySlot
    ? targetDay?.limitsDelivery
    : targetDay?.limits;
  if (hasSpecialHours) {
    const slots = hasSpecialHours.slots;
    return { slots, limits };
  }
  const slots = anyDayHasDeliverySlot
    ? targetDay?.slotsDelivery
    : targetDay?.slots;
  return { slots, limits };
};

export const getOrderTimes = (day, orderType, maximumPreparationTime) => {
  const orderTimes = [];
  const { configHours } = store.getState().configurationReducer;
  const now = maximumPreparationTime
    ? moment().add(maximumPreparationTime, 'h')
    : moment();
  const slotsAndLimits = getSlotsAndLimits(day, orderType);
  if (slotsAndLimits?.slots) {
    const sortedSlots = sortSlots(slotsAndLimits.slots, day);
    const slotInterval = _.get(configHours, 'slotInterval', 30);

    _.forEach(sortedSlots, (slot) => {
      const [beginHours, endHours] = _.map(slot.split('-'), (time) =>
        moment(`${day.format('YYYY-MM-DD')}T${time}:00`)
      );
      if (slot.endsWith('00:00')) {
        endHours.add(1, 'd');
      }
      const date =
        now > beginHours
          ? calculateStartTime(now, slotInterval)
          : moment(beginHours);
      while (date < endHours) {
        const datePlus30Minutes = date.clone().add(slotInterval, 'm');
        const hasLimit = checkOrdersLimit(
          getLimit(slotsAndLimits.limits, slotsAndLimits.slots, slot),
          date,
          datePlus30Minutes
        );
        const momentPlusSlotMinutes = moment().add(slotInterval, 'm');
        const currentSlot =
          momentPlusSlotMinutes.isSameOrAfter(date) &&
          momentPlusSlotMinutes.isSameOrBefore(datePlus30Minutes);
        const dateFormatted = `${date.format(
          'HH:mm'
        )} - ${datePlus30Minutes.format('HH:mm')}`;
        orderTimes.push({
          value: dateFormatted,
          data: date.clone(),
          currentSlot,
          disabled: hasLimit,
        });
        date.add(slotInterval, 'm');
      }
    });
  }
  return orderTimes;
};

export const getFirstDay = (
  maximumPreparationTime,
  setDay,
  setHourSelectValue,
  setSelectDay,
  setSelectTime,
  setIsValidDate
) => {
  const { orderType } = store.getState().pendingOrderReducer;
  let now = moment();
  if (maximumPreparationTime) {
    now = moment().add(maximumPreparationTime, 'h');
  }
  let orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
  let dayToAdd = 1;
  store.dispatch(updateOrderTime(orderTimes[0]));
  while (
    (orderTimes.length === 0 ||
      !_.find(orderTimes, (orderTime) => !orderTime.disabled)) &&
    dayToAdd < 7
  ) {
    now = moment().add(dayToAdd, 'd');
    orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
    dayToAdd++;
  }
  if (orderTimes.length > 0) {
    setDay(now);
    setHourSelectValue(0);
    setSelectDay(false);
    setSelectTime(false);
    setIsValidDate(true);
  }
};

export const getExcludedOrderDays = (
  date,
  maximumPreparationTime,
  orderType
) => {
  const now = moment();
  const newDate = moment(date);
  let diffDays = now.diff(newDate, 'days');
  if (diffDays > 0) {
    return false;
  }
  if (maximumPreparationTime) {
    const nowPlusPreparationTime = moment().add(maximumPreparationTime, 'h');
    diffDays = nowPlusPreparationTime.diff(newDate, 'days');
    if (diffDays > 0) {
      return false;
    }
  }
  const orderTimes = getOrderTimes(newDate, orderType, maximumPreparationTime);
  if (
    orderTimes.length === 0 ||
    !_.find(orderTimes, (orderTime) => !orderTime.disabled)
  ) {
    return false;
  }
  const { openHours, specialHours } = store.getState().shopReducer;
  // Check if shop exceptionnaly close for a specific date
  const isTodayExceptionnallyClose = _.find(specialHours, (hours) => {
    const { day, slots } = hours;
    return isSameDay(day, date) && !slots;
  });
  if (isTodayExceptionnallyClose) {
    return false;
  }
  // Check if shop open for a specific date
  const openHour = _.find(openHours, (openHour, day) => {
    return (
      newDate.format('dddd') === day &&
      (openHour.slots || openHour.slotsDelivery)
    );
  });
  if (openHour) {
    return true;
  }
  return false;
};

export const sendOrderMessenger = async (setLoading) => {
  try {
    setLoading(true);
    const link = await apiService.configurationGetOne(['chatbot', 'link']); // TODO do we really need another DB call, can we get config from the store? also chatbot and link are deprecated ?
    const customerId = getWebViewCustomerId();
    const pageId = getWebViewPageId();
    const data = {
      customerId,
      pageId,
    };
    await sendPostRequest(API_SEND_ORDER_MESSENGER, data, {});
    closeWebView();
    setTimeout(() => {
      setLoading(false);
      window.location.href = link;
      store.dispatch(showCartModal(false));
    }, 5000);
  } catch (error) {
    setLoading(false);
    store.dispatch(
      showMessageModal(
        'Nous n\'avons pas pu envoyer votre commande. Veuillez réessayer.'
      )
    );
    sendCloudWatchAlert(`Could not send order messenger ${error}`);
  }
};

const getCorrectMessage = (allAvailableDates) => {
  return (
    <>
      Votre panier contient des produits qui ne sont pas disponibles pour la
      date choisie.
      <p style={{ marginTop: 20, fontWeight: 'bold' }}>
        Prochaines disponibilités cette semaine
      </p>
      <Horaires availableDates={allAvailableDates} />
    </>
  );
};

export const checkOrder = async ({
  paymentType,
  card,
  commande,
  selectTime,
  setIsValidDate,
  setLoading,
  isPhoneIncorrect,
  setChoosePayment,
  sections,
  day,
  stripe,
  phoneObj,
}) => {
  const closedShop = checkClosedShop(setLoading);
  const totalPriceOrder = calculateFullPrice();
  if (!commande || _.isEmpty(commande)) {
    store.dispatch(showCartModal(false));
    showMessageModalWithLoading(
      setLoading,
      `Votre panier est vide. Choisissez un produit pour pouvoir commander.`
    );
  } else if (!closedShop) {
    const { user } = store.getState().userReducer;
    const { numero } = user;
    const { minimum, openHours, minimumClickAndCollect, shopName } =
      store.getState().shopReducer;
    const { orderTime } = store.getState().orderReducer;
    const { orderType } = store.getState().pendingOrderReducer;
    const totalProductPriceOrder = calculateProductPrice();
    if (
      !orderTime ||
      (orderTime?.value && !selectTime && !orderTime?.currentSlot)
    ) {
      setIsValidDate(false);
      return;
    }
    setLoading(true);
    if ((isPhoneIncorrect || !isStringNotNull(numero)) && !isQrCode()) {
      setLoading(false);
    } else if (
      orderType === ORDER_TYPE_DELIVERY &&
      totalProductPriceOrder < minimum
    ) {
      showMessageModalWithLoading(
        setLoading,
        `Votre panier doit être supérieur à ${displayPriceFormat(
          minimum,
          true
        )} pour pouvoir passer une commande.`
      );
    } else if (
      orderType === ORDER_TYPE_CLICK_AND_COLLECT &&
      totalProductPriceOrder < minimumClickAndCollect
    ) {
      showMessageModalWithLoading(
        setLoading,
        `Votre panier doit être supérieur à ${displayPriceFormat(
          minimumClickAndCollect,
          true
        )} pour pouvoir passer une commande.`
      );
    } else if (
      paymentType === PAYMENT_TYPE_STRIPE &&
      totalPriceOrder < STRIPE_MINIMUM_AMOUNT &&
      totalPriceOrder > 0
    ) {
      showMessageModalWithLoading(
        setLoading,
        `Votre panier doit être supérieur à ${displayPriceFormat(
          STRIPE_MINIMUM_AMOUNT,
          true
        )} pour pouvoir utiliser le paiement en ligne.`
      );
    } else if (openHours) {
      const { slot, isSpecialHours } = getCurrentShopSlot(
        orderTime.data,
        orderType
      );
      if (!slot) {
        if (!isSpecialHours) {
          sendCloudWatchLogs('Le service est fermé.');
          setLoading(false);
          const hoursModalData = {
            show: true,
            hours: openHours,
          };
          store.dispatch(showHoursModal(hoursModalData));
        } else {
          showMessageModalWithLoading(
            setLoading,
            getClosedMessage(
              `${shopName} est exceptionnellement fermé sur ce créneau horaire.`
            )
          );
          sendCloudWatchLogs('Shop exceptionally closed');
        }
      } else {
        const allAvailableDates = checkIfOrderIsInActivationRange({
          sections,
          commande,
          day,
        });
        if (allAvailableDates?.length > 0) {
          showMessageModalWithLoading(
            setLoading,
            getCorrectMessage(allAvailableDates)
          );
        } else
          sendOrderClick({
            paymentType,
            card,
            stripe,
            setLoading,
            phoneObj,
            setChoosePayment,
          });
      }
    } else {
      sendOrderClick({
        paymentType,
        card,
        stripe,
        setLoading,
        phoneObj,
      });
      setChoosePayment(true);
    }
  }
};

export const getPaymentTypeLabel = (paymentType) => {
  let paymentTypeLabel = '';
  switch (paymentType) {
    case PAYMENT_TYPE_STRIPE:
      paymentTypeLabel = 'Payer en ligne';
      break;
    case PAYMENT_TYPE_CASH:
      paymentTypeLabel = 'Payer en espèces (sur place)';

      break;
    case PAYMENT_TYPE_CB:
      paymentTypeLabel = 'CB (sur place)';
      break;
    case PAYMENT_TYPE_TICKET_RESTO:
      paymentTypeLabel = 'Payer en ticket restaurant (sur place)';
      break;
    case PAYMENT_TYPE_COUNTER:
      paymentTypeLabel = 'Payer sur place';
      break;
    default:
      break;
  }
  return paymentTypeLabel;
};

export const getPaymentTypes = () => {
  let { paymentTypes } = store.getState().configurationReducer;
  const { qrcode, delivery } = store.getState().configurationReducer;
  const { shopConfiguration = {} } = store.getState().shopReducer;
  const { orderType, address: customerAddress } =
    store.getState().pendingOrderReducer;
  const { paymentTypes: deliveryPaymentTypes } = delivery;
  if (isQrCode() && qrcode) {
    paymentTypes = qrcode.paymentTypes;
  } else {
    if (shopConfiguration.paymentTypes) {
      paymentTypes = shopConfiguration.paymentTypes;
    }
    if (customerAddress && orderType === ORDER_TYPE_DELIVERY) {
      if (
        shopConfiguration.delivery &&
        shopConfiguration.delivery.paymentTypes
      ) {
        paymentTypes = shopConfiguration.delivery.paymentTypes;
      } else if (deliveryPaymentTypes) {
        paymentTypes = deliveryPaymentTypes;
      }
    }
  }
  return paymentTypes;
};
