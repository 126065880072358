import React from 'react';
import { useAppSelector } from '../../../redux/hook';

export default function CGU() {
  const { secondaryColor, cgu } = useAppSelector(
    (state) => state.configurationReducer
  );
  return (
    <>
      En cliquant sur Inscription, vous acceptez nos{' '}
      <a
        href={cgu}
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: secondaryColor }}
      >
        Conditions générales d'utilisation
      </a>
    </>
  );
}
