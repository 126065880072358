import { sendCloudWatchAlert } from '../../utils';
import { formatPhone } from '../../utils/customer';
import { deleteCustomerAccountBackend, updateUser } from '../../api/backend';
import { updateCustomerHubrise } from '../../api/hubrise';
import store from '../../redux/store';
import {
  listenFirebaseUsersData,
  unlistenFirebase,
} from '../../api/firebase/firebaseOn';

export const deleteUserAccount = async ({
  showUpdateInfo,
  showAccountModal,
  showMessageModal,
  userSignOut,
  customerId,
  isUserAnonymous,
  updateUser,
  dispatch
}) => {
  try {
    unlistenFirebase(customerId);
    await deleteCustomerAccountBackend();
    dispatch(userSignOut());
    dispatch(showUpdateInfo(false));
    dispatch(showAccountModal(false));
    dispatch(showMessageModal(`Nous vous confirmons la suppression de votre compte.`));
  } catch (error) {
    dispatch(showMessageModal(
      `Nous n'avons pas pu supprimer votre compte, veuillez essayer de nouveau.`
    ));
    sendCloudWatchAlert(`Error deleting user account ${error}`);
    await listenFirebaseUsersData(customerId, isUserAnonymous, updateUser);
  }
};

const updateUserPersonalInfos = async (
  firstName = '',
  lastName = '',
  numero = '',
  phone = {}
) => {
  const { user, userConnected } = store.getState().userReducer;
  if (userConnected) {
    const { customerId } = user;
    await updateUser(customerId, { firstName, lastName, numero, phone });
  }
};

export const updateAccount = async ({
  setLoading,
  phoneObj,
  updateProfile,
  showMessageModal,
  showUpdateInfo,
  user,
  firstName,
  lastName,
  numero,
  dispatch
}) => {
  try {
    setLoading(true);
    numero = formatPhone(numero);
    dispatch(updateProfile('fistName', firstName));
    dispatch(updateProfile('lastName', lastName));
    dispatch(updateProfile('numero', numero));
    await updateCustomerHubrise(
      { firstName, lastName, numero },
      user?.customerId
    );
    await updateUserPersonalInfos(firstName, lastName, numero, phoneObj);
    dispatch(showUpdateInfo(false));
    setLoading(false);
    dispatch(showMessageModal('Votre compte a bien été mis à jour'));
  } catch (error) {
    const errorMessage = error;
    sendCloudWatchAlert(`Could not update user info ${errorMessage}`);
    setLoading(false);
    dispatch(showMessageModal(
      'La modification de votre compte a échoué. Veuillez vérifier les informations saisies.'
    ));
  }
};

export const validateForm = (formValues:any) => {
  const errors = {};
  if (!formValues.firstName) {
    errors['firstName'] = 'Merci de fournir un prénom';
  }

  if (!formValues.lastName) {
    errors['lastName'] = 'Merci de fournir un nom de famille';
  }

  return errors;
};
