import React from 'react'
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import styles from './Navbar.styles'
import FlagsSelect from '../FlagsSelect'
import { isStringNotNull } from '../../utils'
import { showAccountModal, showAddressModal, showConnexionModal, showOrderModal } from '../../redux/actions'
import { DARK_GRAY, WHITE } from '../../constants/configuration'

const INITIAL_STATE = {
  styleId: 'navBar',
  orderIcon: 'transparent',
  accountIcon: 'transparent',
  locationIcon: 'transparent'
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const lastScrollY = window.scrollY;
    if (lastScrollY >= 100) {
      this.setState({ styleId: 'navBar2' });
    } else {
      this.setState({ styleId: 'navBar' });
    }
  };

  toggleHover = iconId => {
    const { secondaryColor } = this.props;
    this.setState({
      [iconId]: secondaryColor
    });
  };

  toggleLeave = iconId => {
    this.setState({ [iconId]: 'transparent' });
  };

  render() {
    const { styleId } = this.state;
    const {
      logoUrl,
      name,
      webapp,
      landing,
      localize,
    } = this.props;
    const { navbar } = webapp;
    const landingEnabled = landing && landing.enabled;
    const navbarEnabled = webapp && webapp.navbar && webapp.navbar.enabled;
    const localizeEnabled = localize && localize.enabled;
    return (
      <nav
        className={`navbar navbar-dark navbar-expand-sm px-2 px-sm-5`}
        style={styles(DARK_GRAY)[styleId]}
        id='top-bar'
      >

        {landingEnabled && (
          <>
            <div data-toggle='collapse' data-target='.navbar-collapse.show'>
              <Link className='navbar-brand' to='/'>
                {isStringNotNull(logoUrl) && (
                  <img
                    src={logoUrl}
                    width='30'
                    height='30'
                    className='d-inline-block align-top'
                    alt=''
                  />
                )}
                <span className='m-2'>{name}</span>
              </Link>
            </div>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarNav'
              aria-controls='navbarNav'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
              <ul className='navbar-nav'>
                <li className='nav-item' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  <Link className='nav-link' to='/' style={{ color: WHITE }}>
                    Accueil
                  </Link>
                </li>
                <li className='nav-item' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  <Link className='nav-link' to='/commander' style={{ color: WHITE }}>
                    Commander
                  </Link>
                </li>
              </ul>
              {landingEnabled && localizeEnabled && (
                <>
                  <div className='ml-auto d-none d-sm-block nav-link'>
                    <FlagsSelect
                      className='mx-0 mx-sm-1'
                      showSelectedLabel={false}
                      alignOptions='left'
                    />
                  </div>
                  <div className='d-block d-sm-none nav-link px-0'>
                    <FlagsSelect className='mx-0 mx-sm-1' showSelectedLabel alignOptions='right' />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {!landingEnabled && navbarEnabled && (
          <a className='navbar-brand' href={navbar.homeUrl}>
            {isStringNotNull(logoUrl) && (
              <img
                src={logoUrl}
                width='30'
                height='30'
                className='d-inline-block align-top'
                alt=''
              />
            )}
            <span className='m-2'>{name}</span>
          </a>
        )}
      </nav>
    );
  }
}

const mapStateToProps = ({
  configurationReducer,
  shopReducer,
  userReducer,
  orderReducer,
  pendingOrderReducer,
  componentReducer
}) => {
  const {
    secondaryColor,
    webapp,
    logoUrl,
    name,
    landing,
    localize,
  } = configurationReducer;
  const { support } = shopReducer;
  const { userConnected, isUserAnonymous } = userReducer;
  const { address: customerAddress } = pendingOrderReducer;
  const { orders } = orderReducer;
  const { addressTemp } = componentReducer;
  return {
    support,
    secondaryColor,
    webapp,
    logoUrl,
    name,
    landing,
    localize,
    userConnected,
    isUserAnonymous,
    customerAddress,
    orders,
    addressTemp
  };
};

export default connect(mapStateToProps, {
  showAccountModal,
  showConnexionModal,
  showAddressModal,
  showOrderModal
})(Navbar);
