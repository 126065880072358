import React from 'react';
import Button from '../../../../../../Button';
import { isDarkTheme } from '../../../../../../../utils';
import {
  BLACK,
  COLOR_LIGHT_GRAY,
  DARK_COLOR,
  UNIT,
  WHITE,
} from '../../../../../../../constants';
import { isNextCategoryDisabled } from '../ProductModal.services';
import OptionScrollButton from './OptionScrollButton';
import { useAppSelector } from '../../../../../../../redux/hook';

type CategoryStepsProps = {
  productCategories: any;
  stepOptionIndex: number;
  setStepOptionIndex: React.Dispatch<React.SetStateAction<number>>;
  setShowOptionScrolButton: React.Dispatch<React.SetStateAction<boolean>>;
  items: any;
  mandatoryCategories: any;
  shopCategories: any;
  showOptionScrollButton: boolean;
};

export default function CategorySteps({
  productCategories,
  stepOptionIndex,
  setStepOptionIndex,
  setShowOptionScrolButton,
  items,
  mandatoryCategories,
  shopCategories,
  showOptionScrollButton,
}: CategoryStepsProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  const categoriesLength = productCategories.length + 1;

  return (
    <div className='col-12 d-flex justify-content-center'>
      <Button
        className='no-outline py-0'
        disabled={stepOptionIndex === 0}
        onClick={() => {
          setStepOptionIndex(stepOptionIndex - 1);
          setShowOptionScrolButton(false);
        }}
      >
        <a
          href='#optionContainer'
          style={{}}
        >
          <i
            className={`fas fa-chevron-left mx-2 fa-2x`}
            style={{
              color:
                stepOptionIndex === 0
                  ? isDarkTheme()
                    ? DARK_COLOR
                    : WHITE
                  : isDarkTheme()
                  ? WHITE
                  : BLACK,
            }}
          />
        </a>
      </Button>
      <p
        className='label'
        style={{
          margin: '0px',
          fontSize: UNIT * 1.375,
        }}
      >
        {`${stepOptionIndex + 1} / ${categoriesLength}`}
      </p>
      <Button
        className='no-outline py-0'
        disabled={isNextCategoryDisabled({
          items,
          mandatoryCategories,
          stepOptionIndex,
          productCategories,
          shopCategories,
        })}
        onClick={() => {
          setStepOptionIndex(stepOptionIndex + 1);
          setShowOptionScrolButton(false);
        }}
      >
        <a
          href='#optionContainer'
          style={{
            pointerEvents: isNextCategoryDisabled({
              items,
              mandatoryCategories,
              stepOptionIndex,
              productCategories,
              shopCategories,
            })
              ? 'none'
              : 'auto',
          }}
        >
          <i
            className={`fas fa-chevron-right mx-2 fa-2x`}
            style={{
              color: isNextCategoryDisabled({
                items,
                mandatoryCategories,
                stepOptionIndex,
                productCategories,
                shopCategories,
              })
                ? isNextCategoryDisabled({
                    items,
                    mandatoryCategories,
                    stepOptionIndex,
                    productCategories,
                    shopCategories,
                  }) === 'lastItem'
                  ? isDarkTheme()
                    ? DARK_COLOR
                    : WHITE
                  : COLOR_LIGHT_GRAY
                : secondaryColor,
            }}
          />
        </a>
      </Button>
      {showOptionScrollButton && (
        <OptionScrollButton
          setShowOptionScrolButton={setShowOptionScrolButton}
        />
      )}
    </div>
  );
}
