import React from 'react';
import TextInputCounter from '../../../../../../../../TextInputCounter';
import { UNIT } from '../../../../../../../../../constants';
import { isDarkTheme } from '../../../../../../../../../utils';

type SpecialInstructionsProps = {
  productCategories: any;
  instruction: string;
  setInstruction: React.Dispatch<React.SetStateAction<string>>;
};

export default function SpecialInstrutions({
  productCategories,
  instruction,
  setInstruction,
}: SpecialInstructionsProps) {
  return (
    <div className='col-12 pt-4 dark-modal'>
      <div
        className={`pb-2 dark-modal ${
          productCategories.length === 0 ? 'bg-white' : 'bg-light'
        }`}
        style={{ fontSize: UNIT * 0.875 }}
      >
        Instructions spéciales :
      </div>
      <div className='form-group mb-0'>
        <TextInputCounter
          className='form-control text-center font-italic'
          placeholder='Entrez vos instructions'
          style={{
            margin: 'auto',
            width: '90%',
            resize: 'none',
            fontSize: UNIT * 0.625,
            borderRadius: UNIT / 2,
            backgroundColor: isDarkTheme() && '#202020',
          }}
          counterStyle={{
            position: 'absolute',
            right: '25px',
            bottom: '15px',
            fontSize: UNIT * 0.75,
          }}
          value={instruction}
          onChange={(e) => {
            setInstruction(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
