/* eslint-disable camelcase */
import _ from 'lodash';
import moment from 'moment';
import apiService from '@dishopsaas/dishop-backend-api-service';
import { formatPhone, getPromotion } from './customer';
import {
  getFirebaseCustomerId,
  getIdToken,
} from '../api/firebase/firebaseAuthentication';
import store from '../redux/store';
import { sendCloudWatchAlert, sendCloudWatchLogs } from './logs';
import {
  API_SEND_ORDER,
  ORDER_FLOW_TYPE,
  ORDER_PHASE,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_CONTACTING_TIME,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_DELIVERING_TIME,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_PREPARING_TIME,
  ORDER_PHASE_TAKEAWAY,
  ORDER_PHASE_WAITER,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_CLICK_AND_SEAT_LABEL,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_DELIVERY_LABEL,
  PAYMENT_TYPE_STRIPE,
  PICKUP_STATUS,
  PROMOTION_DELIVERY_FREE,
  PROMOTION_PERCENTAGE,
} from '../constants';
import {
  getCustomerTableNb,
  getRestFutureDays,
  getTimezone,
  isObjectNotNull,
  isStringNotNull,
  renderErrorMessage,
} from '.';
import { isQrCode } from './config';
import {
  showCartModal,
  showMessageModal,
  showOrderModal,
  showOrderStatus,
} from '../redux/actions';
import {
  addCardToExistingCustomer,
  confirmPaymentIntent,
  createCustomer,
  createPayment,
  deleteCustomerPaymentCardBackend,
  retrieveCard,
  sendGoogleAnalytics,
  sendPostRequest,
  updateCustomerHubrise,
  updateUser,
} from '../api';
import { getManagementFee } from './product';
import { getClosedMessage } from './shop';

export const roundNumber = (number) => {
  return Math.round(number * 100) / 100;
};

export const getNonStripeChargeId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const calculateProductPrice = () => {
  const { commande } = store.getState().pendingOrderReducer;
  let productPrice = 0;
  if (!_.isEmpty(commande)) {
    const prices = _.keys(commande).map((key) => {
      const cartItem = commande[key];
      return cartItem.price;
    });

    for (let i = 0; i < prices.length; i += 1) {
      productPrice += prices[i];
    }
  }
  return roundNumber(productPrice);
};

export const getUserServicePrice = () => {
  try {
    const { orderType, address: customerAddress } =
      store.getState().pendingOrderReducer;
    const { userServicePriceInfo = {} } = store.getState().shopReducer;
    const { userServicePrice, userCancelServicePrice } = userServicePriceInfo;
    let managementFee = 0;
    if (userServicePriceInfo?.managementFee > 0) {
      managementFee = userServicePriceInfo?.managementFee;
    }
    let servicePrice = 0;
    let nextServicePrice = 0;
    let nextProductPrice = 0;
    if (orderType === ORDER_TYPE_DELIVERY) {
      if (customerAddress.deliveryPrice) {
        servicePrice = customerAddress.deliveryPrice;
      } else if (userServicePrice) {
        const totalProductPriceOrder = calculateProductPrice();
        _.map(userServicePrice, (val) => {
          if (val) {
            if (
              totalProductPriceOrder >= val.priceOrder &&
              (!val.nextPriceOrder ||
                totalProductPriceOrder < val.nextPriceOrder)
            ) {
              servicePrice = val.fees;
              if (val.nextPriceOrder) {
                nextProductPrice = val.nextPriceOrder;
                nextServicePrice = val.nextFees;
              }
            }
          }
        });
      }
    }
    return {
      servicePrice,
      nextProductPrice,
      nextServicePrice,
      managementFee,
      userCancelServicePrice,
    };
  } catch (error) {
    sendCloudWatchAlert(`Could not get user service price ${error}`);
  }
};

export const calculateFullPrice = () => {
  const { servicePrice } = getUserServicePrice();
  let productPrice = calculateProductPrice();
  const managementFee =
    getManagementFee(productPrice) || getUserServicePrice()?.managementFee;
  let totalPriceOrder = servicePrice + managementFee + productPrice;
  const promotion = getPromotion(productPrice);
  if (promotion && !promotion.minBasketPrice) {
    if (promotion.type === PROMOTION_DELIVERY_FREE) {
      totalPriceOrder -= servicePrice;
    } else if (
      typeof promotion.type === 'string' &&
      promotion.type.includes(PROMOTION_PERCENTAGE)
    ) {
      const percentageReduction = promotion.type.match(/\d+/)[0];
      totalPriceOrder -= Math.round(productPrice * percentageReduction) / 100;
    } else if (typeof promotion.type === 'number') {
      const percentageReduction = promotion.type;
      productPrice =
        productPrice - percentageReduction > 0
          ? productPrice - percentageReduction
          : 0;
      totalPriceOrder = servicePrice + managementFee + productPrice;
    }
  }
  return roundNumber(totalPriceOrder);
};

export const getUserCancelServicePrice = (
  userCancelServicePrice,
  totalProductPriceOrder,
  totalPriceOrder
) => {
  let cancelServicePrice = 0;
  if (userCancelServicePrice.type === '%') {
    cancelServicePrice =
      (totalProductPriceOrder * userCancelServicePrice.value) / 100;
  } else {
    cancelServicePrice = userCancelServicePrice.value;
  }
  if (cancelServicePrice > totalPriceOrder) {
    cancelServicePrice = totalPriceOrder;
  }
  return cancelServicePrice;
};

export const checkClosedShop = (setLoading) => {
  const { closed } = store.getState().shopReducer;
  if (isStringNotNull(closed)) {
    setLoading(false);
    store.dispatch(showMessageModal(getClosedMessage(closed)));
    return true;
  }
  return false;
};
export const showMessageModalWithLoading = (setLoading, message) => {
  setLoading(false);
  store.dispatch(showMessageModal(message));
};

export const attachPaymentMethodToCustomer = async (card) => {
  const { user, userConnected } = store.getState().userReducer;
  const { id } = card;
  const customerId = getFirebaseCustomerId();
  try {
    const { stripeCustomerId, email } = user;
    const data = {
      cardId: id,
      customerId,
    };
    const cardOnStripe = await retrieveCard(data);
    if (!cardOnStripe || !cardOnStripe.customer) {
      const data = { card, customerId };
      sendCloudWatchLogs('Ading card to customer');
      if (stripeCustomerId) {
        data.stripeCustomerId = stripeCustomerId;
        await addCardToExistingCustomer(data);
      } else {
        data.email = email;
        await createCustomer(data);
      }
    }
    sendCloudWatchLogs('Successfully creating payment intent');
    return true;
  } catch (error) {
    const errorMessage = String(error);
    if (!errorMessage.includes('not be used again')) {
      sendCloudWatchAlert(
        `Could not attach payment method to customer ${error}`
      );
    } else if (userConnected) {
      deleteCustomerPaymentCardBackend(id);
    }
    throw error;
  }
};

export const sendOrderClick = async ({
  paymentType,
  card,
  stripe,
  setLoading,
  phoneObj,
  setChoosePayment,
}) => {
  try {
    sendCloudWatchLogs('Sending new order');
    const { servicePrice } = store.getState().shopReducer;
    const { orderTime } = store.getState().orderReducer;
    const { user, userConnected } = store.getState().userReducer;
    const { configHours } = store.getState().configurationReducer;
    const { customAccountId } = servicePrice;
    const {
      shopId,
      orderType,
      address: customerAddress,
    } = store.getState().pendingOrderReducer;
    const { email, stripeCustomerId, customerId, firstName, lastName } = user;
    let { numero } = user;
    const finalOrder = {};
    const orderNumber = 0;
    numero = formatPhone(numero);

    const userServicePriceInfo = getUserServicePrice();
    const totalProductPriceOrder = calculateProductPrice();
    const totalPriceOrder = calculateFullPrice();
    const userCancelServicePrice = getUserCancelServicePrice(
      userServicePriceInfo.userCancelServicePrice,
      totalProductPriceOrder,
      totalPriceOrder
    );
    const promotion = getPromotion(totalProductPriceOrder);
    finalOrder.chargeId = `cash_${getNonStripeChargeId()}`;
    finalOrder.address = customerAddress;
    finalOrder.date =
      isQrCode() || configHours.hideScheduledTime
        ? moment().format()
        : orderTime.data.format();
    finalOrder.distance = '';
    finalOrder.firstName = firstName || '';
    finalOrder.email = email;
    finalOrder.lastName = lastName || '';
    finalOrder.id = customerId;
    finalOrder.idDep = '';
    finalOrder.managementFee =
      getManagementFee(totalProductPriceOrder) ||
      userServicePriceInfo.managementFee;
    finalOrder.numero = numero;
    finalOrder.numeroDep = '';
    finalOrder.orderNumber = orderNumber;
    finalOrder.orderType = orderType;
    finalOrder.paymentType = paymentType;
    finalOrder.shopId = shopId;
    finalOrder.status = 'contacting';
    finalOrder.timezone = getTimezone();
    finalOrder.totalPriceOrder = totalPriceOrder;
    finalOrder.totalProductPriceOrder = totalProductPriceOrder;
    finalOrder.userCancelServicePrice = userCancelServicePrice;
    finalOrder.userNameDep = '';
    finalOrder.userServicePrice = userServicePriceInfo.servicePrice;
    if (isQrCode()) {
      finalOrder.canal = 'QRCODE';
    } else {
      finalOrder.canal = 'WEBAPP';
    }
    if (orderType === ORDER_TYPE_CLICK_AND_SEAT && isQrCode()) {
      finalOrder.tableNb = getCustomerTableNb();
    }

    if (promotion && !promotion.minBasketPrice) {
      finalOrder.promotion = promotion;
    }
    if (userConnected) {
      await updateCustomerHubrise(
        { address: customerAddress, numero },
        customerId
      );
      await updateUser(customerId, {
        numero: numero || '',
        phone: phoneObj,
      });
      if (orderType === ORDER_TYPE_DELIVERY) {
        const { street2, instruction } = customerAddress;
        const newAddressDetails = {
          street2: street2 || '',
          instruction: instruction || '',
        };
        await apiService.pendingOrdersUpsert(
          [customerId, 'address'],
          newAddressDetails
        );
      }
    }

    if (paymentType === PAYMENT_TYPE_STRIPE && totalPriceOrder) {
      try {
        sendCloudWatchLogs('Creating payment');
        const amount = Math.round(totalPriceOrder * 100);
        const transferGroup = moment.utc(finalOrder.date).format('X');
        const intentData = {
          amount,
          description: `${shopId} - ${email}`,
          currency: 'eur',
          customer: stripeCustomerId,
          capture_method: 'manual',
          confirmation_method: 'manual',
          payment_method: card.id,
          confirm: true,
          setup_future_usage: 'on_session',
          on_behalf_of: customAccountId,
          transfer_data: {
            destination: customAccountId,
          },
          transfer_group: transferGroup,
        };
        const data = {
          intentData,
          shopId,
          customerId,
        };
        const charge = await createPayment(data);
        const { id, status, client_secret } = charge;
        finalOrder.chargeId = id;
        if (userConnected) {
          attachPaymentMethodToCustomer(card);
        }
        if (status === 'requires_source_action') {
          sendCloudWatchLogs(`Requiring confirmation for payment ${id}`);

          const { error, paymentIntent } = await stripe.handleCardAction(
            client_secret
          );

          sendCloudWatchLogs(`Success confirmation for payment ${id}`);

          if (error) {
            sendCloudWatchAlert(JSON.stringify(error));
            throw error;
          } else if (paymentIntent.status === 'requires_confirmation') {
            sendCloudWatchLogs(`Asking for 3D SECURE ${id}`);
            await confirmPaymentIntent(paymentIntent.id, customerId);
            sendCloudWatchLogs(`Successfully verifying with 3D SECURE ${id} `);
          }
        }
      } catch (error) {
        const errorMessage = String(error);
        if (userConnected) {
          attachPaymentMethodToCustomer(card);
        }
        if (
          !errorMessage.includes('insufficient') &&
          !errorMessage.includes('declined') &&
          !errorMessage.includes('support') &&
          !errorMessage.includes('incorrect') &&
          !errorMessage.includes('A network')
        ) {
          sendCloudWatchAlert(
            `Custom authorization failed ${JSON.stringify(error)}`
          );
        }
        store.dispatch(
          showMessageModal(
            'Le paiement en ligne a échoué. Veuillez réessayer ou changer de carte bancaire.'
          )
        );
        setLoading(false);
        return;
      }
    }
    sendCloudWatchLogs(
      `Sending order to firebase ${JSON.stringify(finalOrder)}`
    );
    await sendOrder({
      setLoading,
      order: finalOrder,
      setChoosePayment,
    });
  } catch (error) {
    let errorMessage = String(error);
    if (!errorMessage.includes('ERROR_ORDERS_LIMIT')) {
      sendCloudWatchAlert(`Could not send order ${errorMessage}`);
    }
    switch (errorMessage) {
      case 'PHONE_INVALID':
        errorMessage = renderErrorMessage('stuart/phone-invalid');
        break;
      case 'RECORD_INVALID':
        errorMessage = renderErrorMessage('stuart/unavailable');
        break;
      case 'CANT_GEOCODE_ADDRESS':
        errorMessage = renderErrorMessage('stuart/address-not-served');
        break;
      case 'ERROR_ORDERS_LIMIT':
        errorMessage = renderErrorMessage('orders/limit');
        break;
      default:
        errorMessage =
          'Votre commande n\'a pas pu être envoyée. Veuillez réessayer.';
        break;
    }
    setLoading(false);
    store.dispatch(showMessageModal(errorMessage));
  }
};

export const checkIfOrderIsInActivationRange = ({
  sections,
  commande,
  day,
}) => {
  const sectionsToCheck = [];
  _.map(commande, (product) => {
    const sectionProduct = _.find(
      _.cloneDeep(sections),
      (section) => section.key === product.sectionKey
    );
    if (sectionProduct?.activationRange) sectionsToCheck.push(sectionProduct);
  });
  return isOrderInActivationRange(sectionsToCheck, day);
};

export const isOrderInActivationRange = (sections, selectedDay) => {
  if (!sections.length) return true;
  let availableDays = sections[0]?.activationRange?.daysOfWeek;
  let currentEndDate = moment(sections[0]?.activationRange?.endDate).format();
  const daysOfWeekSlots = {};
  _.map(sections, (section) => {
    const { activationRange } = section;
    const formattedEndDate = moment(activationRange?.endDate).format();
    availableDays = getAvailableDays(
      availableDays,
      activationRange.daysOfWeek,
      daysOfWeekSlots
    );
    if (moment(formattedEndDate).isSameOrBefore(moment(currentEndDate))) {
      currentEndDate = formattedEndDate;
    }
  });
  return checkAllDatesInActivationRange(
    currentEndDate,
    availableDays,
    selectedDay
  );
};

const getCommonSlots = (slots1, slots2) => {
  const result = [];

  const parseSlot = (slot) =>
    slot.split('-').map((time) => moment(time, 'HH:mm'));
  const formatSlot = (start, end) =>
    `${start.format('HH:mm')}-${end.format('HH:mm')}`;
  let i = 0,
    j = 0;
  while (i < slots1.length && j < slots2.length) {
    const [start1M, end1M] = parseSlot(slots1[i]);
    const [start2M, end2M] = parseSlot(slots2[j]);

    if (end1M.isSameOrBefore(start2M)) {
      i++;
    } else if (end2M.isSameOrBefore(start1M)) {
      j++;
    } else {
      const startMax = moment.max(start1M, start2M);
      const endMin = moment.min(end1M, end2M);
      result.push(formatSlot(startMax, endMin));

      if (end1M.isBefore(end2M)) {
        i++;
      } else {
        j++;
      }
    }
  }
  return mergeSlots(result, parseSlot, formatSlot);
};

const mergeSlots = (slots, parseSlot, formatSlot) => {
  const mergedResult = [];

  _.forEach(slots, (slot) => {
    const [start, end] = parseSlot(slot);

    if (_.isEmpty(mergedResult)) {
      mergedResult.push(slot);
    } else {
      const [lastStart, lastEnd] = parseSlot(_.last(mergedResult));
      if (lastEnd.isSame(start) || lastEnd.add(1, 'minute').isSame(start)) {
        const newEnd = moment.max(lastEnd, end);
        mergedResult[mergedResult.length - 1] = formatSlot(lastStart, newEnd);
      } else {
        mergedResult.push(slot);
      }
    }
  });
  return mergedResult;
};

const getAvailableDays = (
  availableDays,
  sectionDaysOfWeek,
  daysOfWeekSlots
) => {
  _.forEach(sectionDaysOfWeek, (section, key) => {
    if (!_.isEmpty(section.slots)) {
      daysOfWeekSlots[key] = daysOfWeekSlots[key]
        ? getCommonSlots(daysOfWeekSlots[key], section.slots)
        : section.slots;
    }
  });
  return _.pickBy(availableDays, (day, key) => {
    if (sectionDaysOfWeek[key]) {
      if (!_.isEmpty(daysOfWeekSlots?.[key])) {
        availableDays[key].slots = daysOfWeekSlots[key];
        availableDays[key].fullDay = false;
      }
      return true;
    }
    return false;
  });
};

const checkAllDatesInActivationRange = (
  endDate,
  availableDays,
  selectedDay
) => {
  const { orderTime } = store.getState().orderReducer;
  const selectedHour = orderTime.value.split('-')[0];
  const allAvailableDates = [];
  const now = moment();
  while (now.isBefore(endDate)) {
    const currentDay = now.format('dddd');
    const dayConfig = availableDays[currentDay];
    if (dayConfig) {
      const slots = dayConfig.fullDay ? ['00:00-23:59'] : dayConfig.slots;
      allAvailableDates.push({
        day: moment(now).format('DD/MM/YYYY'),
        dayOfWeek: currentDay,
        slots,
      });
    }
    now.add(1, 'days');
  }
  const dayOfWeekSelected = moment(selectedDay).format('dddd');
  let isHourValid = false;
  const isDayAvailable = _.find(allAvailableDates, (date) => {
    if (date.dayOfWeek === dayOfWeekSelected) {
      isHourValid = _.some(date.slots, (slot) => {
        const [startSlot, endSlot] = slot.split('-');
        return selectedHour >= startSlot && selectedHour <= endSlot;
      });
    }
    return date.day === moment(selectedDay).format('DD/MM/YYYY') && isHourValid;
  });

  return !isDayAvailable && getRestFutureDays(allAvailableDates);
};

export const sendOrder = async ({
  setLoading,
  order,
  setChoosePayment,
}) => {
  const { commande } = store.getState().pendingOrderReducer;
  order.commande = commande;
  const { date, promotion, userServicePrice, totalPriceOrder, managementFee } =
    order;
  const firstScheduleDate = moment().add(25, 'm');
  order.isSchedule = moment(date).isAfter(firstScheduleDate);
  let headers = {};
  const idToken = await getIdToken();
  headers = { Authorization: `Bearer ${idToken}` };

  const data = {
    order,
  };
  if (isObjectNotNull(data.order.commande)) {
    sendCloudWatchLogs(
      `DISHOP WEBAPP - A new order has been sent.\n ${JSON.stringify(order)}`
    );
    await sendPostRequest(API_SEND_ORDER, data, headers);
  } else {
    showMessageModalWithLoading(
      setLoading,
      'Votre commande n\'a pas pu être envoyée, car votre panier ne contenait aucun produit.'
    );
    store.dispatch(showCartModal(false));
    sendCloudWatchAlert(
      `Error in shop ${order.shopId}. Basket was empty when sending order for user ${order.id}.`
    );
    return;
  }
  store.dispatch(showCartModal(false));
  store.dispatch(showOrderModal(true));
  store.dispatch(showOrderStatus(order.chargeId));
  setLoading(false);
  setChoosePayment(false);
  const items = [];
  _.map(commande, (product) => {
    const { key, name, sectionName, nb, price, position } = product;
    items.push({
      id: key,
      name,
      category: sectionName,
      position,
      quantity: nb,
      price,
    });
  });
  sendGoogleAnalytics('purchase', {
    coupon: promotion ? promotion.promoCode : '',
    currency: 'EUR',
    shipping: userServicePrice,
    tax: managementFee,
    value: totalPriceOrder,
    items,
  });
};

export const getEstimationTime = (order) => {
  const { orderType, status, isSchedule } = order;
  const {
    date: dateOrder,
    dateSent = moment(),
    datePreparationStarted = moment(),
    datePreparationEnded = moment(),
  } = order;
  const estimationTimeFormat = 'HH:mm';
  let date = moment(dateOrder);
  switch (status) {
    case ORDER_PHASE_CONTACTING: {
      if (!isSchedule) {
        let defaultMinutesTimeToAdd =
          ORDER_PHASE_CONTACTING_TIME + ORDER_PHASE_PREPARING_TIME;
        if (orderType === ORDER_TYPE_DELIVERY) {
          defaultMinutesTimeToAdd += ORDER_PHASE_DELIVERING_TIME;
        }
        date = moment(dateSent).add(defaultMinutesTimeToAdd, 'm');
      }
      const estimationTime = date;
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_PREPARING: {
      const { preparationTime, isSchedule } = order;
      let defaultMinutesTimeToAdd = preparationTime;
      if (isSchedule) {
        date = date.add(defaultMinutesTimeToAdd, 'm');
      }
      if (orderType === ORDER_TYPE_DELIVERY) {
        defaultMinutesTimeToAdd += ORDER_PHASE_DELIVERING_TIME;
      }

      let estimationTime = moment(datePreparationStarted).add(
        defaultMinutesTimeToAdd,
        'm'
      );
      if (estimationTime.isBefore(date)) {
        estimationTime = date;
      }
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_DELIVERING: {
      let estimationTime = moment(datePreparationEnded).add(
        ORDER_PHASE_DELIVERING_TIME,
        'm'
      );
      if (estimationTime.isBefore(date)) {
        estimationTime = date;
      }
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_TAKEAWAY: {
      return moment(datePreparationEnded)
        .format(estimationTimeFormat)
        .replace(':', 'h');
    }
    case ORDER_PHASE_WAITER: {
      return moment(datePreparationEnded)
        .format(estimationTimeFormat)
        .replace(':', 'h');
    }
    default:
      return null;
  }
};

export const getStatus = (order) => {
  if (_.isEmpty(order)) return '';
  const {
    status,
    orderFlowType = ORDER_FLOW_TYPE.CLASSIC,
    pickupStatus,
    userNameDep,
    orderType,
    tableNb,
  } = order;

  switch (status) {
    case ORDER_PHASE.CONTACTING:
      return handleContacting(
        pickupStatus,
        userNameDep,
        orderType,
        orderFlowType
      );
    case ORDER_PHASE.PREPARING:
      return handlePreparing(pickupStatus, userNameDep, orderType);
    case ORDER_PHASE.DELIVERING:
      return userNameDep
        ? `${userNameDep} se dirige vers vous`
        : `Votre commande est en cours de livraison` +
            ', plus que quelques instants avant de déguster votre plat.';
    case ORDER_PHASE.TAKEAWAY:
      return `La commande est prête. Venez vite la chercher avant qu’elle ne refroidisse.`;
    case ORDER_PHASE.WAITER:
      return `Votre serveur arrive avec votre commande${
        tableNb ? ` à la table ${tableNb}` : ''
      }.`;
    default:
      return '';
  }
};

const handleContacting = (
  pickupStatus,
  userNameDep,
  orderType,
  orderFlowType
) => {
  if (
    orderFlowType === ORDER_FLOW_TYPE.REVERSE &&
    orderType === ORDER_TYPE_DELIVERY
  ) {
    return pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED
      ? 'Nous cherchons actuellement un livreur partenaire avant de contacter votre restaurant préféré.'
      : `Le restaurateur a bien reçu votre commande. Nous attendons sa validation. ${
          userNameDep ? `${userNameDep} sera votre livreur.` : ''
        }`;
  }
  return 'Le restaurateur a bien reçu votre commande. Nous attendons sa validation.';
};

const handlePreparing = (pickupStatus, userNameDep, orderType) => {
  if (orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
    return 'Votre commande est en cours de préparation, vous pourrez la récupérer d’ici peu.';
  } else if (orderType === ORDER_TYPE_CLICK_AND_SEAT) {
    return 'Votre commande est en cours de préparation et sera servie sous peu.';
  }
  switch (pickupStatus) {
    case PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED:
      return 'Votre commande est en cours de préparation, elle partira en livraison d’ici peu.';
    case PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT:
      return `Votre commande est en cours de préparation, votre livreur ${userNameDep} se dirige vers le restaurant.`;
    default:
      return 'Votre commande est en cours de préparation.';
  }
};

export const checkOrdersLimit = (limit, beginHours, endHours) => {
  const { shopOrders } = store.getState().shopReducer;
  let nbOrdersInterval = 0;
  if (limit >= 0) {
    _.map(shopOrders, (order) => {
      let { date } = order;
      date = moment(date);
      if (
        date.isBefore(endHours, 'minutes') &&
        date.isSameOrAfter(beginHours, 'minutes')
      ) {
        nbOrdersInterval++;
      }
    });
    return nbOrdersInterval >= limit;
  }
  return false;
};

export const getOrderTypeLabel = (orderType) => {
  return orderType === ORDER_TYPE_DELIVERY
    ? ORDER_TYPE_DELIVERY_LABEL
    : orderType === ORDER_TYPE_CLICK_AND_COLLECT
    ? ORDER_TYPE_CLICK_AND_COLLECT_LABEL
    : ORDER_TYPE_CLICK_AND_SEAT_LABEL;
};
