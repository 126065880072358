import store from '../../redux/store';
import { showSignUp } from '../../redux/actions';
import { ckeckUserAccount } from '../../api/firebase/firebaseAuthentication';
import { EMAIL } from '../../redux/actions/types';

export const isUserHasAccount = async (email: string) => {
  await ckeckUserAccount(email).then((result) => {
    store.dispatch({ type: EMAIL, payload: email });
    if (result.length === 0) {
      store.dispatch(showSignUp(true));
    }
  });
};

export const validate = (formValues: any, isCheckPassword: boolean) => {
  const errors = {};
  if (!formValues.email) {
    errors['email'] = 'Merci de fournir un mail';
  }

  if (isCheckPassword && !formValues.password) {
    errors['password'] = 'Merci de fournir un mot de passe';
  }
  return errors;
};
