import React from 'react';
import {
  showSignUp,
  showForgetPassword,
  resetEmail,
} from '../../redux/actions';
import { renderErrorMessage } from '../../utils';
import Spinner from '../Spinner';
import { COLOR_FACEBOOK } from '../../constants';
import Button from '../Button';
import {
  UNIT,
  PRIMARY,
  LINK,
  TEXT_COLOR,
  SECONDARY,
  GOOGLE,
  FACEBOOK,
} from '../../constants';
import { isUserHasAccount, validate } from './ConnexionForm.services';
import styles from './ConnexionForm.styles';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { Field, Form } from 'react-final-form';
import { USER_CONNECTION_ERROR } from '../../redux/actions/types';

export default function ConnexionForm({
  facebookOrGoogleSignIn,
  onSubmit,
  errorMessage,
}) {
  const dispatch = useAppDispatch();

  const { loadingConnexion, email } = useAppSelector(
    (state) => state.userReducer
  );
  const { facebook, google } = useAppSelector(
    (state) => state.configurationReducer
  );

  const renderInput = ({ input, placeholder, meta }) => {
    const type = input.name
    const className = `form-control dark-modal ${
      meta.error && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-group mb-0'>
        <input
          {...input}
          type={type}
          placeholder={placeholder}
          className={className}
          readOnly={email && type === 'email'}
          value={email && type === 'email' ? email : input.value}
          autoFocus={
            (type === 'password' && input.value !== '') ||
            (type === 'email' && email)
          }
          style={{
            ...styles.inputStyle,
            marginTop: !email && UNIT * 0.5,
            marginBottom: !email && UNIT * 1.4375,
          }}
        />
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  const onSubmitEmail = (formValues) => {
    onSubmit(formValues.email, formValues.password);
  };

  const handleOnSubmit = (formValues) => {
    if (!email) {
      isUserHasAccount(formValues.email);
    } else {
      onSubmitEmail(formValues);
    }
  };

  const renderBackButton = () => {
    if (email)
      return (
        <Button
          type={SECONDARY}
          style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
          className='btn-block mt-2'
          onClick={() => {
            dispatch({ type: USER_CONNECTION_ERROR, payload: '' });
            dispatch(showSignUp(false));
            dispatch(resetEmail());
          }}
        >
          Retour à la page précédente
        </Button>
      );
  };

  const { auth } = google;

  return (
    <>
      <Form
        validate={(formValues)=>validate(formValues, !!email)}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {errorMessage !== '' && (
              <p className='text-danger'>{renderErrorMessage(errorMessage)}</p>
            )}
            <Field
              name='email'
              type='email'
              placeholder='Adresse E-mail'
              render={renderInput}
            />

            <div className='mb-3' />
            {email && (
              <Field
                name='password'
                type='password'
                placeholder='Mot de passe'
                render={renderInput}
              />
            )}
            {loadingConnexion && (
              <div className='mt-4'>
                <Spinner />
              </div>
            )}
            {!loadingConnexion && email && (
              <div>
                <Button
                  type={LINK}
                  className='p-0 label'
                  style={{
                    ...styles.forgetPasswordStyle,
                  }}
                  onClick={() => {
                    dispatch(showForgetPassword(true));
                  }}
                >
                  Mot de passe oublié ?
                </Button>
              </div>
            )}
            {!loadingConnexion && (
              <>
                <Button
                  type={PRIMARY}
                  isSubmit
                  className='btn-block mt-3'
                  style={{
                    ...styles.btnStyle,
                    margin: `${UNIT * 0.5}px 0px`,
                  }}
                >
                  Continuer
                </Button>
                {renderBackButton()}
                {(auth || facebook.auth) && (
                  <div
                    className='d-flex p-2 align-items-center'
                    style={{ marginBottom: UNIT * 0.5 }}
                  >
                    <div
                      className='hrStyle'
                      style={{ marginLeft: -(UNIT * 0.375) }}
                    />
                    <span className='label labelStyle'>ou</span>
                    <div
                      className='hrStyle'
                      style={{ marginRight: -(UNIT * 0.375) }}
                    />
                  </div>
                )}
              </>
            )}
          </form>
        )}
      />
      {!loadingConnexion && facebook.auth && (
        <Button
          className='text-white btn-block mt-2 dark-modal border-color'
          style={{
            ...styles.btnStyle,
            ...styles.broderStyle,
          }}
          onClick={() => {
            facebookOrGoogleSignIn(FACEBOOK);
          }}
        >
          <div>
            <i
              className='fab fa-facebook-f'
              style={{
                fontSize: UNIT * 0.875,
                color: COLOR_FACEBOOK,
              }}
            />

            <span
              className='text-center label'
              style={{ marginLeft: UNIT * 0.4375, color: TEXT_COLOR }}
            >
              {' '}
              Connexion avec Facebook
            </span>
          </div>
        </Button>
      )}
      {!loadingConnexion && auth && (
        <Button
          className='text-white btn-block mt-2 dark-modal border-color'
          onClick={() => {
            facebookOrGoogleSignIn(GOOGLE);
          }}
          style={{
            ...styles.btnStyle,
            ...styles.broderStyle,
          }}
        >
          <div>
            <i
              className='fab fa-google'
              style={{ fontSize: UNIT * 0.875 }}
            />

            <span
              className='text-center label'
              style={{ marginLeft: UNIT * 1.3125, color: TEXT_COLOR }}
            >
              Connexion avec Google
            </span>
          </div>
        </Button>
      )}
    </>
  );
}
