import React from 'react';
import { ORDER_TYPE_DELIVERY } from '../../../constants';
import { getDayFormat, getEstimationTime } from '../../../utils';

type OrderStatusEstimationTimeProps = {
  order: any;
};

export default function OrderStatusEstimationTime({
  order,
}: OrderStatusEstimationTimeProps) {
  const { isSchedule, date, orderType } = order;
  const estimationTime = getEstimationTime(order);
  let estimationDay = '';
  if (isSchedule) {
    estimationDay = getDayFormat(date);
  }
  return (
    <p className='text-left text-gray mb-2 label'>
      {orderType === ORDER_TYPE_DELIVERY
        ? 'Votre livraison'
        : `L'heure de récupération`}{' '}
      est prévue pour {estimationDay && 'le '} :{' '}
      <strong>{`${estimationDay} ${estimationTime}`}</strong>
    </p>
  );
}
