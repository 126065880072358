import React from 'react';
import { UNIT } from '../../../constants';
import { showPaymentModal } from '../../../redux/actions';
import { useAppDispatch } from '../../../redux/hook';
import Button from '../../Button';

type CreditCardProps = {
  name: string;
};

export default function CreditCard({ name }: CreditCardProps) {
  const dispatch = useAppDispatch();
  return (
    <div>
      <p className='text-muted mb-2 label'>Carte bancaire</p>
      <div className='input-group mb-3'>
        <input
          type='text'
          className='form-control no-localization dark-modal'
          placeholder={name}
          aria-describedby='button-addon2'
          readOnly
        />
        <div className='input-group-append'>
          <Button
            className='btn-outline-secondary label'
            id='button-addon2'
            style={{ borderRadius: `0px ${UNIT / 4}px ${UNIT / 4}px 0px` }}
            onClick={() => {
              dispatch(showPaymentModal(true));
            }}
          >
            Modifier
          </Button>
        </div>
      </div>
    </div>
  );
}
