import React from 'react';
import { useAppSelector } from '../../../redux/hook';
import { getAddressFormatted } from '../../../utils';
import { Media } from 'react-bootstrap';
import _ from 'lodash';
import { Radio } from '@mui/material';

export default function Shops({ setSelectedShop, selectedShop }) {
  const { closestShops } = useAppSelector((state) => state.shopReducer);
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  return (
    closestShops &&
    !_.isEmpty(closestShops) &&
    _.keys(closestShops).map((key) => {
      const shop = closestShops[key];
      const { name, address, distance } = shop;
      let className = 'mt-3 align-self-center border-bottom';
      if (closestShops.length - 1 === parseInt(key)) {
        className = 'mt-3 align-self-center';
      }
      return (
        <Media
          key={key}
          as='li'
          style={{
            cursor: 'pointer',
          }}
          className={className}
          onClick={() => {
            setSelectedShop(shop);
          }}
        >
          <Media.Body>
            <div className='align-items-center'>
              <div className='row justify'>
                <div className='form-check pl-0 pr-2'>
                  <Radio
                    onChange={() => setSelectedShop(shop)}
                    checked={selectedShop.shopId === shop.shopId}
                    id={name}
                    className='p-0 mb-1'
                    style={{ color: secondaryColor }}
                    size='small'
                  />
                </div>
                <h5 className='text-secondary mb-0 label'>{name}</h5>
              </div>
              <p className='text-secondary mb-3 no-localization label'>
                {distance >= 0 && (
                  <span className='d-block label'>
                    {distance.toFixed(2)} km
                  </span>
                )}
                <span className='d-block label'>
                  {getAddressFormatted(address)}
                </span>
              </p>
            </div>
          </Media.Body>
        </Media>
      );
    })
  );
}
