import React from 'react';
import { ORDER_TYPE_DELIVERY } from '../../../constants';
import {
  getExcludedOrderDays,
  getMaximumPreparationTime,
  getOrderTimes,
} from '../CartModal.services';
import moment from 'moment';
import { updateOrderTime } from '../../../redux/actions';
import DatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import fr from 'date-fns/locale/fr';
import { convertHoursToDays } from '../../../utils';
import CustomDayPickerInput from './CustomDayPickerInput';

export default function OrderTime({
  day,
  selectDay,
  setDay,
  setIsValidDate,
  setHourSelectedValue,
  setSelectDay,
  isValidDate,
  setSelectTime,
  hourSelectValue,
  selectTime,
}) {
  const dispatch = useAppDispatch();

  const { orderTime } = useAppSelector((state) => state.orderReducer);

  const { closed } = useAppSelector((state) => state.shopReducer);

  const { commande, orderType } = useAppSelector(
    (state) => state.pendingOrderReducer
  );

  let title = 'Heure de récupération';
  if (orderType === ORDER_TYPE_DELIVERY) {
    title = 'Heure de livraison';
  }
  const maximumPreparationTime = getMaximumPreparationTime(commande);
  const orderTimes = getOrderTimes(day, orderType, maximumPreparationTime);
  const displayDay = moment().isBefore(day);

  return (
    <div className='mb-2'>
      <p className='text-muted mb-1 label'>{title}</p>
      <div className='row pb-2'>
        <div className='col-6 pr-3'>
          <p className='mb-0'>Jour: </p>
          <DatePicker
            selected={displayDay && !selectDay ? null : day.toDate()}
            filterDate={(date) => {
              const isDayIncluded = getExcludedOrderDays(
                date,
                maximumPreparationTime,
                orderType
              );
              return isDayIncluded;
            }}
            onChange={(date) => {
              const newDate = moment(date);
              const orderTimes = getOrderTimes(
                newDate,
                orderType,
                maximumPreparationTime
              );
              dispatch(updateOrderTime(orderTimes[0]));
              setDay(newDate);
              setIsValidDate(true);
              setHourSelectedValue(0);
              setSelectDay(true);
            }}
            customInput={
              <CustomDayPickerInput
                isValidDate={isValidDate}
                orderTime={orderTime?.value}
                closed={closed}
              />
            }
            locale={fr}
            maxDate={moment().add(30, 'd').toDate()}
            disabled={closed}
          />
          {!isValidDate && !orderTime && (
            <div className='invalid-feedback-block'>
              Veuillez choisir une date{' '}
            </div>
          )}
        </div>
        <div className='col-6 pl-3'>
          <p className='mb-0'>Heure: </p>
          <select
            disabled={(displayDay && !selectDay) || closed}
            className={`custom-select ${
              !isValidDate &&
              orderTime &&
              !orderTime.currentSlot &&
              'is-invalid'
            } dark-modal`}
            onChange={(e) => {
              const index = e.target.value;
              const orderTime = orderTimes[index];
              dispatch(updateOrderTime(orderTime));
              setHourSelectedValue(index);
              setIsValidDate(true);
              setSelectTime(true);
            }}
            value={
              ((!orderTimes[0]?.currentSlot || orderTimes[0]?.disabled) &&
                !selectTime) ||
              closed
                ? ''
                : hourSelectValue
            }
          >
            <option
              value=''
              className='empty-option'
            ></option>
            {orderTimes?.map((orderTime, index) => {
              const { value } = orderTime;
              return (
                <option
                  disabled={orderTime.disabled}
                  value={index}
                >
                  {value}
                </option>
              );
            })}
          </select>
          {!isValidDate && orderTime && !orderTime.currentSlot && (
            <div className='invalid-feedback-block'>
              Veuillez choisir une heure{' '}
            </div>
          )}
        </div>
      </div>
      {maximumPreparationTime > 0 && (
        <p className='text-warning mb-0'>
          {`Votre panier nécessite au moins ${
            convertHoursToDays(maximumPreparationTime) > 0
              ? `${convertHoursToDays(maximumPreparationTime)} jour(s)`
              : `${maximumPreparationTime} heure(s)`
          } de préparation`}
        </p>
      )}
    </div>
  );
}
