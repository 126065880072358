import React from 'react';
import {
  CONTACTING_IMAGE_URL,
  COOKING_IMAGE_URL,
  ORDER_FLOW_TYPE,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_PHASE_WAITER,
  ORDER_TYPE_DELIVERY,
  PICKUP_STATUS,
  WAITER_IMAGE_URL,
} from '../../../constants';
import OrderSteps from '../../OrderSteps';
import { getStatus } from '../../../utils';
import MapClient from '../../MapClient';
import _ from 'lodash';
import { useAppSelector } from '../../../redux/hook';
import OrderStatusEmergencyDelivery from './OrderStatusEmergencyDelivery';
import OrderStatusContactingOrderDetails from './OrderStatusContactingOrderDetails';
import OrderStatusEstimationTime from './OrderStatusEstimationTime';

type OrderStatusStatusProps = {
  order: any;
  chargeId: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OrderStatusStatus({
  order,
  chargeId,
  isLoading,
  setIsLoading,
}: OrderStatusStatusProps) {
  const { status, orderFlowType, pickupStatus, orderType } = order;
  const { delivery } = useAppSelector((state) => state.configurationReducer);

  const renderSwitch = () => {
    const orderFlowReverse = orderFlowType === ORDER_FLOW_TYPE.REVERSE;
    switch (status) {
      case ORDER_PHASE_CONTACTING: {
        const isContactingEmergency =
          order?.status == ORDER_PHASE_CONTACTING &&
          order?.selfPickupEligible &&
          pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED;
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={
                orderFlowReverse &&
                pickupStatus === PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT
                  ? 1
                  : 0
              }
            />
            <div>
              {delivery?.emergency?.enabled && isContactingEmergency ? (
                <OrderStatusEmergencyDelivery
                  order={order}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <OrderStatusContactingOrderDetails
                  order={order}
                  chargeId={chargeId}
                />
              )}
            </div>
          </>
        );
      }
      case ORDER_PHASE_PREPARING: {
        const isPreparingEmergency =
          order?.status == ORDER_PHASE_PREPARING &&
          order?.selfPickupEligible &&
          order.pickupStatus < PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT;
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={
                orderFlowReverse && orderType === ORDER_TYPE_DELIVERY
                  ? 2
                  : 1
              }
            />
            {isPreparingEmergency ? (
              <OrderStatusEmergencyDelivery
                order={order}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : (
              <div>
                <p className='text-left mb-0 label'>{getStatus(order)}</p>
                <img
                  style={{ height: '150px', width: '300px' }}
                  className='img-fluid rounded mx-auto d-block my-4'
                  alt='cooking gif'
                  src={COOKING_IMAGE_URL}
                />
                <OrderStatusEstimationTime order={order} />
              </div>
            )}
          </>
        );
      }
      case ORDER_PHASE_DELIVERING: {
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={
                orderFlowReverse && orderType === ORDER_TYPE_DELIVERY
                  ? 3
                  : 2
              }
            />
            <p className='text-left label'>{getStatus(order)}</p>
            <div className='my-3'>
              <MapClient chargeId={chargeId} />
            </div>
            <OrderStatusEstimationTime order={order} />
          </>
        );
      }
      case ORDER_PHASE_TAKEAWAY: {
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={2}
            />
            <p className='text-left label'>{getStatus(order)}</p>
            <div className='my-3'>
              <MapClient chargeId={chargeId} />
            </div>
          </>
        );
      }
      case ORDER_PHASE_WAITER: {
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={2}
            />
            <p className='text-left mb-0 label'>{getStatus(order)}</p>
            <img
              style={{ height: '300px', width: '300px' }}
              className='img-fluid rounded mx-auto my-4'
              alt='waiter gif'
              src={WAITER_IMAGE_URL}
            />
          </>
        );
      }
      default:
        return (
          <>
            <OrderSteps
              order={order}
              activeStep={0}
            />
            <img
              style={{ height: '160px', width: '240px' }}
              className='img-fluid rounded mx-auto my-4'
              alt='contacting gif'
              src={CONTACTING_IMAGE_URL}
            />
          </>
        );
    }
  };

  return _.isEmpty(order) ? <></> : renderSwitch();
}
