import React from 'react';
import _ from 'lodash';
import Order from '../Order';
import { useAppSelector } from '../../redux/hook';

export default function PastOrders() {
  const { orders, pastOrders } = useAppSelector((state) => state.orderReducer);

  return _.isEmpty(pastOrders) ? (
    <div className='text-center'>
      <p className='text-secondary mb-0 label'>
        {_.isEmpty(orders)
          ? 'Vous n\'avez encore jamais passé de commande.'
          : 'Vous n\'avez pas encore de commande finalisée.'}
      </p>
    </div>
  ) : (
    <div>
      {_.keys(pastOrders)
        .sort(
          (a, b) =>
            new Date(pastOrders[b].date).getTime() -
            new Date(pastOrders[a].date).getTime()
        )
        .map((key) => {
          const order = pastOrders[key];
          return (
            <Order
              order={order}
              key={key}
              showFees
              showPromo
            />
          );
        })}
    </div>
  );
}
