import React from 'react';
import { updateAddressAttribute } from '../../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';

export default function AskApartment() {
  const dispatch = useAppDispatch();

  const {
    address: { street2 },
  } = useAppSelector((state) => state.pendingOrderReducer);

  return (
    <div className='row mb-2'>
      <div className='col'>
        <div className='input-group mb-1'>
          <input
            name='street2'
            type='text'
            value={street2}
            placeholder="Nom de l'immeuble/N° d'appartement"
            onChange={(e) => {
              dispatch(updateAddressAttribute('street2', e.target.value));
            }}
            className='form-control dark-modal'
          />
        </div>
      </div>
    </div>
  );
}
