import { sendCloudWatchAlert, sendCloudWatchLogs } from '../../utils';
import {
  retrieveCard,
  updateCustomerPaymentCardBackend,
  updateDefaultCard,
} from '../../api';
import { deleteCustomerPaymentCardBackend } from '../../api/backend';
import store from '../../redux/store';
import { showMessageModal, showPaymentModal } from '../../redux/actions';

type ChangeDefaultCreditCardParams = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCard: React.Dispatch<React.SetStateAction<any>>;
  newCardId: any;
};

export const changeDefaultCreditCard = async ({
  setLoading,
  setSelectedCard,
  newCardId,
}: ChangeDefaultCreditCardParams) => {
  try {
    sendCloudWatchLogs(`Updating default card to ${newCardId}`);
    const { user, userConnected } = store.getState().userReducer;
    const { customerId } = user;
    setLoading(true);
    if (userConnected) {
      const { stripeCustomerId } = user;
      const card = await retrieveCard({
        cardId: newCardId,
        customerId,
      });
      if (card?.customer) {
        sendCloudWatchLogs('Updating default source on stripe');
        await updateDefaultCard({
          cardId: newCardId,
          stripeCustomerId,
          customerId,
        });
      }
      await updateCustomerPaymentCardBackend(newCardId);
    }
    setLoading(false);
    setSelectedCard({});
    store.dispatch(showPaymentModal(false));
  } catch (error) {
    sendCloudWatchAlert(`Error updating default credit card: ${error}`);
    store.dispatch(
      showMessageModal(
        'Nous n\'avons pas pu modifier votre carte par défaut. Veuillez réessayer ou ajouter une nouvelle carte bancaire.'
      )
    );
    setLoading(false);
  }
};

export const deleteCB = async (cardId: any) => {
  try {
    await deleteCustomerPaymentCardBackend(cardId);
    store.dispatch(
      showMessageModal(
        `Nous vous confirmons la suppression de votre carte bancaire`
      )
    );
  } catch (error) {
    store.dispatch(
      showMessageModal(
        `Nous n'avons pas pu supprimer votre carte bancaire, veuillez essayer de nouveau.`
      )
    );
    sendCloudWatchAlert(`Error deleting user CB ${error}`);
  }
};
