import React from 'react';
import Category from './components/Category';
import { addItem, addUniqueItem, removeItem } from './Categories.services';
import SelectItemLastStep from '../SelectItemLastStep';

type CategoriesProps = {
  items: any;
  nb: number;
  unit: any;
  product: any;
  productCategories: any;
  stepOptionIndex: any;
  shopCategories: any;
  modalOptionContainer: any;
  title: any;
  itemsCounter: any;
  mandatoryCategories: any;
  linkBetweenOptionsAndSubOptions: any;
  setItems: React.Dispatch<React.SetStateAction<any>>;
  setShopCategories: React.Dispatch<React.SetStateAction<any>>;
  setProductCategories: React.Dispatch<React.SetStateAction<any>>;
  setMandatoryCategories: React.Dispatch<React.SetStateAction<any>>;
  setLinkBetweenOptionsAndSubOptions: React.Dispatch<React.SetStateAction<any>>;
  setItemsCounter: React.Dispatch<React.SetStateAction<any>>;
  instruction: string;
  setInstruction: React.Dispatch<React.SetStateAction<string>>;
  setNb: React.Dispatch<React.SetStateAction<number>>;
  setUnit: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
  price: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sectionName: any;
  sectionKey: any;
  resetComponent: (notHide: boolean) => void;
  productKey: any;
};

export default function Categories({
  items,
  nb,
  unit,
  product,
  productCategories,
  stepOptionIndex,
  shopCategories,
  modalOptionContainer,
  title,
  itemsCounter,
  mandatoryCategories,
  linkBetweenOptionsAndSubOptions,
  setItems,
  setShopCategories,
  setProductCategories,
  setMandatoryCategories,
  setLinkBetweenOptionsAndSubOptions,
  setItemsCounter,
  instruction,
  setInstruction,
  setNb,
  setUnit,
  loading,
  price,
  setLoading,
  sectionName,
  sectionKey,
  resetComponent,
  productKey,
}: CategoriesProps) {
  const handleAddItem = (index: any, category: any, item: any, count: any) =>
    addItem({
      index,
      category,
      item,
      count,
      itemsCounter,
      items,
      shopCategories,
      productCategories,
      mandatoryCategories,
      linkBetweenOptionsAndSubOptions,
      setItems,
      setShopCategories,
      setProductCategories,
      setMandatoryCategories,
      setLinkBetweenOptionsAndSubOptions,
      setItemsCounter,
    });

  const handleAddUniqueItem = (index: any, category: any, item: any) =>
    addUniqueItem({
      index,
      category,
      item,
      itemsCounter,
      items,
      shopCategories,
      productCategories,
      mandatoryCategories,
      linkBetweenOptionsAndSubOptions,
      setItems,
      setShopCategories,
      setProductCategories,
      setMandatoryCategories,
      setLinkBetweenOptionsAndSubOptions,
      setItemsCounter,
    });

  const handleRemoveItem = (index: any, category: any, item: any, count: any) =>
    removeItem({
      index,
      category,
      item,
      count,
      itemsCounter,
      items,
      productCategories,
      shopCategories,
      mandatoryCategories,
      linkBetweenOptionsAndSubOptions,
      setItems,
      setShopCategories,
      setProductCategories,
      setMandatoryCategories,
      setItemsCounter,
    });

  if (
    productCategories?.[stepOptionIndex] &&
    stepOptionIndex < productCategories?.length
  ) {
    const categoryId = productCategories[stepOptionIndex];
    const category = shopCategories[categoryId];
    if (category) {
      return (
        <Category
          productTitle={title}
          product={product}
          nbProduct={nb}
          category={category}
          key={categoryId}
          categoryId={categoryId}
          itemsCounter={itemsCounter}
          productItems={items}
          categoryKey={categoryId}
          index={stepOptionIndex}
          addUniqueItem={handleAddUniqueItem}
          addItem={handleAddItem}
          removeItem={handleRemoveItem}
          modalOptionContainer={modalOptionContainer}
        />
      );
    }
  }
  if (productCategories.length === stepOptionIndex) {
    return (
      <SelectItemLastStep
        items={items}
        nb={nb}
        setNb={setNb}
        unit={unit}
        product={product}
        productCategories={productCategories}
        stepOptionIndex={stepOptionIndex}
        modalOptionContainer={modalOptionContainer}
        shopCategories={shopCategories}
        price={price}
        setLoading={setLoading}
        mandatoryCategories={mandatoryCategories}
        productKey={productKey}
        instruction={instruction}
        sectionName={sectionName}
        sectionKey={sectionKey}
        resetComponent={resetComponent}
        loading={loading}
        setUnit={setUnit}
        setInstruction={setInstruction}
      />
    );
  }
}
