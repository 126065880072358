import React from 'react';
import CloseButtonMui from 'react-bootstrap/CloseButton';

class CloseButton extends React.Component {
  render() {
    const { onClick, closeStyle } = this.props;
    return (
      <div className='row closeStyle' style={{ ...closeStyle }}>
        <CloseButtonMui
          onClick={() => {
            onClick();
          }}
        />
      </div>
    );
  }
}

export default CloseButton;
