import React from 'react';
import {
  CONTACTING_IMAGE_URL,
  ORDER_FLOW_TYPE,
  ORDER_TYPE_DELIVERY,
  PICKUP_STATUS,
} from '../../../constants';
import { getStatus } from '../../../utils';
import MapClient from '../../MapClient';
import OrderStatusEstimationTime from './OrderStatusEstimationTime';

type OrderStatusContactingOrderDetailsProps = {
  order: any;
  chargeId: string;
};

export default function OrderStatusContactingOrderDetails({
  order,
  chargeId,
}: OrderStatusContactingOrderDetailsProps) {
  const { orderType, pickupStatus, orderFlowType } = order;
  return (
    <div>
      <p className='text-left mb-0 label'>{getStatus(order)}</p>
      {orderFlowType === ORDER_FLOW_TYPE.REVERSE &&
      orderType === ORDER_TYPE_DELIVERY &&
      pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED ? (
        <div className='my-3'>
          <MapClient chargeId={chargeId} />
        </div>
      ) : (
        <img
          style={{ height: '160px', width: '240px', alignItems: 'center' }}
          className='img-fluid rounded mx-auto mb-4'
          alt='contacting gif'
          src={CONTACTING_IMAGE_URL}
        />
      )}
      <OrderStatusEstimationTime order={order} />
    </div>
  );
}
