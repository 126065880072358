import React from 'react';
import {
  convertHoursToDays,
  isChatbot,
  isStringNotNull,
} from '../../../../../../../utils';
import { UNIT } from '../../../../../../../constants';
import Categories from './Categories/Categories';

type ProductModalBodyProps = {
  product: any;
  productAdded: any;
  title: string;
  image: any;
  description: string;
  items: any;
  nb: number;
  unit: any;
  productCategories: any;
  stepOptionIndex: any;
  shopCategories: any;
  modalOptionContainer: any;
  itemsCounter: any;
  mandatoryCategories: any;
  linkBetweenOptionsAndSubOptions: any;
  setItems: React.Dispatch<React.SetStateAction<any>>;
  setShopCategories: React.Dispatch<React.SetStateAction<any>>;
  setProductCategories: React.Dispatch<React.SetStateAction<any>>;
  setMandatoryCategories: React.Dispatch<React.SetStateAction<any>>;
  setLinkBetweenOptionsAndSubOptions: React.Dispatch<React.SetStateAction<any>>;
  setItemsCounter: React.Dispatch<React.SetStateAction<any>>;
  instruction: string;
  setInstruction: React.Dispatch<React.SetStateAction<string>>;
  setNb: React.Dispatch<React.SetStateAction<number>>;
  setUnit: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  price: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sectionName: string;
  sectionKey: string;
  resetComponent: (notHide: boolean) => void;
  productKey: string;
};

export default function ProductModalBody({
  product,
  productAdded,
  title,
  image,
  description,
  items,
  nb,
  unit,
  productCategories,
  stepOptionIndex,
  shopCategories,
  modalOptionContainer,
  itemsCounter,
  mandatoryCategories,
  linkBetweenOptionsAndSubOptions,
  setItems,
  setShopCategories,
  setProductCategories,
  setMandatoryCategories,
  setLinkBetweenOptionsAndSubOptions,
  setItemsCounter,
  instruction,
  setInstruction,
  setNb,
  setUnit,
  loading,
  price,
  setLoading,
  sectionName,
  sectionKey,
  resetComponent,
  productKey,
}: ProductModalBodyProps) {
  const { preparationTime } = product;
  if (productAdded) {
    return <p className='label'>{title} a bien été ajouté au panier.</p>;
  }
  return (
    <div className='text-center'>
      {isStringNotNull(image) && (
        <div className='mb-3 p-0'>
          <img
            src={image}
            alt={title}
            className='img-fluid col-12 image-modal-product'
            style={{ borderRadius: UNIT }}
          />
        </div>
      )}
      {!isStringNotNull(image) && <div className='mt-4' />}
      <div className='px-sm-4 my-3'>
        <h2
          className={`text-break`}
          id='product-modal-title'
        >
          {title}
        </h2>
        {isStringNotNull(description) && (
          <p className='text-secondary text-break line-break mb-1 label'>
            {description}
          </p>
        )}
        <div className='col-12'>
          {preparationTime > 0 && (
            <p className='text-warning font-weight-bold font-italic pt-3 label'>
              {`Pour votre information, ce produit nécessite au moins ${
                convertHoursToDays(preparationTime) > 0
                  ? `${convertHoursToDays(preparationTime)} jour(s)`
                  : `${preparationTime} heure(s)`
              } de préparation`}
            </p>
          )}
        </div>
        {!isChatbot() && (
          <div>
            <div
              className='row'
              id='optionContainer'
            >
              <Categories
                items={items}
                nb={nb}
                unit={unit}
                product={product}
                productCategories={productCategories}
                stepOptionIndex={stepOptionIndex}
                shopCategories={shopCategories}
                modalOptionContainer={modalOptionContainer}
                title={title}
                itemsCounter={itemsCounter}
                mandatoryCategories={mandatoryCategories}
                linkBetweenOptionsAndSubOptions={
                  linkBetweenOptionsAndSubOptions
                }
                setItems={setItems}
                setShopCategories={setShopCategories}
                setProductCategories={setProductCategories}
                setMandatoryCategories={setMandatoryCategories}
                setLinkBetweenOptionsAndSubOptions={
                  setLinkBetweenOptionsAndSubOptions
                }
                setItemsCounter={setItemsCounter}
                instruction={instruction}
                setInstruction={setInstruction}
                setNb={setNb}
                setUnit={setUnit}
                loading={loading}
                price={price}
                setLoading={setLoading}
                sectionName={sectionName}
                sectionKey={sectionKey}
                resetComponent={resetComponent}
                productKey={productKey}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
