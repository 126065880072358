export const preventPageBadEffect = () => {
  document.body.setAttribute('style', 'padding: 0px !i  mportant;');
  document
    .getElementById('sticky-cart')
    .setAttribute('style', 'padding: 0px !important;');
};
export const getActiveOptions = (
  productCategories: any,
  shopCategories: any
): any[] => {
  const categories = new Set();
  productCategories?.split(',')?.forEach((category) => {
    const categoryKey = category?.split('@')[0];
    if (shopCategories[categoryKey]) {
      categories.add(category);
    }
  });
  return Array.from(categories);
};
