import store from '../../redux/store';
import {
  showAddressModal,
  showChooseShop,
  showMessageModal,
} from '../../redux/actions';
import { isObjectNotNull, sendCloudWatchLogs } from '../../utils';
import { updateAddressAndShop } from '../../utils/customer-address-and-shops';
import { ORDER_TYPE_DELIVERY } from '../../constants';

type OnChooseShopParams = {
  shop: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const onChooseShop = async ({
  shop,
  setLoading,
}: OnChooseShopParams) => {
  try {
    const { delivery } = store.getState().configurationReducer;
    const { addressTemp } = store.getState().componentReducer;
    const { chooseShop } = delivery;
    const { address } = shop;
    setLoading(true);
    let customerAddress = address;
    let orderType = store.getState().pendingOrderReducer?.orderType;
    if (isObjectNotNull(addressTemp)) {
      customerAddress = addressTemp;
      orderType = addressTemp.orderType;
    }
    await updateAddressAndShop(
      shop.shopId,
      customerAddress,
      chooseShop && orderType === ORDER_TYPE_DELIVERY
        ? ORDER_TYPE_DELIVERY
        : orderType
    );
    store.dispatch(showAddressModal(false));
    store.dispatch(showChooseShop(false));
    setLoading(false);
  } catch (error) {
    sendCloudWatchLogs(`Could not change shop ${error}`);
    store.dispatch(showMessageModal(error));
    setLoading(false);
  }
};
