import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { PRIMARY, SECONDARY } from '../../../constants';
import {
  showCartModal,
  showConnexionModal,
  showMessageModal,
} from '../../../redux/actions';
import Button from '../../Button';
import {
  getFirstDay,
  getMaximumPreparationTime,
  sendOrderMessenger,
} from '../CartModal.services';
import { isQrCode, isWebView } from '../../../utils';
import _ from 'lodash';
import Spinner from '../../Spinner';

type CartModalFooterProps = {
  resetState: () => void;
  choosePayment: boolean;
  setChoosePayment: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDay: React.Dispatch<React.SetStateAction<any>>;
  setHourSelectedValue: React.Dispatch<React.SetStateAction<number>>;
  setSelectDay: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectTime: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidDate: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CartModalFooter({
  resetState,
  choosePayment,
  setChoosePayment,
  loading,
  setLoading,
  setDay,
  setHourSelectedValue,
  setSelectDay,
  setSelectTime,
  setIsValidDate,
}: CartModalFooterProps) {
  const dispatch = useAppDispatch();

  const { userConnected } = useAppSelector((state) => state.userReducer);
  const { multiOrder } = useAppSelector((state) => state.configurationReducer);

  const { orders } = useAppSelector((state) => state.orderReducer);
  const { commande, address: customerAddress } = useAppSelector(
    (state) => state.pendingOrderReducer
  );

  return _.isEmpty(commande) ? (
    <Button
      type={SECONDARY}
      onClick={() => {
        dispatch(showCartModal(false));
        resetState();
      }}
    >
      Fermer
    </Button>
  ) : choosePayment ? (
    <Button
      type={SECONDARY}
      onClick={() => setChoosePayment(false)}
    >
      Retour
    </Button>
  ) : loading ? (
    <Spinner />
  ) : (
    <>
      <Button
        type={SECONDARY}
        onClick={() => {
          dispatch(showCartModal(false));
          resetState();
        }}
      >
        Fermer
      </Button>
      {!_.isEmpty(commande) && customerAddress && (
        <Button
          type={PRIMARY}
          className={`btn`}
          onClick={() => {
            const maximumPreparationTime = getMaximumPreparationTime(commande);
            getFirstDay(
              maximumPreparationTime,
              setDay,
              setHourSelectedValue,
              setSelectDay,
              setSelectTime,
              setIsValidDate
            );
            if (isWebView()) {
              sendOrderMessenger(setLoading);
            } else if (!multiOrder && !_.isEmpty(orders)) {
              dispatch(
                showMessageModal(
                  `Vous ne pouvez pas valider votre panier car vous avez déjà ${
                    _.size(orders) > 1
                      ? `${_.size(orders)} commandes`
                      : 'une commande'
                  } en cours`
                )
              );
            } else if (userConnected || isQrCode()) {
              setChoosePayment(true);
            } else {
              dispatch(showConnexionModal(true));
            }
          }}
        >
          Commander
        </Button>
      )}
    </>
  );
}
