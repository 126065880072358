import { isObjectNotNull, validateEmail } from '../../utils';

export const validateFormValues = (formValues: any, signUpData: any) => {
  const errors = {};
  if (!isObjectNotNull(signUpData)) {
    if (!formValues.nom) {
      errors['nom'] = 'Merci de mettre un nom';
    }
    if (!formValues.prenom) {
      errors['prenom'] = 'Merci de mettre un prénom';
    }
    if (!formValues.email) {
      errors['email'] = 'Merci de fournir un mail';
    } else if (!validateEmail(formValues.email)) {
      errors['email'] = 'Veuillez fournir un mail valide';
    }

    if (!formValues.password) {
      errors['password'] = 'Merci de fournir un mot de passe';
    }

    if (!formValues.confirmPassword) {
      errors['confirmPassword'] = 'Merci de confirmer le mot de passe';
    }

    if (formValues.confirmPassword !== formValues.password) {
      errors['confirmPassword'] = 'Les mots de passe ne sont pas identiques';
    }
  }

  if (!formValues.CGU) {
    errors['CGU'] = 'Merci de valider les conditions générales d\'utilisation';
  }

  return errors;
};
