import React from 'react';
import {
  displayPriceFormat,
  getMinimumPriceFreeProduct,
  isStringNotNull,
} from '../../../../../../../utils';

type ProductPriceProps = {
  product: any;
};

export default function ProductPrice({ product }: ProductPriceProps) {
  const { price, unit } = product;
  const minimumPrice = getMinimumPriceFreeProduct(product);

  return minimumPrice > 0 || price > 0 ? (
    price > 0 ? (
      <h6 className={`text-product text-muted`}>
        {displayPriceFormat(price)}
        {isStringNotNull(unit) ? `/${unit}` : ''}
      </h6>
    ) : (
      <h6 className='text-product text-muted font-italic'>
        À partir de {displayPriceFormat(minimumPrice)}
        {isStringNotNull(unit) ? `/${unit}` : ''}
      </h6>
    )
  ) : (
    <></>
  );
}
