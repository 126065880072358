import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hook';
import _ from 'lodash';
import OrderStatus from '../../OrderStatus';
import { showOrderStatus } from '../../../redux/actions';
import Order from '../../Order/Order.view';

type OrderModalBodyProps = {
  orderStatus: any;
  orders: any;
};

export default function OrderModalBody({
  orderStatus,
  orders,
}: OrderModalBodyProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (_.size(orders) === 1) {
      const chargeId = _.map(orders, (order) => {
        return order.chargeId;
      });
      if (chargeId) dispatch(showOrderStatus(chargeId));
    }
  }, [orders]);

  if (orderStatus && !_.isEmpty(orders) && orders[orderStatus]) {
    return <OrderStatus chargeId={orderStatus} />;
  } else {
    return (
      !_.isEmpty(orders) &&
      _.keys(orders)
        .sort(
          (a, b) =>
            new Date(orders[b].date).getTime() -
            new Date(orders[a].date).getTime()
        )
        .map((key) => {
          const order = orders[key];
          return (
            <div
              onClick={() => {
                dispatch(showOrderStatus(order.chargeId));
              }}
            >
              <Order
                order={order}
                key={key}
              />
            </div>
          );
        })
    );
  }
}
