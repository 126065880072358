import React from 'react';
import {
  IMAGE_ASPECT_COVER,
  UNIT,
} from '../../../../../../../../../../../constants';
import { useAppSelector } from '../../../../../../../../../../../redux/hook';

type OptionIconProps = {
  optionIconUrl: string;
};

export default function OptionIcon({ optionIconUrl }: OptionIconProps) {
  const {
    images: { aspect },
  } = useAppSelector((state) => state.configurationReducer);
  const imageOptionStyle = { width: '60px', height: '60px' };
  const imageOptionClassName = 'rounded-circle';
  return (
    <div
      style={{ marginRight: UNIT * 0.5 }}
      className={`align-self-center`}
    >
      {aspect === IMAGE_ASPECT_COVER && (
        <div
          className={imageOptionClassName}
          style={{
            ...imageOptionStyle,
            backgroundImage: `url(${optionIconUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            margin: 'auto',
          }}
        />
      )}
      {aspect !== IMAGE_ASPECT_COVER && (
        <img
          alt=''
          src={optionIconUrl}
          style={imageOptionStyle}
          className={imageOptionClassName}
        />
      )}
    </div>
  );
}
