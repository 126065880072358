import React from 'react';
import { PAYMENT_TYPE_STRIPE, PRIMARY, SECONDARY } from '../../../constants';
import Button from '../../Button';
import { getCreditCard } from '../../../utils';
import { useAppDispatch } from '../../../redux/hook';
import { showPaymentModal } from '../../../redux/actions';
import { addCustomerCard } from '../../../api';
import { getPaymentTypeLabel } from '../CartModal.services';
import { Stripe, StripeElement } from '@stripe/stripe-js';

type PaymentTypeProps = {
  paymentTypes: any;
  stripe: Stripe;
  elements: StripeElement;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleCheckOrder: (card: any, paymentType: string) => Promise<void>;
  isUserAnonymous: boolean;
};

export default function PaymentType({
  paymentTypes,
  stripe,
  elements,
  setLoading,
  handleCheckOrder,
  isUserAnonymous,
}: PaymentTypeProps) {
  const dispatch = useAppDispatch();

  return paymentTypes.map((paymentType: string) => {
    let className = 'btn btn-block ';
    if (paymentType !== PAYMENT_TYPE_STRIPE) {
      className += 'btn-secondary';
    }
    return (
      <div className='row mb-2'>
        <div className='col'>
          <Button
            type={`${
              paymentType === PAYMENT_TYPE_STRIPE ? PRIMARY : SECONDARY
            }`}
            className={className}
            onClick={async () => {
              const card = getCreditCard();
              if (paymentType === PAYMENT_TYPE_STRIPE && !card) {
                if (!isUserAnonymous) {
                  dispatch(showPaymentModal(true));
                } else {
                  setLoading(true);
                  const card = await addCustomerCard(stripe, elements);
                  if (card) {
                    await handleCheckOrder(card, paymentType);
                  } else {
                    setLoading(false);
                  }
                }
              } else {
                await handleCheckOrder(card, paymentType);
              }
            }}
          >
            {getPaymentTypeLabel(paymentType)}
          </Button>
        </div>
      </div>
    );
  });
}
