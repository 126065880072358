import React, { useState } from 'react'
import { UNIT } from '../../../constants';
import Button from '../../Button';
import { addPromotion } from '../Order.services';

export default function AddPromoCode({ totalProductPrice }) {
    const [loadingPromotion, setLoadingPromotion] = useState(false);
    const [promoCode, setPromoCode] = useState('');

  return (
    <div>
    <div className='input-group my-4'>
      <input
        type='text'
        className='form-control font-italic'
        placeholder='Ajouter un code promotionnel'
        aria-describedby='button-addon2'
        onChange={(e) => {
          setPromoCode(e.target.value);
        }}
        style={{ fontSize: UNIT * 0.75 }}
      />
      <div className='input-group-append'>
        <Button
          className='btn-outline-secondary label'
          id='button-addon2'
          style={{ borderRadius: `0px ${UNIT / 4}px ${UNIT / 4}px 0px` }}
          onClick={() => {
            addPromotion(setLoadingPromotion, promoCode, totalProductPrice);
          }}
        >
          {loadingPromotion && (
            <div className='d-flex justify-content-center align-items-center'>
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              />
              <span className='sr-only'>Loading...</span>
            </div>
          )}
          {!loadingPromotion && (
            <div style={{ fontSize: UNIT * 0.75 }}>Appliquer</div>
          )}
        </Button>
      </div>
    </div>
  </div>
  )
}
