import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Cover from '../../components/Cover/Cover';
import ListSection from '../../components/ListSections/ListSections/ListSections.view';
import ListProduct from '../../components/ListProduct';
import Footer from '../../components/Footer';
import Splash from '../../components/Splash';
import InfoBar from '../../components/InfoBar/InfoBar.view';
import {
  setIsMobile,
  showAddressModal,
  showHorizontalSection,
  showMessageModal,
  updateShopIsLoaded,
  userSignOut,
} from '../../redux/actions';
import {
  getClosedMessage,
  isQrCode,
  isSplash,
  isStringNotNull,
} from '../../utils';
import { loadLocalizeSdk } from '../../api';
import {
  displayOrNotHeader,
  getClasses,
  handleScroll,
  removeItemFromCommande,
  updateDimensions,
  updateShopFromLink,
} from './Home.services';
import { BLOCK_CONTENT } from '../../constants';
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../redux/reducers/componentReducer';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import CloseBar from './components/CloseBar';

export default function Home() {
  const dispatch = useAppDispatch();

  const { shopId: shopIdFromLink } = useParams();

  if (shopIdFromLink === 'mentions-legales') {
    return <></>;
  }

  const [displaySectionsNavBar, setDisplaySectionsNavBar] = useState(false);
  const [executed, setExecuted] = useState(false);

  const {
    isUserAnonymous,
    userConnected,
    user: { customerId },
  } = useAppSelector((state) => state.userReducer);
  const {
    commande,
    address: customerAddress,
    orderType,
    addressIsLoaded,
    shopId,
  } = useAppSelector((state) => state.pendingOrderReducer);

  const {
    navbarHeight,
    overlayCover,
    isHorizontalSection,
    googleMapsEnabled,
    connexionModal,
    customerAddressCheck,
  } = useAppSelector((state) => state.componentReducer);
  const {
    sectionOrientation,
    servicePrice: { networkFees },
  } = useAppSelector((state) => state.configurationReducer);
  const { closed, sections, categories, shopIsLoaded } = useAppSelector(
    (state) => state.shopReducer
  );

  const listSectionsRef = useRef(null);
  const prevOrderType = useRef<string>(orderType);

  const { listSectionClass, listProductClass } = getClasses();
  const displayNavBar =
    isHorizontalSection && (displaySectionsNavBar || isUserAnonymous);
  displayOrNotHeader(displaySectionsNavBar || isUserAnonymous);
  const displayOverlay = overlayCover === BLOCK_CONTENT;
  const showSplash = isSplash() && !shopIsLoaded;

  useEffect(() => {
    if (!isQrCode() && isUserAnonymous) {
      userSignOut();
    }
  }, [isUserAnonymous, window.location.href]);

  useEffect(() => {
    const runUpdateShopFromLink = async () => {
      await updateShopFromLink({
        shopIdFromLink,
        customerAddress,
        orderType,
      });
    };

    if (addressIsLoaded) runUpdateShopFromLink();
  }, [customerAddress, shopId, orderType, addressIsLoaded]);

  useEffect(() => {
    const dimensionUpdate = () => updateDimensions();
    const scrollEvent = () =>
      handleScroll(
        listSectionsRef.current,
        displaySectionsNavBar,
        setDisplaySectionsNavBar
      );

    loadLocalizeSdk();
    dispatch(
      setIsMobile(window.matchMedia('screen and (max-width: 575px)').matches)
    );
    window.addEventListener('scroll', scrollEvent);
    window.addEventListener('resize', dimensionUpdate);
    dispatch(showHorizontalSection());

    return () => {
      window.removeEventListener('scroll', scrollEvent);
      window.removeEventListener('resize', dimensionUpdate);
    };
  }, [listSectionsRef.current, displaySectionsNavBar]);

  useEffect(() => {
    dispatch(showHorizontalSection());
  }, [sectionOrientation]);

  useEffect(() => {
    if (
      overlayCover !== BLOCK_CONTENT &&
      !customerAddress &&
      googleMapsEnabled &&
      addressIsLoaded &&
      !isUserAnonymous &&
      !executed &&
      !isSplash() &&
      !connexionModal
    ) {
      setExecuted(true);
      dispatch(showAddressModal(true));
    } else if (
      !addressIsLoaded ||
      customerAddressCheck === CUSTOMER_ADDRESS_CHECK_VALUES.VALID
    ) {
      dispatch(showAddressModal(false));
    }
  }, [
    overlayCover,
    customerAddress,
    googleMapsEnabled,
    addressIsLoaded,
    isUserAnonymous,
    executed,
    connexionModal,
    addressIsLoaded,
    customerAddressCheck,
  ]);

  useEffect(() => {
    if (!customerAddress && addressIsLoaded && !shopIsLoaded) {
      dispatch(updateShopIsLoaded(true));
    }
  }, [customerAddress, addressIsLoaded, shopIsLoaded]);

  useEffect(() => {
    if (shopIsLoaded && customerAddress && isStringNotNull(closed)) {
      dispatch(showMessageModal(getClosedMessage(closed)));
    }
  }, [shopIsLoaded, customerAddress, closed]);

  useEffect(() => {
    if (!_.isEmpty(commande)) {
      removeItemFromCommande({
        orderType,
        prevOrderType: prevOrderType.current,
        commande,
        shopId,
        shopIsLoaded,
        networkFees,
        sections,
        categories,
        userConnected,
        isUserAnonymous,
        customerId,
      });
    }
    prevOrderType.current = orderType;
  }, [
    orderType,
    prevOrderType.current,
    commande,
    shopId,
    shopIsLoaded,
    networkFees,
    sections,
    categories,
    userConnected,
    isUserAnonymous,
    customerId,
  ]);

  const onTouchStart = () => {
    document.body.style.overflow = 'hidden';
  };

  const onTouchEnd = () => {
    document.body.style.overflow = 'visible';
  };

  return showSplash ? (
    <Splash />
  ) : (
    <>
      {!displayOverlay && (
        <div>
          <div
            id='top'
            style={{ position: 'absolute', top: '0px', left: '0px' }}
          />
          {!isUserAnonymous && <Cover />}
          <div
            className='container-fluid px-0'
            id='main-container'
          >
            {isHorizontalSection ? (
              <div ref={listSectionsRef}>
                <div
                  id='info-bar'
                  className={`shadow ${displayNavBar ? 'fixed-top' : ''}`}
                  onTouchStart={onTouchStart}
                  onTouchEnd={onTouchEnd}
                >
                  {shopIsLoaded && <CloseBar />}
                  <InfoBar />
                  <ListSection />
                </div>
              </div>
            ) : (
              <div ref={listSectionsRef}>
                <div
                  id='info-bar'
                  className={`shadow ${
                    displaySectionsNavBar || isUserAnonymous ? 'fixed-top' : ''
                  }`}
                >
                  {shopIsLoaded && <CloseBar />}
                  <InfoBar />
                </div>
              </div>
            )}
            <div className={`col-12 px-0 px-sm-5 `}>
              <div className={`row px-3 px-sm-0 px-md-3`}>
                {!isHorizontalSection && (
                  <div
                    className={listSectionClass}
                    style={{
                      marginTop:
                        displaySectionsNavBar || isUserAnonymous
                          ? navbarHeight
                          : '16px',
                    }}
                  >
                    <ListSection />
                  </div>
                )}
                <div
                  className={listProductClass}
                  style={{
                    marginTop:
                      displaySectionsNavBar || isUserAnonymous
                        ? navbarHeight
                        : '16px',
                  }}
                >
                  <ListProduct />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
