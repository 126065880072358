import _ from 'lodash'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import {
    COOKIE_ADDRESS,
    COOKIE_ORDER_TYPE,
    COOKIE_SHOP_ID,
    DISTRICT,
    OPEN_TO_ALL_CITIES,
    ORDER_TYPE_DELIVERY,
    STREET,
    STREET_NUMBER,
    SUBLOCALITY,
} from '../constants'
import { UPDATE_PENDING_ORDER, UPDATE_SHOPS } from '../redux/actions/types'
import { containsCircle, containsPolygon, getDistance } from './map'
import store from '../redux/store'
import { isShopUnavailable } from '../redux/actions'
import { getDateToday, getTimezone, isSameDay } from './date'
import { sendCloudWatchAlert, sendCloudWatchLogs } from './logs'
import { isQrCode } from './config'
import { isStringNotNull } from './dataType'
import apiService from '@dishopsaas/dishop-backend-api-service'
import { getProductFees, priceWithPercentage } from './product'
import { getFirebaseCustomerId } from '../api/firebase/firebaseAuthentication';
import { Cookies } from 'react-cookie';

const getCurrentShopDay = date => {
    const { openHours } = store.getState().shopReducer as any;
    let shopDay = false;
    if (openHours) {
        const timezone = getTimezone();
        const day: any = getDateToday(date, timezone);
        shopDay = openHours[day];
    }
    return shopDay;
};

const findSlot = (time, slots, timezone) => {
    const formatTime = 'MM-DD-YYYY HH:mm';
    const formatDayTime = 'MM-DD-YYYY';
    const day = momentTimezone(time)
        .tz(timezone)
        .format(formatDayTime);
    let shopSlot: boolean | { slot: any; index: any } = false;
    _.find(slots, (slot, index) => {
        moment.tz.setDefault(timezone);
        const beforeTime = moment(`${day} ${slot.split('-')[0]}`, formatTime);
        const afterTime = moment(`${day} ${slot.split('-')[1]}`, formatTime);
        if (slot.split('-')[1] === '00:00') {
            afterTime.add(1, 'days');
        }
        if (time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime)) {
            shopSlot = { slot, index };
            return true;
        }
    });
    return shopSlot;
};

export const getClosedMessage = closed => {
    const { shopName } = store.getState().shopReducer as any;
    if (typeof closed === 'string') {
        return closed;
    }
    return `${shopName} est exceptionnellement fermé pour le moment.`;
};

export const sortSlots = (slots, day) => {
    return _.sortBy(slots, slot => {
        const stringSlots = slot.split('-');
        return moment(`${day.format('YYYY-MM-DD')}T${stringSlots[0]}:00`);
    });
};

export const getCurrentShopSlot = (date, orderType) => {
    let shopSlot = false;
    let isSpecialHours = false;
    const { openHours } = store.getState().shopReducer as any;
    const timezone = getTimezone();
    const time = moment(date);
    const specialHour = _.find((specialHours, specialHour: any) => {
        const { day } = specialHour;
        return isSameDay(day, date);
    });
    if (specialHour) {
        const { slots } = specialHour;
        shopSlot = findSlot(time, slots, timezone);
        isSpecialHours = true;
    } else if (openHours) {
        const shopDay: any = getCurrentShopDay(date);
        if (shopDay) {
            if (orderType === ORDER_TYPE_DELIVERY && shopDay?.slotsDelivery) {
                shopSlot = findSlot(time, shopDay?.slotsDelivery, timezone);
            } else {
                shopSlot = findSlot(time, shopDay?.slots, timezone);
            }
        }
    }
    return { slot: shopSlot, isSpecialHours };
};

export const checkAndGetZoneData = (address, deliveryZones, openCities) => {
    const { city, district, street, subLocality, location } = address;
    let zoneData: any = null;
    let excludedZonesData = null;
    const sortedDeliveryZones = _.orderBy(deliveryZones, ['priority']).reverse();
    const zone = _.find(sortedDeliveryZones, deliveryZone => {
        const { shape, data } = deliveryZone;
        let containsZone = false;
        if (shape === 'circle') {
            containsZone = containsCircle(location, data, data.radius);
        } else {
            containsZone = containsPolygon(location, data);
        }
        return containsZone;
    });
    if (zone) {
        zoneData = {
            userServicePrice: zone.deliveryFees,
            minimum: zone.minimum
        };
    }
    if (openCities && !zone) {
        const openCity = _.find(openCities, cityTemp => {
            return (
                city.toLowerCase() === cityTemp.name.toLowerCase() || cityTemp.name === OPEN_TO_ALL_CITIES
            );
        });
        if (openCity) {
            const { userServicePrice, minimum, excludedZones } = openCity;
            zoneData = {
                userServicePrice,
                minimum
            };
            excludedZonesData = excludedZones;
            if (isStringNotNull(subLocality) && openCity.subLocalities) {
                const openSubLocality = _.find(openCity.subLocalities, subLocalityTemp => {
                    return subLocality.toLowerCase() === subLocalityTemp.name.toLowerCase();
                });
                if (openSubLocality) {
                    const { userServicePrice, minimum } = openSubLocality;
                    zoneData = {
                        userServicePrice,
                        minimum
                    };
                }
            }
            if (district && openCity.districts) {
                const openDistrict = _.find(openCity.districts, districtTemp => {
                    return district.toLowerCase() === districtTemp.name.toLowerCase();
                });
                if (openDistrict) {
                    const { userServicePrice, minimum } = openDistrict;
                    zoneData = {
                        userServicePrice,
                        minimum
                    };
                    if (openDistrict.streets) {
                        const openStreet = _.find(openDistrict.streets, streetTemp => {
                            return street.toLowerCase() === streetTemp.name.toLowerCase();
                        });
                        if (openStreet) {
                            const { userServicePrice, minimum } = openStreet;
                            zoneData = {
                                userServicePrice,
                                minimum
                            };
                        }
                    }
                }
            }
        }
    }
    return { excludedZonesData, zoneData };
};

export const checkIfZoneExcluded = (address: any, excludedZones: any) => {
    return _.find(excludedZones, excludedZone => {
        switch (excludedZone.type) {
            case STREET:
                if (address.street.toLowerCase() === excludedZone.name.toLowerCase()) return true;
                break;
            case STREET_NUMBER:
                if (
                    `${address.streetNumber} ${address.street}`.toLowerCase() ===
                    excludedZone.name.toLowerCase()
                ) return true;
                break;
            case DISTRICT:
                if (
                    address.district &&
                    address.district.toLowerCase() === excludedZone.name.toLowerCase()
                ) return true;
                break;
            case SUBLOCALITY:
                if (
                    address.sublocality &&
                    address.subLocality.toLowerCase() === excludedZone.name.toLowerCase()
                ) return true;
                break;
            default:
                break;
        }
    });
};

export const parseSections = (sections: any, orderType?: string) => {
    if (!sections) {
        return {};
    }
    try {
        let productFees = getProductFees(orderType);
        let newSections: any[] = [];
        _.map(sections, (value, key) => {
            const section = value;
            let minimumPrice = 100;
            if (section) {
                if (section.productFees) {
                    productFees = section.productFees;
                }
                _.map(section?.products, product => {
                    const price = product?.price;
                    const categories = product?.categories;
                    if (productFees && parseFloat(price) > 0) {
                        product.price = priceWithPercentage(price, productFees);
                    }
                    if (product?.categories) {
                        let i = 0;
                        const categoriesArray = categories.trim().split(',');
                        const newCategories: string[] = [];
                        _.map(categoriesArray, category => {
                            newCategories.push(`${category}@${i}`);
                            i += 1;
                        });
                        product.categories = newCategories.toString();
                    }

                    if (minimumPrice > parseFloat(product?.price)) {
                        minimumPrice = parseFloat(product?.price);
                    }
                });
                section.key = key;
                section.minimumPrice = minimumPrice;
            }
        });
        newSections = _.sortBy(sections, section => {
            return parseInt(section?.position, 10);
        });
        newSections = newSections.filter(section => {
            return section;
        });
        return newSections;
    } catch (error) {
        sendCloudWatchAlert(`Error parsing section ${error}`);
    }
};

export const parseCategories = (categories: any, orderType?: string) => {
    if (!categories) {
        return {};
    }
    let productFees = getProductFees(orderType);
    _.map(categories, category => {
        if (category?.productFees) {
            productFees = category.productFees;
        }
        _.map(category?.items, item => {
            if (productFees && parseFloat(item.price) > 0) {
                item.price = priceWithPercentage(item.price, productFees);
            }
            if (item && item.subcategories) {
                let i = 0;
                const categoriesArray = item.subcategories.trim().split(',');
                const newCategories: any[] = [];
                _.map(categoriesArray, category => {
                    newCategories.push(`${category}@${i}`);
                    i += 1;
                });
                item.subcategories = newCategories.toString();
            }
        });
    });
    return categories;
};

export const getShopData = (shop: any, zoneData: any) => {
    const newShop = shop;
    const { managementFee, admins, cancelFee = { type: '%', value: 100 } } = shop;
    let minimum = 0;
    let userServicePriceInfo = {
        userServicePrice: 0,
        userCancelServicePrice: cancelFee,
        managementFee: isQrCode() ? 0 : managementFee
    };
    if (zoneData) {
        minimum = zoneData.minimum;
        let { userServicePrice } = zoneData;
        userServicePrice = _.sortBy(userServicePrice, ['priceOrder']);
        _.map(userServicePrice, (value, key) => {
            if (userServicePrice[key + 1]) {
                userServicePrice[key].nextPriceOrder = userServicePrice[key + 1].priceOrder;
                userServicePrice[key].nextFees = userServicePrice[key + 1].fees;
            }
        });
        userServicePriceInfo = {
            userServicePrice,
            userCancelServicePrice: cancelFee,
            managementFee: isQrCode() ? 0 : managementFee
        };
    }
    newShop.userServicePriceInfo = userServicePriceInfo;
    const newAdmins: any[] = [];
    _.map(admins, admin => {
        newAdmins.push(admin);
    });
    newShop.admins = newAdmins;
    newShop.minimum = minimum;
    return newShop;
};

const findAvailableOpenShopId = (shops: Record<string, any>) => {
    let firstShopId = null;
    for (const [sId, shop] of Object.entries(shops)) {
        if (!firstShopId) {
            firstShopId = sId;
        }
        if (!shop?.closed && shop?.configuration.orderTypes?.length) {
            return sId;
        }
    }
    return firstShopId
};

export const getShopsData = async () => {
    try {
        const shops = await apiService.shopsGetAll();
        store.dispatch({ type: UPDATE_SHOPS, payload: shops })
        return shops
    } catch (error) {
        await sendCloudWatchAlert(`Could not fetch shops ${error}`)
        throw error;
    }
};

export const getShopIdFromLink = () => {
    return window.location.pathname.split('/')[2];
  };

export const getFirstShopId = async (): Promise<string | undefined> => {
    const customerId = getFirebaseCustomerId();
    const shops = store.getState().shopReducer?.shops || (await getShopsData());
    const pendingOrder = customerId
        ? await apiService.pendingOrdersGetOne([customerId])
        : {
            address: new Cookies().get(COOKIE_ADDRESS),
            shopId: new Cookies().get(COOKIE_SHOP_ID),
            orderType: new Cookies().get(COOKIE_ORDER_TYPE)
        }
    const pendingOrderPayload: any = { address: pendingOrder?.address };
    if (pendingOrder?.shopId) {
        const pendingOrderAvailableOrderTypes = _.find(shops, (shop, shopId) => shopId === pendingOrder.shopId && !shop?.closed)?.orderTypes;
        const isPendingOrderShopIdIsAvailable = pendingOrderAvailableOrderTypes?.includes(
            pendingOrder?.orderType
        );
        if (isPendingOrderShopIdIsAvailable) {
            pendingOrderPayload.orderType = pendingOrder?.orderType;
            pendingOrderPayload.shopId = pendingOrder?.shopId;
        }
    }
    store.dispatch({ type: UPDATE_PENDING_ORDER, payload: pendingOrderPayload });
    return pendingOrder?.shopId || (isQrCode() ? getShopIdFromLink() : findAvailableOpenShopId(shops));
};

export const isShopValid = (shop: any, customerAddress: any, orderType: any) => {
    const { openCities, configuration, hide, deliveryZones } = shop;
    const { orderTypes } = configuration;
    const { excludedZonesData, zoneData } = checkAndGetZoneData(
        customerAddress,
        deliveryZones,
        openCities
    );
    const excludedZone = checkIfZoneExcluded(customerAddress, excludedZonesData);
    return !!(
        (((!excludedZone && zoneData) || orderType !== ORDER_TYPE_DELIVERY) &&
            !hide &&
            (!orderTypes || orderTypes?.includes(orderType))) ||
        isQrCode()
    );
};

export const getClosestShops = async (customerAddress: any, orderType: any) => {
    try {
        let closestShops: any[] = [];
        const { shops } = store.getState().shopReducer;
        if (customerAddress) {
            const { location } = customerAddress;
            _.map(shops, (shop: any, key) => {
                const { openCities, address, deliveryZones } = shop;
                const { latitude, longitude } = address.location;
                const distance = getDistance(location.latitude, location.longitude, latitude, longitude);
                if (isShopValid(shop, customerAddress, orderType) && !shop?.closed) {
                    const { zoneData } = checkAndGetZoneData(customerAddress, deliveryZones, openCities);
                    const newShop: any = getShopData(shop, zoneData);
                    newShop.distance = distance;
                    newShop.shopId = key;
                    closestShops.push(newShop);
                }
                closestShops = _.sortBy(closestShops, ['distance']);
                closestShops = closestShops.slice(0, 5);

            });
        } else {
            _.map(shops, (shop: any, key) => {
                try {
                    const { configuration, hide } = shop as any;
                    const { orderTypes } = configuration;
                    shop.shopId = key;
                    delete shop.distance;
                    if (!hide) {
                        if (orderType && (!orderTypes || (orderTypes?.includes(orderType)))) {
                            closestShops.push(shop);
                        } else if (!orderType) {
                            closestShops.push(shop);
                        }
                    }
                } catch (error) {
                    sendCloudWatchLogs(`Error Getting shop ${error}`);
                }
            });
        }
        return closestShops;
    } catch (error) {
        sendCloudWatchAlert(`Error getting closest shops ${error}`);
        throw error;
    }
};

export const getShopAndZoneData = (
    shopId: string,
    shop: any,
    customerAddress: any,
    orderType: string
) => {
    if (!shop) {
        return null;
    }
    if (customerAddress) {
        if (!isShopValid(shop, customerAddress, orderType || shop?.configuration.orderTypes[0])) {
            return null;
        }
        const { zoneData } = checkAndGetZoneData(customerAddress, shop.deliveryZones, shop.openCities);
        shop = getShopData(shop, zoneData);
    } else {
        const { sections, categories } = shop;
        shop.sections = parseSections(sections);
        shop.categories = parseCategories(categories);
    }
    shop.shopId = shopId;
    return shop;
};

export const getShopFromOrder = order => {
    const { shops } = store.getState().shopReducer;
    const { shopId } = order;
    let { shop } = order;
    if (!shop) {
        shop = _.find(shops, (shop, key) => {
            return shop && key === shopId;
        });
    }
    return shop || {};
};

export const checkIfShopAvailable = (customerAddress: any, shopId: any, closestShops: any) => {
    let shopUnavailable: any = false;
    if (closestShops?.length) {
        const shopExists = closestShops?.find((shop: any) => shop?.shopId === shopId);
        if (!shopExists) {
            shopUnavailable =
                customerAddress && customerAddress?.orderType === ORDER_TYPE_DELIVERY
                    ? 'Ce magasin ne livre pas à votre adresse'
                    : 'Service indisponible';
        }
    }
    store.dispatch(isShopUnavailable(shopUnavailable));
    return shopUnavailable;
};