import React from 'react';
import {
  IMAGE_ASPECT_COVER,
  IMAGE_UNAVAILABLE_IMAGE_URL,
  PRODUCT_STYLE_LIST,
} from '../../../../../../../constants';
import styles from '../../../Product.styles';
import { isStringNotNull } from '../../../../../../../utils';
import { useAppSelector } from '../../../../../../../redux/hook';

type ProductImageProps = {
  image: any;
  title: string;
};

export default function ProductImage({ image, title }: ProductImageProps) {
  const { productStyle, images } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { isHorizontalSection } = useAppSelector(
    (state) => state.componentReducer
  );
  const imageProductGridClass = isHorizontalSection
    ? 'image-product-grid-horizontal'
    : 'image-product-grid-vertical';
  const imageProductListClass = isHorizontalSection
    ? 'image-product-list-horizontal'
    : 'image-product-list-vertical';
  const imageUrl = !isStringNotNull(image)
    ? IMAGE_UNAVAILABLE_IMAGE_URL
    : image;
  const { aspect } = images;
  if (productStyle === PRODUCT_STYLE_LIST) {
    const imageProductClassname = `col-5 img-fluid float-left ${imageProductListClass} `;
    if (aspect === IMAGE_ASPECT_COVER) {
      return (
        <div
          className={imageProductClassname}
          style={{
            ...styles.productPictureBorder,
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      );
    }
    return (
      <img
        src={imageUrl}
        alt={title}
        className={imageProductClassname}
        style={styles.productPictureBorder}
      />
    );
  }
  const imageProductClassname = `img-fluid col-12 ${imageProductGridClass}`;
  if (aspect === IMAGE_ASPECT_COVER) {
    return (
      <div
        className={imageProductClassname}
        style={{
          ...styles.productPicture,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  }
  return (
    <img
      src={imageUrl}
      alt={title}
      className={imageProductClassname}
      style={styles.productPicture}
    />
  );
}
