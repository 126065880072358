import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styles from './Product.styles';
import { showAddressModal, showMessageModal } from '../../../../redux/actions';

import { UNIT } from '../../../../constants/configuration';

import {
  getNbInCartWithKey,
  isChatbot,
  isProductDisabled,
} from '../../../../utils';
import ProductModal from './components/ProductModal';
import { sendGoogleAnalytics } from '../../../../api';
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../../../redux/reducers/componentReducer';
import { isCustomerAddressDefined } from '../../../../utils/customer-address-and-shops';
import { getActiveOptions } from './Product.services';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import ProductCard from './components/ProductCard/ProductCard';

type ProductProps = {
  product: any;
  title: string;
  image: any;
  price: any;
  description: string;
  productKey: string;
  section: any;
  messagesSectionRange: any;
};

export default function Product({
  product,
  title,
  image,
  price,
  description,
  productKey,
  section,
  messagesSectionRange,
}: ProductProps) {
  const { name, key, position } = product;
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [cartNb, setCartNb] = useState(0);
  const [categories, setCategories] = useState([]);

  const { secondaryColor, multiOrder, generalDesign } = useAppSelector(
    (state) => state.configurationReducer
  );

  const { customerAddressCheck } = useAppSelector(
    (state) => state.componentReducer
  );
  const { orders } = useAppSelector((state) => state.orderReducer);
  const { commande, address: customerAddress } = useAppSelector(
    (state) => state.pendingOrderReducer
  );
  const { categories: shopCategories } = useAppSelector(
    (state) => state.shopReducer
  );

  useEffect(() => {
    if (!_.isEmpty(commande)) {
      const { key } = product;
      const cartNb = getNbInCartWithKey(key);
      setCartNb(cartNb);
    } else {
      setCartNb(0);
    }
  }, [commande]);

  const hideModal = () => {
    setShowModal(false);
    setCategories(
      product.categories
        ? getActiveOptions(product.categories, shopCategories)
        : []
    );
  };

  const productCardStyle = _.clone(styles.productCard);
  if (cartNb > 0) {
    productCardStyle['border'] = `2px solid ${secondaryColor}`;
  }
  const productDisabled =
    isProductDisabled(product) || messagesSectionRange?.[section.key];
  const hideProductName =
    section?.hideProductName || generalDesign?.hideProductsName?.products;

  useEffect(() => {
    setCategories(
      product.categories
        ? getActiveOptions(product.categories, shopCategories)
        : []
    );
  }, [product.categories, shopCategories]);

  return (
    <>
      <div
        className={`card text-center my-1 my-sm-3 productCard`}
        style={productCardStyle}
        onClick={() => {
          if (!productDisabled) {
            if (
              !isChatbot() &&
              (customerAddressCheck !== CUSTOMER_ADDRESS_CHECK_VALUES.VALID ||
                !isCustomerAddressDefined(customerAddress))
            ) {
              dispatch(showAddressModal(true));
            } else if (!_.isEmpty(orders) && !multiOrder) {
              dispatch(
                showMessageModal(
                  `Vous ne pouvez pas ajouter de produits car vous avez déjà ${
                    _.size(orders) > 1
                      ? `${_.size(orders)} commandes`
                      : 'une commande'
                  } en cours`
                )
              );
            } else {
              sendGoogleAnalytics('view_item', {
                items: [
                  {
                    id: key,
                    name,
                    price,
                    position,
                    category: section.name,
                  },
                ],
                currency: 'EUR',
                value: price,
              });
              setShowModal(true);
            }
          }
        }}
      >
        {cartNb > 0 && (
          <div
            style={{
              position: 'absolute',
              height: '40px',
              width: '40px',
              right: -10,
              top: -10,
              zIndex: 1000,
              borderRadius: UNIT * 2,
              backgroundColor: secondaryColor,
            }}
          >
            <div
              className='text-white d-flex align-items-center justify-content-center'
              style={{
                height: '100%',
                fontSize: UNIT * 1.25,
              }}
            >
              {cartNb}
            </div>
          </div>
        )}

        {productDisabled && (
          <div
            className='overlay'
            style={{ borderRadius: UNIT }}
          >
            <div className='text-overlay'>
              <h6 className='text-white disabledTitle mb-0 font-weight-bold'>
                Produit Indisponible
              </h6>
            </div>
          </div>
        )}
        <ProductCard
          title={title}
          description={description}
          image={image}
          product={product}
          hideProductName={hideProductName}
        />
      </div>
      {showModal && (
        <div className='mt-2 mt-sm-3'>
          <ProductModal
            product={product}
            title={product.name}
            image={product.image}
            price={product.price}
            description={product.description}
            categories={categories}
            sectionName={section.name}
            showModal={showModal}
            productKey={productKey}
            sectionKey={section.key}
            onHideModal={() => hideModal()}
          />
        </div>
      )}
    </>
  );
}
