import React from 'react';
import TextInputCounter from '../../TextInputCounter';
import { updateAddressAttribute } from '../../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';

export default function AskInstructions() {
  const dispatch = useAppDispatch();

  const {
    address: { instruction },
  } = useAppSelector((state) => state.pendingOrderReducer);

  return (
    <div className='row mb-2'>
      <div className='col'>
        <TextInputCounter
          className='form-control mb-2'
          placeholder='Instructions supplémentaires'
          style={{ resize: 'none' }}
          counterStyle={{ position: 'absolute', right: '5px', bottom: '10px' }}
          value={instruction}
          onChange={(e) => {
            dispatch(updateAddressAttribute('instruction', e.target.value));
          }}
        />
      </div>
    </div>
  );
}
