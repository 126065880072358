import _ from 'lodash';
import {
  getNbInCart,
  getProductId,
  removeUndefined,
  roundNumber,
  sendCloudWatchAlert,
} from '../../../../../../../../utils';
import apiService from '@dishopsaas/dishop-backend-api-service';
import { ITEM_KEY_COUNTER_SEPARATOR } from '../../../../../../../../constants';
import {
  addToCommande,
  showMessageModal,
} from '../../../../../../../../redux/actions';
import store from '../../../../../../../../redux/store';
import { getOptionsData } from '../../ProductModal.services';
import { sendGoogleAnalytics } from '../../../../../../../../api';

export const calculatePrice = (
  items: any,
  shopCategories: any,
  price: number | string
): number => {
  let itemsPrice = 0;
  _.map(items, (item) => {
    const optionFree = shopCategories[item?.categoryKey]?.optionFree;
    if (item) {
      const itemOptions = _.values(item.options);
      _.map(itemOptions, (option, index) => {
        if (!optionFree || index >= optionFree) {
          itemsPrice += parseFloat(option.price);
        }
      });
    }
  });
  const productPrice = parseFloat(price.toString());
  return roundNumber(productPrice + itemsPrice);
};

type AddToCartClickParams = {
  product: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mandatoryCategories: any;
  items: any;
  productCategories: any;
  shopCategories: any;
  sectionName: string;
  nb: number;
  sectionKey: any;
  productKey: any;
  instruction: any;
  unit: any;
  shopId: string;
  resetComponent: (notHide: boolean) => void;
};

export const addToCartClick = async ({
  product,
  setLoading,
  mandatoryCategories,
  items,
  productCategories,
  shopCategories,
  sectionName,
  nb,
  sectionKey,
  productKey,
  instruction,
  unit,
  shopId,
  resetComponent,
}: AddToCartClickParams): Promise<void> => {
  const { name, price, key, position } = product;
  const {
    userConnected,
    user: { customerId },
    isUserAnonymous,
  } = store.getState().userReducer;
  try {
    setLoading(true);

    for (let i = 0; i < mandatoryCategories.length; i += 1) {
      const key = mandatoryCategories[i];
      if (!items[key]) {
        const categoryId = productCategories[key];
        const categoryKey = categoryId.split(ITEM_KEY_COUNTER_SEPARATOR)[0];
        const category = shopCategories[categoryKey];
        if (category) {
          const errorMessage = `Vous devez sélectionner l'option : ${category.name}`;
          store.dispatch(showMessageModal(errorMessage));
          setLoading(false);
        }
        return;
      }
    }
    const productId = getProductId(productKey, items, instruction);
    let cartNb = getNbInCart(productId) + Number(nb);
    let productPrice = calculatePrice(items, shopCategories, price) * cartNb;
    if (unit) {
      cartNb = Number(nb);
      productPrice =
        (calculatePrice(items, shopCategories, price) * cartNb) /
        unit.conversion;
    }
    const newProduct = { ...product };
    newProduct.items = getOptionsData(items);
    newProduct.nb = cartNb;
    newProduct.unit = unit ? unit.name : null;
    newProduct.sectionName = sectionName;
    newProduct.sectionKey = sectionKey;
    newProduct.productKey = productKey;
    newProduct.unitPrice = parseFloat(product.price);
    newProduct.price = productPrice;
    newProduct.shopId = shopId;
    if (!newProduct.boughtPrice) {
      newProduct.boughtPrice = 0;
    }
    if (instruction) {
      newProduct.instruction = instruction;
    }
    if (userConnected || isUserAnonymous) {
      removeUndefined(newProduct.items);
      await apiService.pendingOrdersUpsert(
        [customerId, 'commande', productId],
        newProduct,
        { updateMode: 'replace' }
      );
    } else {
      store.dispatch(addToCommande(productId, newProduct));
    }
    setTimeout(() => {
      resetComponent(false);
    }, 1000);
    sendGoogleAnalytics('add_to_cart', {
      currency: 'EUR',
      items: [
        {
          id: key,
          name,
          price,
          position,
          category: sectionName,
          quantity: nb,
        },
      ],
      value: productPrice,
    });
  } catch (error) {
    sendCloudWatchAlert(
      `Error adding product ${key} with items ${JSON.stringify(
        items
      )} to commande ${error}`
    );
  }
};
