import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import SignInSignUpContainer from '../SignInSignUpContainer';
import {
  showConnexionModal,
  showSignUp,
  showForgetPassword,
  resetEmail,
  getSignUpData,
} from '../../redux/actions';
import { UNIT } from '../../constants/configuration';
import ModalBorder from '../ModalBorder';
import { useAppDispatch, useAppSelector } from '../../redux/hook';

export default function ConnexionModal() {
  const dispatch = useAppDispatch();

  const { userConnected } = useAppSelector((state) => state.userReducer);
  const { connexionModal, isMobile } = useAppSelector(
    (state) => state.componentReducer
  );

  const onClose = () => {
    dispatch(showConnexionModal(false));
    dispatch(showSignUp(false));
    dispatch(showForgetPassword(false));
    dispatch(resetEmail());
    dispatch(getSignUpData({}));
  };

  useEffect(() => {
    if (userConnected) {
      dispatch(showConnexionModal(false));
      dispatch(showSignUp(false));
      dispatch(resetEmail());
      dispatch(getSignUpData({}));
    }
  }, [userConnected, dispatch]);

  return (
    <Modal
      show={connexionModal}
      onHide={onClose}
      scrollable
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <ModalBorder
        connexionModal
        width={!isMobile && UNIT * 26.25}
        margin='0 auto'
      >
        <div
          className='row closeStyle'
          style={{
            right: isMobile ? `${UNIT * 0.25}%` : `${UNIT * 0.6875}%`,
          }}
        >
          <CloseButton
            onClick={onClose}
            closeStyle={{ right: UNIT * 0.5 }}
          />
        </div>
        <Modal.Body
          className='dark-modal'
          style={{ backgroundColor: 'transparent', borderRadius: UNIT * 2 }}
        >
          <div
            className='dishop-iso dark-modal'
            style={{ borderRadius: UNIT * 2 }}
          >
            <SignInSignUpContainer />
          </div>
        </Modal.Body>
      </ModalBorder>
    </Modal>
  );
}
