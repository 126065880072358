import React from 'react';
import Button from '../../Button';
import { BLACK, PRIMARY, UNIT } from '../../../constants';
import styles from '../AddressInput.styles';
import { ExpandLess, ExpandMore, NearMe, Store } from '@mui/icons-material';
import _ from 'lodash';

type ShopsDropdownListProps = {
  showShopsDropdownList: (
    isCutomerAddressSelected?: boolean,
    hasErrorOnAddress?: boolean,
    orderTypeSelected?: any
  ) => boolean;
  openShopsDropdownList: any;
  hasErrorAddressMessage: boolean;
  errorMessage: string;
  getShopsIsLoading: boolean;
  shopDropdowListText: string;
  handleShopsDropdownListClick: () => void;
  closestShops: any;
  secondaryColor: string;
  handleShopSelection: (shopId: string) => void;
};

export default function ShopsDropdownList({
  showShopsDropdownList,
  openShopsDropdownList,
  hasErrorAddressMessage,
  errorMessage,
  getShopsIsLoading,
  shopDropdowListText,
  handleShopsDropdownListClick,
  closestShops,
  secondaryColor,
  handleShopSelection,
}: ShopsDropdownListProps) {
  const toggleHover = (id: string) => {
    document.getElementById(id).style.backgroundColor = secondaryColor;
    document.getElementById(id).style.borderRadius = '0px';
  };
  const toggleLeave = (id: string) => {
    document.getElementById(id).style.backgroundColor = 'transparent';
  };

  return (
    showShopsDropdownList() && (
      <div>
        <Button
          type={PRIMARY}
          className={`btn dark-modal  ${
            !openShopsDropdownList &&
            !hasErrorAddressMessage &&
            errorMessage &&
            !getShopsIsLoading
              ? 'border-red'
              : 'border-color'
          } ${openShopsDropdownList ? 'borderMenu menuStyle' : 'borderStyle'}`}
          style={{
            border: !openShopsDropdownList && `1px solid ${BLACK}`,
            borderRadius: openShopsDropdownList && '0px',
            ...styles.menuStyle,
            ...styles.row,
          }}
          onClick={() => handleShopsDropdownListClick()}
        >
          <div style={styles.row as any}>
            <Store
              className=' label'
              style={styles.startIcon}
            />
            <p
              className='label'
              style={{
                ...styles.inputText,
                ...(shopDropdowListText?.includes('Recherche')
                  ? { color: '#6c757d' }
                  : {}),
              }}
            >
              {shopDropdowListText}
            </p>
          </div>
          <div>
            {!openShopsDropdownList ? (
              <ExpandMore className='label' />
            ) : (
              <ExpandLess className='label' />
            )}
          </div>
        </Button>
        {openShopsDropdownList !== null && (
          <ul
            className={`menu ${
              openShopsDropdownList ? 'animationOpen' : 'animationClose'
            }`}
            style={{
              marginBottom: 0,
              overflowY: closestShops?.length <= 2 && ('hidden' as any),
            }}
          >
            {_.keys(closestShops).map((key, i) => {
              const shop = closestShops[key];
              if (!shop) {
                return;
              }
              const { name, distance } = shop;
              return (
                <li
                  key={i}
                  className='menu-item'
                  onMouseEnter={() => toggleHover(name)}
                  onMouseLeave={() => toggleLeave(name)}
                >
                  <Button
                    id={name}
                    type={PRIMARY}
                    style={{
                      ...styles.row,
                      justifyContent: 'space-between',
                      color: `${BLACK}!important`,
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => handleShopSelection(shop?.shopId)}
                  >
                    <div style={{ ...(styles.row as any) }}>
                      <Store
                        className='label'
                        style={styles.startIcon}
                      />
                      <p
                        className='label'
                        style={{
                          ...(styles.truncText as any),
                          ...(styles.inputText as any),
                        }}
                      >
                        {name}
                      </p>
                    </div>
                    {distance >= 0 && (
                      <div
                        className=' lightcolor'
                        style={styles.row as any}
                      >
                        <NearMe
                          style={{
                            fontSize: 'medium',
                          }}
                          className='label'
                        />
                        <span
                          style={{ fontSize: UNIT * 0.875 }}
                          className='label'
                        >
                          {distance.toFixed(2)} km
                        </span>
                      </div>
                    )}
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    )
  );
}
