import React from 'react';
import { handleRadioButtonClick } from '../../../Category.services';
import {
  COLOR_LIGHT_GRAY,
  UNIT,
} from '../../../../../../../../../../../../../constants';
import OptionLabel from '../../OptionLabel';
import { Radio } from '@mui/material';
import { useAppSelector } from '../../../../../../../../../../../../../redux/hook';
import { isCheckedOrSelected } from '../../../../../../../../../../../ListProduct.services';

type RequiredItemProps = {
  productItems: any;
  index: any;
  item: any;
  uniqueId: any;
  productTitle: any;
  categoryId: any;
  items: any;
  optionContainerWebapp: any;
  notEnoughStock: any;
  itemKey: any;
  category: any;
  addUniqueItem: any;
  product: any;
  categoryKey: any;
};

export default function RequiredItem({
  productItems,
  index,
  item,
  uniqueId,
  productTitle,
  categoryId,
  items,
  optionContainerWebapp,
  notEnoughStock,
  itemKey,
  category,
  addUniqueItem,
  product,
  categoryKey,
}: RequiredItemProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );

  const isItemSelected = isCheckedOrSelected(productItems, index, item);
  const id = `${uniqueId}-${productTitle}-${categoryId}-${items[itemKey]}`;
  return (
    <div
      className={optionContainerWebapp}
      style={{ cursor: !notEnoughStock ? 'pointer' : 'auto' }}
      key={itemKey}
    >
      <div
        className={`h-100`}
        onClick={() => {
          if (!notEnoughStock) {
            handleRadioButtonClick({
              index,
              category,
              addUniqueItem,
              item,
            });
          }
        }}
      >
        <div className={`row h-100 d-flex align-items-center`}>
          <OptionLabel
            item={item}
            notAvailable={notEnoughStock}
            product={product}
            categoryKey={categoryKey}
          />
          <Radio
            checked={isItemSelected}
            id={id}
            value={itemKey}
            style={{
              paddingLeft: UNIT * 0.5,
              paddingRight: 0,
              color: notEnoughStock ? COLOR_LIGHT_GRAY : secondaryColor,
            }}
            disabled={notEnoughStock}
          />
        </div>
      </div>
    </div>
  );
}
