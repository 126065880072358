import React, { useState } from 'react';
import Order from '../../Order';
import { Fade } from 'react-awesome-reveal';
import { getAddressFormatted, isStringNotNull } from '../../../utils';
import { useAppSelector } from '../../../redux/hook';
import {
  ORDER_TYPE_DELIVERY,
  PAYMENT_TYPE_STRIPE,
  SECURE_PAYMENT_STRIPE_IMAGE_URL,
} from '../../../constants';
import AddPayment from '../../AddPayment';
import OrderTime from './OrderTime';
import { checkOrder, getPaymentTypes } from '../CartModal.services';
import AskApartment from './AskApartment';
import AskInstructions from './AskInstructions';
import AskPhone from './AskPhone';
import CreditCard from './CreditCard';
import PaymentType from './PaymentType';
import Spinner from '../../Spinner';
import { Stripe, StripeElement } from '@stripe/stripe-js';

type CartModalBodyProps = {
  choosePayment: boolean;
  stripe: Stripe;
  elements: StripeElement;
  card: any;
  selectTime: boolean;
  setIsValidDate: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isPhoneIncorrect: boolean;
  setChoosePayment: React.Dispatch<React.SetStateAction<boolean>>;
  sections: any;
  day: any;
  selectDay: boolean;
  setDay: React.Dispatch<React.SetStateAction<any>>;
  setHourSelectedValue: React.Dispatch<React.SetStateAction<number>>;
  setSelectDay: React.Dispatch<React.SetStateAction<boolean>>;
  isValidDate: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectTime: React.Dispatch<React.SetStateAction<boolean>>;
  hourSelectValue: number;
  setIsPhoneIncorrect: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

export default function CartModalBody({
  choosePayment,
  stripe,
  elements,
  card,
  selectTime,
  setIsValidDate,
  setLoading,
  isPhoneIncorrect,
  setChoosePayment,
  sections,
  day,
  selectDay,
  setDay,
  setHourSelectedValue,
  setSelectDay,
  isValidDate,
  setSelectTime,
  hourSelectValue,
  setIsPhoneIncorrect,
  loading,
}: CartModalBodyProps) {
  const paymentTypes = getPaymentTypes();
  const [phoneObj, setPhoneObj] = useState({});

  const { isUserAnonymous } = useAppSelector((state) => state.userReducer);

  const {
    webapp: { phrases },
  } = useAppSelector((state) => state.configurationReducer);

  const {
    commande,
    orderType,
    address: customerAddress,
  } = useAppSelector((state) => state.pendingOrderReducer);

  const handleCheckOrder = async (card: any, paymentType:string) =>
    await checkOrder({
      paymentType,
      card,
      commande,
      selectTime,
      setIsValidDate,
      setLoading,
      isPhoneIncorrect,
      setChoosePayment,
      sections,
      day,
      stripe,
      phoneObj,
    });

  return choosePayment ? (
    <Fade>
      <div className='container-fluid'>
        {phrases && isStringNotNull(phrases.cartPhrase) && (
          <div className='row mb-2'>
            <div className='col text-center'>
              <div
                className='alert alert-warning mb-0'
                role='alert'
              >
                <p className='text-break'>{phrases.cartPhrase}</p>
              </div>
            </div>
          </div>
        )}
        {isUserAnonymous && (
          <>
            <h5 className='text-center mb-3 text-secondary label'>
              Comment souhaitez-vous payer?
            </h5>
            {paymentTypes.includes(PAYMENT_TYPE_STRIPE) && (
              <div className='mb-2'>
                <AddPayment />
              </div>
            )}
          </>
        )}
        {!isUserAnonymous && (
          <>
            <OrderTime
              day={day}
              selectDay={selectDay}
              setDay={setDay}
              setIsValidDate={setIsValidDate}
              setHourSelectedValue={setHourSelectedValue}
              setSelectDay={setSelectDay}
              isValidDate={isValidDate}
              setSelectTime={setSelectTime}
              hourSelectValue={hourSelectValue}
              selectTime={selectTime}
            />
            {orderType === ORDER_TYPE_DELIVERY && (
              <div>
                <div className='row mb-2'>
                  <div className='col'>
                    <div>
                      <p className='text-muted mb-2 label'>Votre adresse</p>
                      <div className='input-group mb-1 no-localization'>
                        <input
                          type='text'
                          className='form-control dark-modal label'
                          placeholder={getAddressFormatted(customerAddress)}
                          aria-describedby='button-addon3'
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <AskApartment />
                <AskInstructions />
              </div>
            )}
            <AskPhone
              isPhoneIncorrect={isPhoneIncorrect}
              setIsPhoneIncorrect={setIsPhoneIncorrect}
              phoneObj={phoneObj}
              setPhoneObj={setPhoneObj}
            />
          </>
        )}

        <div className='row mb-2'>
          <div className='col'>{card && <CreditCard name={card.name} />}</div>
        </div>
        {loading ? (
          <div className='text-center mb-2'>
            <Spinner />
          </div>
        ) : (
          <PaymentType
            paymentTypes={paymentTypes}
            handleCheckOrder={handleCheckOrder}
            stripe={stripe}
            elements={elements}
            setLoading={setLoading}
            isUserAnonymous={isUserAnonymous}
          />
        )}
        <div className='row mb-2'>
          <div className='col'>
            <img
              src={SECURE_PAYMENT_STRIPE_IMAGE_URL}
              alt='stripe secure logo'
              className='img-fluid'
            />
          </div>
        </div>
      </div>
    </Fade>
  ) : (
    <Order
      order={commande}
      showCart
      showPromo
      deleteOption
      showFees
      fetchLoyalty
    />
  );
}
