/*
  Can't be converted to tsx due to type error
  Consider upgrading @types/react or react-bootstrap to see if fix the issue
*/

import React from 'react';
import _ from 'lodash';
import { showOrderModal, showOrderStatus } from '../../redux/actions';
import ModalBorder from '../ModalBorder';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { Modal } from 'react-bootstrap';
import OrderModalFooter from './components/OrderModalFooter';
import OrderModalBody from './components/OrderModalBody';

export default function OrderModal() {
  const dispatch = useAppDispatch();

  const { orders } = useAppSelector((state) => state.orderReducer);
  const { orderModal, orderStatus } = useAppSelector(
    (state) => state.componentReducer
  );

  return (
    <Modal
      onHide={() => {
        dispatch(showOrderModal(false));
        dispatch(showOrderStatus(false));
      }}
      scrollable
      show={orderModal && !_.isEmpty(orders)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <ModalBorder className='modal-size-fixed hide-scroll'>
        <Modal.Body className='dark-modal'>
          <OrderModalBody
            orderStatus={orderStatus}
            orders={orders}
          />
        </Modal.Body>
        <Modal.Footer className='modal-sticky-footer justify-content-center dark-modal'>
          <OrderModalFooter
            orderStatus={orderStatus}
            orders={orders}
          />
        </Modal.Footer>
      </ModalBorder>
    </Modal>
  );
}
