import _ from 'lodash';
import { generateCounterOptionKey } from '../../../../../../../../utils';
import {
  generateItemRecursiveKey,
  isCheckedOrSelected,
} from '../../../../../../ListProduct.services';
import {
  generateProductItem,
  handleRemovedItemSubCategories,
  sortProductItemsAccordingToSortedProductCategories,
  updateSubCategories,
} from '../../ProductModal.services';

export const addItem = async ({
  index,
  category,
  item,
  count,
  itemsCounter,
  items,
  shopCategories,
  productCategories,
  mandatoryCategories,
  linkBetweenOptionsAndSubOptions,
  setItems,
  setShopCategories,
  setProductCategories,
  setMandatoryCategories,
  setLinkBetweenOptionsAndSubOptions,
  setItemsCounter,
}) => {
  const updatedItemsCounter = itemsCounter ?? {};
  let updatedItems = items ?? {};
  let updatedShopCategories = shopCategories;
  let updatedProductCategories = productCategories;
  let updatedMandatoryCategories = mandatoryCategories;
  let updatedLinkBetweenOptionsAndSubOptions = linkBetweenOptionsAndSubOptions;
  let counter;
  if (count) {
    const optionKey = generateCounterOptionKey(category.key, item.key);
    counter = updatedItemsCounter[optionKey];
    counter = counter ? counter + 1 : 1;
    updatedItemsCounter[optionKey] = counter;
  }
  const itemRecursiveKey = generateItemRecursiveKey(item, counter);
  if (item?.subcategories?.length) {
    const subCategoryUpdateResult = updateSubCategories(
      updatedItems,
      updatedShopCategories,
      updatedProductCategories,
      updatedMandatoryCategories,
      updatedLinkBetweenOptionsAndSubOptions,
      item,
      itemRecursiveKey,
      category.multiple
    );
    updatedItems = subCategoryUpdateResult.items;
    updatedShopCategories = subCategoryUpdateResult.shopCategories;
    updatedProductCategories = subCategoryUpdateResult.productCategories;
    updatedMandatoryCategories = subCategoryUpdateResult.mandatoryCategories;
    updatedLinkBetweenOptionsAndSubOptions =
      subCategoryUpdateResult.linkBetweenOptionsAndSubOptions;
  }
  updatedItems[index] = generateProductItem(
    category,
    index,
    updatedItems,
    itemRecursiveKey,
    item
  );
  setItems(
    sortProductItemsAccordingToSortedProductCategories(
      updatedProductCategories,
      updatedItems
    )
  );
  setShopCategories(updatedShopCategories);
  setProductCategories(updatedProductCategories);
  setMandatoryCategories(updatedMandatoryCategories);
  setLinkBetweenOptionsAndSubOptions(updatedLinkBetweenOptionsAndSubOptions);
  if (counter) {
    setItemsCounter(updatedItemsCounter);
  }
};

export const addUniqueItem = ({
  index,
  category,
  item,
  itemsCounter,
  items,
  shopCategories,
  productCategories,
  mandatoryCategories,
  linkBetweenOptionsAndSubOptions,
  setItems,
  setShopCategories,
  setProductCategories,
  setMandatoryCategories,
  setLinkBetweenOptionsAndSubOptions,
  setItemsCounter,
}) => {
  const updatedItemsCounter = itemsCounter ?? {};
  let updatedItems = items ?? {};
  let updatedShopCategories = shopCategories;
  let updatedProductCategories = productCategories;
  let updatedMandatoryCategories = mandatoryCategories;
  let updatedLinkBetweenOptionsAndSubOptions = linkBetweenOptionsAndSubOptions;
  const itemRecursiveKey = generateItemRecursiveKey(item, undefined);
  const isSelected = isCheckedOrSelected(updatedItems, index, item);
  if (isSelected) {
    return;
  }
  const subCategoryUpdateResult = updateSubCategories(
    updatedItems,
    updatedShopCategories,
    updatedProductCategories,
    updatedMandatoryCategories,
    updatedLinkBetweenOptionsAndSubOptions,
    item,
    itemRecursiveKey,
    category.multiple
  );
  ({
    items: updatedItems,
    shopCategories: updatedShopCategories,
    productCategories: updatedProductCategories,
    mandatoryCategories: updatedMandatoryCategories,
    linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions,
  } = subCategoryUpdateResult);
  if (subCategoryUpdateResult?.productItemToRemove) {
    const itemsToRemove = subCategoryUpdateResult.productItemToRemove?.options
      ? Object.values(subCategoryUpdateResult.productItemToRemove?.options)
      : [];
    for (const item of itemsToRemove as any) {
      const result = handleRemovedItemSubCategories(
        item,
        updatedItems,
        updatedItemsCounter,
        item?.itemRecursiveKey,
        true,
        updatedLinkBetweenOptionsAndSubOptions,
        updatedShopCategories,
        updatedProductCategories
      );
      ({
        productCategories: updatedProductCategories,
        shopCategories: updatedShopCategories,
        mandatoryCategories: updatedMandatoryCategories,
        items: updatedItems,
      } = result);
    }
  }
  updatedItems[index] = generateProductItem(
    category,
    index,
    updatedItems,
    itemRecursiveKey,
    item
  );
  const reorderedProductItemsAccordingToProductCategories =
    sortProductItemsAccordingToSortedProductCategories(
      updatedProductCategories,
      updatedItems
    );

  setItems(reorderedProductItemsAccordingToProductCategories);
  setShopCategories(updatedShopCategories);
  setProductCategories(updatedProductCategories);
  setMandatoryCategories(updatedMandatoryCategories);
  setLinkBetweenOptionsAndSubOptions(updatedLinkBetweenOptionsAndSubOptions);
  setItemsCounter(updatedItemsCounter);
};

export const removeItem = ({
  index,
  category,
  item,
  count,
  itemsCounter,
  items,
  productCategories,
  shopCategories,
  mandatoryCategories,
  linkBetweenOptionsAndSubOptions,
  setItems,
  setShopCategories,
  setProductCategories,
  setMandatoryCategories,
  setItemsCounter,
}) => {
  const updatedItemsCounter = itemsCounter ?? {};
  let updatedItems = items ?? {};
  let updatedProductCategories = productCategories;
  let updatedShopCategories = shopCategories;
  let updatedMandatoryCategories = mandatoryCategories;

  const optionKey = generateCounterOptionKey(category.key, item.key);
  let counter = count ? updatedItemsCounter[optionKey] : null;
  const itemRecursiveKey = generateItemRecursiveKey(item, counter);
  const newProductItem = updatedItems[index];
  const result = handleRemovedItemSubCategories(
    item,
    updatedItems,
    updatedItemsCounter,
    itemRecursiveKey,
    false,
    linkBetweenOptionsAndSubOptions,
    shopCategories,
    productCategories
  );
  let updateItemsCounter = result?.shouldUpdateItemsCounter;
  updatedProductCategories = result.productCategories;
  updatedShopCategories = result.shopCategories;
  updatedMandatoryCategories = result.mandatoryCategories;
  updatedItems = result.items;

  if (counter) {
    counter -= 1;
    updatedItemsCounter[optionKey] = counter;
    updateItemsCounter = true;
  }
  // remove option item
  const itemGeneratedId = _.findKey(
    updatedItems.options,
    (option) =>
      itemRecursiveKey && option?.itemRecursiveKey === itemRecursiveKey
  );
  if (itemGeneratedId) {
    delete newProductItem?.options[itemGeneratedId];
    if (
      newProductItem?.options &&
      _.keys(newProductItem?.options).length === 0
    ) {
      // remove product item if no options
      delete updatedItems[index];
    } else {
      updatedItems[index] = newProductItem;
    }
  }
  const reorderedNewProductItems =
    sortProductItemsAccordingToSortedProductCategories(
      productCategories,
      updatedItems
    );

  setItems(reorderedNewProductItems);
  setShopCategories(updatedShopCategories);
  setProductCategories(updatedProductCategories);
  setMandatoryCategories(updatedMandatoryCategories);

  if (updateItemsCounter) {
    setItemsCounter(updatedItemsCounter);
  }
};
