export const ORDER_TYPE_DELIVERY = 'delivery';
export const ORDER_TYPE_CLICK_AND_COLLECT = 'click_and_collect';
export const ORDER_TYPE_CLICK_AND_SEAT = 'click_and_seat';
export const ORDER_TYPE_DELIVERY_LABEL = 'En livraison';
export const ORDER_TYPE_CLICK_AND_COLLECT_LABEL = 'À emporter';
export const ORDER_TYPE_CLICK_AND_SEAT_LABEL = 'Sur place';

// Payment type
export const PAYMENT_TYPE_STRIPE = 'stripe';
export const PAYMENT_TYPE_CB = 'cb';
export const PAYMENT_TYPE_CASH = 'cash';
export const PAYMENT_TYPE_TICKET_RESTO = 'ticket_resto';
export const PAYMENT_TYPE_COUNTER = 'counter';

export const STRIPE_MINIMUM_AMOUNT = '0.50';

export const PROMOTION_DELIVERY_FREE = 'delivery_free';
export const PROMOTION_PERCENTAGE = '%';
export const LOYALTY = 'FIDELITÉ';
export const LOYALTY_TYPE = {
  CASHBACK: 'cashback',
  PRODUCT: 'product'
}

export const ORDER_PHASE_CONTACTING = 'contacting';
export const ORDER_PHASE_PREPARING = 'preparing';
export const ORDER_PHASE_DELIVERING = 'delivering';
export const ORDER_PHASE_TAKEAWAY = 'takeaway';
export const ORDER_PHASE_WAITER = 'waiter';

export const ORDER_PHASE_CONTACTING_TIME = 5;
export const ORDER_PHASE_PREPARING_TIME = 15;
export const ORDER_PHASE_DELIVERING_TIME = 15;

export const CUSTOMER_CANCEL_PHASE_CONTACTING = 'customer_cancel_phase_contacting';
export const CUSTOMER_CANCEL_PHASE_PREPARING = 'customer_cancel_phase_preparing';
export const CUSTOMER_CANCEL_PHASE_DELIVERING = 'customer_cancel_phase_delivering';
export const CUSTOMER_CANCEL_PHASE_TAKEAWAY = 'customer_cancel_phase_takeaway';
export const CANCEL_CLIENT_UNAVAILABLE = 'client_unavailable';
export const SHOP_CANCEL_PHASE_CONTACTING = 'shop_cancel_phase_contacting';
export const SHOP_CANCEL_AND_REFUND = 'shop_cancel_and_refund';
export const CUSTOMER_CANCEL = 'customer';
export const SHOP_CANCEL = 'shop';
export const DRIVER_CANCEL = 'driver';
export const ORDER_PHASE = {
  CONTACTING: 'contacting',
  PREPARING: 'preparing',
  DELIVERING: 'delivering',
  TAKEAWAY: 'takeaway',
  WAITER: 'waiter'
};
export const ORDER_FLOW_TYPE = {
  REVERSE: 'reverse',
  CLASSIC: 'classic',
};

export enum PICKUP_STATUS {
    DRIVER_ON_THE_WAY_TO_CUSTOMER = 3,
    DRIVER_NEAR_TO_RESTAURANT = 2,
    DRIVER_ON_THE_WAY_TO_RESTAURANT = 1,
    ORDER_NOT_CURRENTLY_SUPPORTED = 0
}
export const MONTHS = {
  January: 'Janvier',
  February: 'Fevrier',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre'
};

export enum MODIFY_ORDER_TYPE_PROMOTION {
  REFUND = 'RF',
  CLICK_AND_COLLECT = 'CNC'
}