import React from 'react';
import Button from '../../../../../../Button';
import { PRIMARY, SECONDARY } from '../../../../../../../constants';
import { useAppDispatch } from '../../../../../../../redux/hook';
import { showCartModal } from '../../../../../../../redux/actions';
import CategorySteps from './CategorySteps';
import AddToCartButton from './AddToCartButton.tsx/AddToCartButton.view';

type ProductModalFooterProps = {
  items: any;
  shopCategories: any;
  price: any;
  nb: number;
  unit: any;
  product: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mandatoryCategories: any;
  productCategories: any;
  productKey: any;
  instruction: any;
  sectionName: any;
  sectionKey: any;
  resetComponent: any;
  stepOptionIndex: any;
  loading: boolean;
  setStepOptionIndex: React.Dispatch<React.SetStateAction<number>>;
  setShowOptionScrolButton: React.Dispatch<React.SetStateAction<boolean>>;
  showOptionScrollButton: boolean;
  productAdded: any;
};

export default function ProductModalFooter({
  productAdded,
  items,
  shopCategories,
  price,
  nb,
  unit,
  product,
  setLoading,
  mandatoryCategories,
  productCategories,
  productKey,
  instruction,
  sectionName,
  sectionKey,
  resetComponent,
  stepOptionIndex,
  loading,
  setStepOptionIndex,
  setShowOptionScrolButton,
  showOptionScrollButton,
}: ProductModalFooterProps) {
  const dispatch = useAppDispatch();
  const categoriesLength = productCategories.length + 1;
  if (productAdded) {
    return (
      <>
        <Button
          type={PRIMARY}
          onClick={() => resetComponent(false)}
        >
          Continuer mes achats
        </Button>
        <Button
          type={SECONDARY}
          onClick={() => {
            resetComponent(false);
            dispatch(showCartModal(true));
          }}
        >
          Accéder au panier
        </Button>
      </>
    );
  }
  return categoriesLength > 1 ? (
    <CategorySteps
      productCategories={productCategories}
      stepOptionIndex={stepOptionIndex}
      setStepOptionIndex={setStepOptionIndex}
      setShowOptionScrolButton={setShowOptionScrolButton}
      items={items}
      mandatoryCategories={mandatoryCategories}
      shopCategories={shopCategories}
      showOptionScrollButton={showOptionScrollButton}
    />
  ) : (
    <AddToCartButton
      items={items}
      shopCategories={shopCategories}
      price={price}
      nb={nb}
      unit={unit}
      product={product}
      setLoading={setLoading}
      mandatoryCategories={mandatoryCategories}
      productCategories={productCategories}
      productKey={productKey}
      instruction={instruction}
      sectionName={sectionName}
      sectionKey={sectionKey}
      resetComponent={resetComponent}
      stepOptionIndex={stepOptionIndex}
      loading={loading}
    />
  );
}
