import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { ScrollView, ScrollElement } from '../../ScrollVertical';
import '../../../css/react-perfect-scrollbar.css';
import Section from '../Section';
import { setSectionListKey } from './ListSections.services';
import { filterSections } from '../../../utils';

const Sections = sections => {
  return _.map(sections, (section, key) => {
    return (
      <div key={key}>
        <Section
          key={key}
          sectionIndex={key}
          title={section.name}
          image={section.iconUrl}
          sectionKey={section.key}
        />
      </div>
    );
  });
};
const ArrowLeft = <i className='fa fa-arrow-left text-color pl-2' />;
const ArrowRight = <i className='fa fa-arrow-right text-color pr-2' />;
let globalAnchor = window.location.hash.substr(1)

export default function ListSection () {

  const [selected, setSelected] = useState('0');
  const [scrollIndex, setScrollIndex] = useState(0);
  const { sections } = useSelector(state => state.shopReducer)
  const { navbar, isMobile, navbarHeight, isHorizontalSection } = useSelector(state => state.componentReducer)
  const { selectedSection } = useSelector(state => state.sectionReducer)
  const newSections = filterSections(sections);
  const menu = Sections(newSections);

  useEffect(() => {
    // scroll horizontal
    const anchor = window.location.hash.substr(1);
    if (globalAnchor === anchor) {
      setScrollIndex(selectedSection);
    } else {
      globalAnchor = anchor;
    }
    // scroll vertical
    setSectionListKey(setSelected, selectedSection);
  }, [selectedSection]);

  const containerClassname = navbar ? 'px-0 col-12' : '';
  return (
    <>
      {isHorizontalSection && (
        <>
          <div
            className={`${containerClassname} section-bar`}
            style={{
              paddingLeft: isMobile ? 10 : 0
            }}
          >
            <ScrollMenu
              data={menu}
              selected={String(selected)}
              arrowLeft={!isMobile ? ArrowLeft : null}
              arrowRight={!isMobile ? ArrowRight : null}
              scrollToSelected
              alignCenter={false}
              dragging={isMobile}
              wheel={false}
              transition={1}
              hideSingleArrow
              hideArrows
            />
          </div>
        </>
      )}
      {!isHorizontalSection && (
        <div
          className='sticky-top hide-scroll overflow-auto'
          style={{
            top: navbarHeight,
            height: window.innerHeight * 0.875
          }}
        >
          <ScrollView scrollToIndex={scrollIndex} className='hide-scroll'>
            {registration =>
              _.map(newSections, (section, key) => {
                if (section && !section.hidden) {
                  return (
                    <ScrollElement
                      sectionKey={section.key}
                      key={section.key}
                      registration={registration}
                    >
                      <Section
                        key={key}
                        sectionIndex={key}
                        title={section.name}
                        image={section.iconUrl}
                        sectionKey={section.key}
                      />
                    </ScrollElement>
                  );
                }
              })
            }
          </ScrollView>
        </div>
      )}
    </>
  );
}