import React from 'react';
import _ from 'lodash';
import {
  convertHoursToDays,
  displayPriceFormat,
  formatCategoryName,
} from '../../utils';
import styles from './OrderItem.styles';
import { UNIT } from '../../constants/configuration';
import { deleteItem, findOptionParent } from './OrderItem.services';
import { useAppSelector } from '../../redux/hook';
import OrderItemOption from './components/OrderItemOption';
import OrderItemInstruction from './components/OrderItemInstruction';

export default function OrderItem({ deleteOption, cartItem, cartItemKey }) {
  const { nb, unit = null, name, price, preparationTime } = cartItem;
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  const {
    userConnected,
    user: { customerId },
    isUserAnonymous,
  } = useAppSelector((state) => state.userReducer);

  const renderOptions = () => {
    const { items } = cartItem;
    if (items) {
      return _.map(items, (item) => {
        if (item) {
          const { options, categoryKey } = item;
          const optionLabels = _.map(options, 'name');
          const isChild = _.some(
            items,
            (childItem) =>
              childItem?.categoryKey !== categoryKey &&
              categoryKey.includes(childItem?.categoryKey)
          );
          const prefix = isChild ? '- ' : '* ';
          const fontSize = isChild ? UNIT * 0.85 : UNIT * 0.9;
          const className = isChild ? 'ml-4' : 'ml-2';
          let { categoryName } = item;
          if (options && isChild) {
            _.forEach(options, (option) => {
              const optionParent = findOptionParent(option, items);
              if (optionParent) {
                categoryName = formatCategoryName({
                  shopCategory: { name: item.categoryName },
                  parentItemLabel: optionParent.label,
                  parentItemRecursiveKey: optionParent.itemRecursiveKey,
                  isMultiple: optionParent.categoryMultiple,
                });
              }
            });
          }
          const itemRenderString = `${prefix}${categoryName}: ${optionLabels.join(
            ','
          )}`;
          return (
            <OrderItemOption
              className={className}
              fontSize={fontSize}
              itemRenderString={itemRenderString}
              categoryId={item.categoryId}
            />
          );
        }
      });
    }
  };

  return (
    <>
      <div>
        <div className={`row justify-content-between`}>
          {deleteOption && (
            <div className={`mr-2`}>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  deleteItem({
                    userConnected,
                    isUserAnonymous,
                    cartItem,
                    cartItemKey,
                    customerId,
                  });
                }}
              >
                <i
                  className='far fa-trash-alt'
                  style={{ color: secondaryColor }}
                />
              </a>
            </div>
          )}
          <div className='col-8'>
            {unit ? (
              <p style={{ ...styles.receiptProductItemTitleDefaultStyle }}>
                <span className='no-localization'>
                  {nb} {unit}
                </span>{' '}
                de {name}
              </p>
            ) : (
              <p style={{ ...styles.receiptProductItemTitleDefaultStyle }}>
                <span className='no-localization'>x {nb}</span> {name}
              </p>
            )}
          </div>
          <div className={`my-auto text-right col-3`}>
            {displayPriceFormat(price)}
          </div>
        </div>
        <div className={'pl-3 pl-sm-4'}>
          {renderOptions()}
          {cartItem.instruction && (
            <OrderItemInstruction instruction={cartItem.instruction} />
          )}
          {preparationTime > 0 && deleteOption && (
            <p
              className='ml-2 text-warning mb-0'
              style={{ fontSize: UNIT * 0.9 }}
            >
              {`~ ${
                convertHoursToDays(preparationTime) > 0
                  ? `${convertHoursToDays(preparationTime)} jour(s)`
                  : `${preparationTime} heure(s)`
              } de préparation`}
            </p>
          )}
        </div>
      </div>
      <hr className='my-4 hr' />
    </>
  );
}
