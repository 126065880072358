import _ from 'lodash'
import apiService from '@dishopsaas/dishop-backend-api-service'
import store from '../../redux/store'
import {
  MESSAGE_MODAL,
  SHOW_ORDER_MODAL,
  SHOW_ORDER_STATUS,
  UPDATE_CANCELED_ORDERS,
  UPDATE_DRIVERS_DATA,
  UPDATE_LAST_ORDER_CHARGE_IDS,
  UPDATE_ORDERS,
  UPDATE_PAST_ORDERS,
  UPDATE_PENDING_ORDER,
  UPDATE_SHOP,
  UPDATE_SHOP_IS_LOADED,
  UPDATE_SHOP_ORDERS
} from '../../redux/actions/types'
import {
  CANCEL_CLIENT_UNAVAILABLE,
  CUSTOMER_CANCEL,
  DRIVER_CANCEL,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  PAYMENT_TYPE_STRIPE,
  SHOP_CANCEL_AND_REFUND
} from '../../constants'
import { updateAddress } from '../../redux/actions'
import { checkIfCustomerAddressValid, getCustomerAddress } from '../../utils/customer-address-and-shops'
import { getFirebaseFleet, getFirebaseModule } from './firebaseInit'
import { getDatabase, ref, off, onValue } from 'firebase/database';
import { parseCategories, parseSections, setPendingOrderCookies } from '../../utils/index.js'

export const unlistenFirebase = (customerId) => {
  if (!customerId) {
    return;
  }

  const db = getDatabase(getFirebaseModule());

  off(ref(db, `/users/${customerId}`), 'value');
  off(ref(db, `/orders/${customerId}`), 'value');
  off(ref(db, `/pendingOrders/${customerId}`), 'value');
  off(ref(db, `/pastOrders/${customerId}`), 'value');
  off(ref(db, `/canceledOrder/${customerId}`), 'value');
};

export const listenFirebasePendingOrder = async (customerId) => {
  if (!customerId) return;

  const db = getDatabase(getFirebaseModule()); 
  const pendingOrderRef = ref(db, `/pendingOrders/${customerId}`);

  onValue(pendingOrderRef, (snapshot) => {
    const pendingOrder = snapshot.val(); 
    store.dispatch({
      type: UPDATE_PENDING_ORDER,
      payload: { ...pendingOrder, commande: pendingOrder?.commande || {} },
    });
  setPendingOrderCookies(!!pendingOrder?.address, pendingOrder?.address, pendingOrder?.shopId, pendingOrder?.orderType);
  checkIfCustomerAddressValid(pendingOrder?.address, pendingOrder?.orderType, pendingOrder?.shopId);
  });
};

export const listenOrders = (shopId) => {
  if (!shopId) return;

  const db = getDatabase(getFirebaseModule());
  const ordersRef = ref(db, '/orders');

  off(ordersRef, 'value');

  onValue(ordersRef, (snapshot) => {
    const ordersByCustomerId = snapshot.val();
    const ordersByShopId = [];
    _.map(ordersByCustomerId, (orders) => {
      _.map(orders, (order) => {
        if (order.shopId === shopId) {
          ordersByShopId.push(order);
        }
      });
    });
    store.dispatch({ type: UPDATE_SHOP_ORDERS, payload: ordersByShopId });
  });
};

export const listenLastOrderChargeId = async (customerId) => {
  if (!customerId) return;

  const db = getDatabase(getFirebaseModule());
  const lastOrderChargeIdsRef = ref(db, `/users/${customerId}/lastOrderChargeIds`);

  off(lastOrderChargeIdsRef, 'value');

  onValue(lastOrderChargeIdsRef, (snapshot) => {
    const lastOrderChargeIds = snapshot.val(); 

    store.dispatch({
      type: UPDATE_LAST_ORDER_CHARGE_IDS,
      payload: lastOrderChargeIds || {},
    });
  });
};

export const listendFirebaseOrderTables = async (customerId, updateUser, isUserAnonymous) => {
  if (!customerId) return;

  const db = getDatabase(getFirebaseModule());

  const userRef = ref(db, `/users/${customerId}`);
  onValue(userRef, async (snapshot) => {
    const customerProfile = snapshot.val() || {};
    const address = await getCustomerAddress(customerId);

    if (address) {
      store.dispatch(updateAddress(address));
    }

    store.dispatch(updateUser(customerProfile, customerId, isUserAnonymous));
  });

  const ordersRef = ref(db, `/orders/${customerId}`);
  onValue(ordersRef, (snapshot) => {
    const newOrders = snapshot.val();
    const { orders } = store.getState().orderReducer;

    if (newOrders) {
      _.map(newOrders, (newOrder) => {
        const { status, idDep, chargeId, delivery = {} } = newOrder;
        const order = _.find(orders, (order) => order.chargeId === chargeId);

        if (status === ORDER_PHASE_DELIVERING && idDep) {
          listenDriverData(idDep, chargeId, delivery.freelance);
        }

        if (order && status === ORDER_PHASE_PREPARING && order.status === ORDER_PHASE_DELIVERING) {
          unlistenDriverData(order.idDep, order.chargeId, delivery.freelance);
        }
      });
    }

    store.dispatch({ type: UPDATE_ORDERS, payload: newOrders });
  });
  const pastOrdersRef = ref(db, `/pastOrders/${customerId}`);
  onValue(pastOrdersRef, async (snapshot) => {
    const pastOrders = snapshot.val();

    if (!_.isEmpty(pastOrders)) {
      const { lastOrderChargeIds } = store.getState().userReducer;
      const { orders } = store.getState().orderReducer;
      const { orderStatus } = store.getState().componentReducer;

      const lastPastOrder = _.find(lastOrderChargeIds, (lastOrderChargeId) => pastOrders[lastOrderChargeId]);

      if (lastPastOrder) {
        const { orderNumber, chargeId, idDep, delivery = {} } = pastOrders[lastPastOrder];

        if (idDep) {
          unlistenDriverData(idDep, chargeId, delivery.freelance);
        }
        if (orderStatus === chargeId) {
          store.dispatch({ type: SHOW_ORDER_STATUS, payload: false });
        }
        if (_.isEmpty(orders)) {
          store.dispatch({ type: SHOW_ORDER_MODAL, payload: false });
        }
        store.dispatch({
          type: MESSAGE_MODAL,
          payload: {
            message: `Votre commande n°${orderNumber} est terminée. Merci pour votre fidélité et à très bientôt !`
          }
        });

        await apiService.usersDelete([customerId, 'lastOrderChargeIds', chargeId]);
      }

      store.dispatch({ type: UPDATE_PAST_ORDERS, payload: pastOrders });
    } else {
      store.dispatch({ type: UPDATE_PAST_ORDERS, payload: {} });
    }
  });
  const canceledOrdersRef = ref(db, `/canceledOrder/${customerId}`);
  onValue(canceledOrdersRef, async (snapshot) => {
    const canceledOrders = snapshot.val();

    if (!_.isEmpty(canceledOrders)) {
      const { lastOrderChargeIds } = store.getState().userReducer;
      const { support } = store.getState().shopReducer;
      const { orders } = store.getState().orderReducer;
      const { orderStatus } = store.getState().componentReducer;

      const lastCanceledOrder = _.find(lastOrderChargeIds, (lastOrderChargeId) => canceledOrders[lastOrderChargeId]);

      if (lastCanceledOrder) {
        const {
          cancellation,
          paymentType,
          userCancelServicePrice,
          orderNumber,
          chargeId,
          idDep,
          totalPriceOrder,
          delivery = {}
        } = canceledOrders[lastCanceledOrder];
        const { canceledBy, cancelReason } = cancellation;

        if (idDep) {
          await unlistenDriverData(idDep, chargeId, delivery.freelance);
        }

        let cancelMessage = `Le magasin n'a pas pu prendre votre commande n°${orderNumber}. Vous pouvez contacter le magasin ${
          support?.number ? `au ${support.number}` : ''
        } pour plus d'informations.`;

        if (cancelReason === CANCEL_CLIENT_UNAVAILABLE) {
          cancelMessage =
            canceledBy === DRIVER_CANCEL
              ? `Le livreur a annulé votre commande n°${orderNumber} car vous étiez injoignable.`
              : `Le magasin a annulé votre commande n°${orderNumber} car vous étiez injoignable.`;
          if (paymentType === PAYMENT_TYPE_STRIPE && userCancelServicePrice > 0) {
            cancelMessage += ` Vous avez été facturé d'un montant de ${userCancelServicePrice} €.`;
          }
        }
        if (cancelReason === SHOP_CANCEL_AND_REFUND) {
          cancelMessage = `Le magasin a remboursé votre commande n°${orderNumber}. Vous avez été remboursé d'un montant de ${totalPriceOrder} €.`;
        }

        if (canceledBy !== CUSTOMER_CANCEL) {
          store.dispatch({
            type: MESSAGE_MODAL,
            payload: { message: cancelMessage }
          });
        }

        if (orderStatus === chargeId) {
          store.dispatch({ type: SHOW_ORDER_STATUS, payload: false });
        }
        if (_.isEmpty(orders)) {
          store.dispatch({ type: SHOW_ORDER_MODAL, payload: false });
        }

        await apiService.usersDelete([customerId, 'lastOrderChargeIds', chargeId]);
      }
      store.dispatch({ type: UPDATE_CANCELED_ORDERS, payload: canceledOrders });
    }
  });
};

export const listenFirebaseUsersData = async (customerId, isUserAnonymous, updateUser) => {
  unlistenFirebase(customerId);
  if (!isUserAnonymous) {
    await listenLastOrderChargeId(customerId);
  }
  await listendFirebaseOrderTables(customerId, updateUser, isUserAnonymous);
  await listenFirebasePendingOrder(customerId);
  return true;
};

export const listenShopData = async (
  previousShopId,
  shopId,
  customerAddress,
  getShopAndZoneData,
  orderType
) => {
  const db = getDatabase(getFirebaseModule());
  if (previousShopId !== shopId) {
    store.dispatch({ type: UPDATE_SHOP_IS_LOADED, payload: false });
    off(ref(db, `/shops/${previousShopId}`)); 
    off(ref(db, `/menus/${previousShopId}`)); 
  }
  const shopRef = ref(db, `/shops/${shopId}`);
  onValue(shopRef, snapshot => {
    const firebaseShop = snapshot.val();
    const shop = getShopAndZoneData(
      shopId,
      firebaseShop,
      customerAddress || firebaseShop?.address,
      orderType
    );
    if (shop) {
      const menuRef = ref(db, `/menus/${shopId}`);
      onValue(menuRef, snapshot => {
        const shopMenus = snapshot.val();
        if (shopMenus) {
          shop.sections = parseSections(shopMenus?.sections, orderType);
          shop.categories = parseCategories(shopMenus?.categories, orderType);
          shop.shopId = shopId;
          store.dispatch({ type: UPDATE_SHOP, payload: { ...shop, shopIsLoaded: true } });
          const pendingOrder = store.getState()?.pendingOrderReducer;
          checkIfCustomerAddressValid(pendingOrder?.address, pendingOrder?.orderType, shopId);
        }
      });
    }
  });
  listenOrders(shopId); 
  return true;
};

export const listenDriverData = async (driverId, chargeId, driverFreelance) => {
  const database = driverFreelance ? getFirebaseFleet() : getFirebaseModule();
  const db = getDatabase(database);
  const driverRef = ref(db, `/drivers/${driverId}`);
  off(driverRef);
  onValue(driverRef, snapshot => {
    if (snapshot.exists()) {
      const { firstName, lastName, lat, long, numero } = snapshot.val();
      const driverData = {
        driverFirstName: firstName,
        driverLastName: lastName,
        driverLocation: {
          latitude: lat,
          longitude: long,
        },
        driverNumero: numero,
      };
      store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
    }
  });
};

export const unlistenDriverData = async (driverId, chargeId, driverFreelance) => {
  let firebase = getFirebaseModule();
  if (driverFreelance) {
    firebase = getFirebaseFleet();
  }
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .off();
  const driverData = null;
  store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
};
