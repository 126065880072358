import React from 'react';
import { IMAGE_ASPECT_COVER } from '../../../../constants';
import { useAppSelector } from '../../../../redux/hook';
import styles from '../Section.styles';

export default function SectionImage({ image, title }) {
  const { images } = useAppSelector((state) => state.configurationReducer);

  const { aspect } = images;
  const sectionImageClassName = 'mr-2 rounded-circle';

  return (
    <div className='align-self-center'>
      {aspect === IMAGE_ASPECT_COVER && (
        <div
          className={sectionImageClassName}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            ...styles.imageSection,
          }}
        />
      )}
      {aspect !== IMAGE_ASPECT_COVER && (
        <img
          alt={title}
          src={image}
          style={styles.imageSection}
          className={sectionImageClassName}
        />
      )}
    </div>
  );
}
