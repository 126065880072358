import React from 'react';
import { UNIT } from '../../../constants';

type OrderItemInstructionProps = {
  instruction: any;
};

export default function OrderItemInstruction({
  instruction,
}: OrderItemInstructionProps) {
  return (
    <div>
      <span
        className='ml-2'
        style={{ fontSize: UNIT * 0.9 }}
      >
        - Instructions : {instruction}
      </span>
    </div>
  );
}
