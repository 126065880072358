import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { BLACK, UNIT } from '../../../constants';
import { Check, LocationOnOutlined } from '@mui/icons-material';
import styles from '../AddressInput.styles';
import Spinner from '../../Spinner';

const searchOptions = {
  componentRestrictions: { country: ['fr', 'mc', 'gf'] },
};

type AutocompleteSuggestionType = {
  active: boolean;
  placeId: string;
  formattedSuggestion: { mainText: string; secondaryText: string };
};

type AddressSearchAutocompleteInputProps = {
  typedAddress: string;
  setTypedAddress: React.Dispatch<React.SetStateAction<string>>;
  handleAddressSelection: (
    selectedAddressValue: any,
    placeId: string
  ) => Promise<void>;
  handleAddressInputFormControlError: (
    status: any,
    clearSuggestions: any
  ) => void;
  hasErrorAddressMessage: boolean;
  getShopsIsLoading: boolean;
  showShopsDropdownList: (
    isCutomerAddressSelected?: boolean,
    hasErrorOnAddress?: boolean,
    orderTypeSelected?: any
  ) => boolean;
  inputElementRef: React.MutableRefObject<any>;
  animatePlaceholder: string;
  isFilledAddressValid: boolean;
};

export default function AddressSearchAutocompleteInput({
  typedAddress,
  setTypedAddress,
  handleAddressSelection,
  handleAddressInputFormControlError,
  hasErrorAddressMessage,
  getShopsIsLoading,
  showShopsDropdownList,
  inputElementRef,
  animatePlaceholder,
  isFilledAddressValid,
}: AddressSearchAutocompleteInputProps) {
  return (
    <PlacesAutocomplete
      value={typedAddress}
      onChange={(typedAddress: string) => setTypedAddress(typedAddress)}
      onSelect={(selectedAddress: any, placeId: string) =>
        handleAddressSelection(selectedAddress, placeId)
      }
      onError={(status: any, clearSuggestions: any) =>
        handleAddressInputFormControlError(status, clearSuggestions)
      }
      shouldFetchSuggestions={typedAddress?.length > 6}
      highlightFirstSuggestion
      searchOptions={searchOptions}
      debounce={400}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div
            className={`inputStyle ${
              hasErrorAddressMessage && !getShopsIsLoading && 'border-red'
            }`}
            style={{
              marginBottom: showShopsDropdownList() ? UNIT * 0.875 : 0,
            }}
          >
            <LocationOnOutlined
              className='fa-map-marker'
              style={{ ...styles.space }}
            />
            <input
              {...getInputProps({
                className: `form-control no-localization dark-modal`,
                ref: inputElementRef,
                placeholder: animatePlaceholder,
                style: styles.addressInput,
                'aria-describedby': 'button-addon3',
                type: 'text',
              })}
            />
            {isFilledAddressValid && (
              <Check
                style={styles.space}
                className='fa-check'
              />
            )}
          </div>
          <div
            className='autocomplete-dropdown-container'
            style={{ position: 'absolute', zIndex: 5000 }}
          >
            {loading && <Spinner />}
            {suggestions?.map((suggestion: AutocompleteSuggestionType) => {
              const className = suggestion?.active
                ? 'suggestion-item--active p-2'
                : 'suggestion-item p-2';
              const style = suggestion?.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    color: BLACK,
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    color: BLACK,
                  };
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <strong className='no-localization'>
                    {suggestion?.formattedSuggestion?.mainText}
                  </strong>{' '}
                  <small className='no-localization'>
                    {suggestion?.formattedSuggestion?.secondaryText}
                  </small>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
