import React from 'react';
import { generateOptionChoiceMessage } from './Category.services';
import styles from './Category.styles';
import { BLACK } from '../../../../../../../../../../constants';
import Items from './components/Items/Items';

type CategoryProps = {
  productTitle: any;
  product: any;
  nbProduct: any;
  category: any;
  categoryId: any;
  itemsCounter: any;
  productItems: any;
  categoryKey: any;
  index: any;
  addUniqueItem: any;
  addItem: any;
  removeItem: any;
  modalOptionContainer: any;
};

export default function Category({
  productTitle,
  product,
  nbProduct,
  category,
  categoryId,
  itemsCounter,
  productItems,
  categoryKey,
  index,
  addUniqueItem,
  addItem,
  removeItem,
  modalOptionContainer,
}: CategoryProps) {
  const choiceMessage = generateOptionChoiceMessage(category);

  return (
    <div
      className='row col-12 mt-4 hide-scroll'
      style={modalOptionContainer}
    >
      <div className='row col-12 align-self-start dark-modal border-radius'>
        <div
          className='col-12 d-flex py-1 py-sm-4 shadow-sm align-items-center dark-modal dark-border-bottom'
          style={styles.containerOptionHeader}
        >
          <div className='col-12'>
            <p
              style={{ color: BLACK }}
              className='mb-0 header-container-option px-2 label'
            >
              {category.name}
            </p>
            {choiceMessage && (
              <span className='text-muted header-description-container-option label'>
                {choiceMessage}
              </span>
            )}
          </div>
        </div>
        <Items
          category={category}
          productItems={productItems}
          index={index}
          categoryId={categoryId}
          nbProduct={nbProduct}
          itemsCounter={itemsCounter}
          product={product}
          categoryKey={categoryKey}
          addItem={addItem}
          removeItem={removeItem}
          addUniqueItem={addUniqueItem}
          productTitle={productTitle}
        />
      </div>
    </div>
  );
}
