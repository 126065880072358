import React from 'react';
import { COLOR_LIGHT_GRAY, UNIT } from '../../../../../../../../../../../constants';
import Button from '../../../../../../../../../../Button';
import { handleCounterClick } from '../Category.services';
import { useAppSelector } from '../../../../../../../../../../../redux/hook';

type MultiOptionsButtonsProps = {
  counter: any;
  item: any;
  counterDisabled: any;
  index: any;
  addItem: any;
  removeItem: any;
  category: any;
};

export default function MultiOptionsButtons({
  counter,
  item,
  counterDisabled,
  index,
  addItem,
  removeItem,
  category,
}: MultiOptionsButtonsProps) {
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  return (
    <div
      className='row'
      style={{ paddingLeft: UNIT * 0.5 }}
    >
      {counter > 0 && (
        <div className='row'>
          <Button
            className={`p-0 no-outline align-items-center`}
            onClick={(e) => {
              e.stopPropagation();
              handleCounterClick({
                index,
                addItem,
                removeItem,
                item,
                category,
                action: 'remove',
              });
            }}
          >
            <i className='fas fa-minus-circle fa-lg label' />
          </Button>
          <p className={`align-self-center mx-2`}>{counter}</p>
        </div>
      )}
      <Button
        className={`p-0 no-outline align-items-center ${
          counterDisabled ? 'disabled' : ''
        }`}
        onClick={(e) => {
          if (!counterDisabled) {
            e.stopPropagation();
            handleCounterClick({
              index,
              addItem,
              removeItem,
              item,
              category,
              action: 'add',
            });
          }
        }}
      >
        <i
          className='fas fa-plus-circle fa-lg label'
          style={{
            color: counterDisabled ? COLOR_LIGHT_GRAY : secondaryColor,
          }}
        />
      </Button>
    </div>
  );
}
